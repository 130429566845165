import React from 'react'
import { Tooltip } from 'antd'
import { InfoCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import IntlMessages from '../utils/IntlMessages'
import { getDaysOrHours } from '../../functions/get-days-or-hours'
import { roundTo2Decimals } from '../../functions/calculation-shared'
import dayjs from 'dayjs'
import FormattedDate from '../formatted-date'
import { getUserWorkingHoursPerDay } from '../../functions/work-week'
import { WorkingHours } from '../../types/work-week'

const InitialBroughtForwardTooltipContent = ({value, hourlyLeaveAccounting, workingHours}) => (
  <div style={{textAlign: 'center'}}>
    <div><IntlMessages id="components.userLeaveQuotas.initialBroughtForwardInfo" /></div>
    <br />
    <div><IntlMessages id="components.userLeaveQuotas.initialBroughtForward" />
      :<strong> {getDaysOrHours(value, hourlyLeaveAccounting, getUserWorkingHoursPerDay(workingHours as WorkingHours))}
        <IntlMessages id={`app.${hourlyLeaveAccounting ? 'hours' : 'days'}`}
        /></strong></div>
  </div>
)

const BroughtForwardTooltipContent = ({value, leaveTypeName, locationName, broughtForwardLimit, hourlyLeaveAccounting, workingHours}) => (
  <div style={{textAlign: 'center'}}>
    <div><IntlMessages id="components.userLeaveQuotas.broughtForwardInfo" /></div>
    <br />
    {broughtForwardLimit < 366 && <div><IntlMessages id="components.userLeaveQuotas.broughtForwardInfoDetails" values={{leaveTypeName, locationName, broughtForwardLimit }}/></div>}
    {broughtForwardLimit === 366 && <div><IntlMessages id="components.userLeaveQuotas.broughtForwardInfoDetailsAll" values={{ leaveTypeName, locationName }}/></div>}
    <br />
    <div>
      <IntlMessages id="components.userLeaveQuotas.broughtForwardValue" />:
      <strong>&nbsp;{getDaysOrHours(roundTo2Decimals(value), hourlyLeaveAccounting, getUserWorkingHoursPerDay(workingHours as WorkingHours))}
        &nbsp;
        <IntlMessages id={`app.${hourlyLeaveAccounting ? 'hours' : 'days'}`}/>
      </strong>
    </div>
  </div>
)

const QuotaTooltipContent = ({value, quota, hourlyLeaveAccounting, workingHours}) => (
  <div style={{textAlign: 'center'}}>
    <div><IntlMessages id="components.userLeaveQuotas.quotaInfo" /></div>
    <br />
    <div><IntlMessages id="components.userLeaveQuotas.quotaInfoSummary" /></div>
    {quota.hasUnlimitedDays ? <div><IntlMessages id="components.userLeaveQuotas.noLimit"/></div> : <div><div>
      <strong>{value === '∞' 
        ? 'unlimited' 
        : getDaysOrHours(roundTo2Decimals(value), hourlyLeaveAccounting, getUserWorkingHoursPerDay(workingHours as WorkingHours))} <IntlMessages
        id={`app.${hourlyLeaveAccounting ? 'hours' : 'days'}`}
      /></strong>
    </div>
    <div><IntlMessages id="app.ofThat" />:</div>
    <br />
    <ul style={{ textAlign: 'left' }}>
      <li><IntlMessages id="components.userLeaveQuotas.baseQuota"/>
      : {getDaysOrHours(roundTo2Decimals(quota.baseQuota), hourlyLeaveAccounting, getUserWorkingHoursPerDay(workingHours as WorkingHours))}</li>
      <li><IntlMessages id="app.timeOffInLieu"/>
      : {getDaysOrHours(roundTo2Decimals(quota.toilDays - quota.toilDaysLapsed), hourlyLeaveAccounting, getUserWorkingHoursPerDay(workingHours as WorkingHours))}
        {quota.toilDaysLapsed > 0 && <>&nbsp;
        ({getDaysOrHours(roundTo2Decimals(quota.toilDaysLapsed), hourlyLeaveAccounting, getUserWorkingHoursPerDay(workingHours as WorkingHours))}&nbsp;
        <IntlMessages id="components.toil.lapsed"/>)</>}
      </li>
      <li>
        <IntlMessages id="automations.SENIORITY_ENTITLEMENT"/>: {getDaysOrHours(
          roundTo2Decimals(quota.seniorityEntitlementDaysForYear), hourlyLeaveAccounting, getUserWorkingHoursPerDay(workingHours as WorkingHours)
        )}
      </li>
      {quota.entitlementByRoleDaysForYear > 0 &&
        <li>
          <IntlMessages id="automations.ENTITLEMENT_BY_ROLE"/>: {getDaysOrHours(
            roundTo2Decimals(quota.entitlementByRoleDaysForYear), hourlyLeaveAccounting, getUserWorkingHoursPerDay(workingHours as WorkingHours)
          )}
        </li>
      }
    </ul></div>}
  </div>
)

const ExpirationTooltipContent = ({expirationDate, locale}) => (
  <div style={{textAlign: 'center'}}>
    <div><IntlMessages id="components.userLeaveQuotas.expirationTooltip" /></div>
    <br />
    <div>
      {dayjs(expirationDate as string).isBefore(new Date()) ? <IntlMessages id="app.expired" /> : <IntlMessages id="app.expires" />}:
      <strong> <FormattedDate value={expirationDate?.slice(0,10)} locale={locale} format="MMMM Do YYYY."/></strong>
    </div>
  </div>
)

const UsedDaysTooltipContent = ({value, hourlyLeaveAccounting, leaveTypeName, scheduledDays, workingHours}) => (
  <div style={{textAlign: 'center'}}>
    <div><IntlMessages id="components.userLeaveQuotas.usedTooltip" values={{ leaveTypeName }} /></div>
    <div>
      <strong>{value === '∞' 
        ? 'unlimited'
        : getDaysOrHours(roundTo2Decimals(value), hourlyLeaveAccounting, getUserWorkingHoursPerDay(workingHours as WorkingHours))} <IntlMessages
        id={`app.${hourlyLeaveAccounting ? 'hours' : 'days'}`}
      /></strong>
    </div>
    <div><IntlMessages id="app.ofThat" />:</div>
    <br />
    <ul style={{ textAlign: 'left' }}>
      <li>
        <IntlMessages id="components.userLeaveQuotas.usedAlready"/>
        : {getDaysOrHours(roundTo2Decimals(value - scheduledDays), hourlyLeaveAccounting, getUserWorkingHoursPerDay(workingHours as WorkingHours))}
        &nbsp;<IntlMessages id={`app.${hourlyLeaveAccounting ? 'hours' : 'days'}`}/>
      </li>
      {scheduledDays > 0 && 
        <li>
          <IntlMessages
            id="components.userLeaveQuotas.scheduled"/>: {getDaysOrHours(
            roundTo2Decimals(scheduledDays), hourlyLeaveAccounting, getUserWorkingHoursPerDay(workingHours as WorkingHours)
          )}
          &nbsp;<IntlMessages id={`app.${hourlyLeaveAccounting ? 'hours' : 'days'}`}/>
        </li>
      }
      {/* add real value when we implement TOIL */}
      {/* <li><IntlMessages id="components.userLeaveQuotas.paidDays"/>: 0</li> */}
    </ul>
  </div>
)

const RemainingDaysTooltipContent = ({value, hourlyLeaveAccounting, leaveTypeName, broughtForwardLimit, workingHours}) => (
  <div style={{textAlign: 'center'}}>
    <div><IntlMessages id="components.userLeaveQuotas.remainingTooltip" values={{ leaveTypeName }} /></div>
    {value !== '∞' ? <div>
      <strong>
        {hourlyLeaveAccounting 
          ? Math.floor(getDaysOrHours(value, hourlyLeaveAccounting, getUserWorkingHoursPerDay(workingHours as WorkingHours)))
          : getDaysOrHours(roundTo2Decimals(value), hourlyLeaveAccounting, getUserWorkingHoursPerDay(workingHours as WorkingHours))
        }&nbsp;
        <IntlMessages id={`app.${hourlyLeaveAccounting ? 'hours' : 'days'}`}/>
      </strong>
    </div> : <div>∞ <IntlMessages id="components.userLeaveQuotas.noLimit" /></div>}
    <br />
    {value !== '∞' && <div>{broughtForwardLimit > 0 && broughtForwardLimit < 366 && 
      <div><IntlMessages id="components.userLeaveQuotas.remainingTooltipFooter" 
        values={{ 
          broughtForwardLimit: Math.floor(getDaysOrHours(broughtForwardLimit, hourlyLeaveAccounting, getUserWorkingHoursPerDay(workingHours as WorkingHours))),
          period: <IntlMessages id={`app.${hourlyLeaveAccounting ? 'hours' : 'days'}`}/>}
        } 
      /></div>
    }
    {broughtForwardLimit === 366 && <div><IntlMessages id="components.userLeaveQuotas.remainingTooltipFooterAll" values={{ broughtForwardLimit }} /></div>}
    {broughtForwardLimit === 0 && <div><IntlMessages id="components.userLeaveQuotas.remainingTooltipFooterZero" /></div>}</div>}
  </div>
)

export const InitialBroughtForwardTooltip = ({value, hourlyLeaveAccounting, workingHours}) => (
  <Tooltip title={<InitialBroughtForwardTooltipContent value={value} hourlyLeaveAccounting={hourlyLeaveAccounting} workingHours={workingHours} />}>
    <InfoCircleOutlined style={{ marginLeft: '10px' }} />
  </Tooltip>
)

export const BroughtForwardTooltip = ({value, locationName, leaveTypeName, broughtForwardLimit, hourlyLeaveAccounting, workingHours}) => (
  <Tooltip title={
    <BroughtForwardTooltipContent 
      value={value}
      locationName={locationName}
      leaveTypeName={leaveTypeName}
      broughtForwardLimit={broughtForwardLimit}
      hourlyLeaveAccounting={hourlyLeaveAccounting}
      workingHours={workingHours}
    />
  }>
    <QuestionCircleOutlined style={{ marginLeft: '10px' }} />
  </Tooltip>
)

export const QuotaTooltip = ({value, quota, hourlyLeaveAccounting, workingHours}) => (
  <Tooltip title={<QuotaTooltipContent value={value} quota={quota} hourlyLeaveAccounting={hourlyLeaveAccounting} workingHours={workingHours}/>}>
    <QuestionCircleOutlined style={{ marginLeft: '10px' }} />
  </Tooltip>
)

export const ExpirationTooltip = ({expirationDate, locale}) => (
  <Tooltip title={<ExpirationTooltipContent expirationDate={expirationDate} locale={locale} />}>
    <QuestionCircleOutlined style={{ marginLeft: '10px' }} />
  </Tooltip>
)

export const UsedDaysTooltip = ({value, hourlyLeaveAccounting, leaveTypeName, scheduledDays, workingHours}) => (
  <Tooltip title={<UsedDaysTooltipContent 
    scheduledDays={scheduledDays}
    value={value}
    hourlyLeaveAccounting={hourlyLeaveAccounting}
    leaveTypeName={leaveTypeName}
    workingHours={workingHours}/>
  }>
    <QuestionCircleOutlined style={{ marginLeft: '10px' }} />
  </Tooltip>
)

export const RemainingDaysTooltip = ({value, hourlyLeaveAccounting, leaveTypeName, broughtForwardLimit, workingHours}) => (
  <Tooltip title={<RemainingDaysTooltipContent 
    value={value} hourlyLeaveAccounting={hourlyLeaveAccounting} leaveTypeName={leaveTypeName} broughtForwardLimit={broughtForwardLimit} workingHours={workingHours}/>}>
    <QuestionCircleOutlined style={{ marginLeft: '10px' }} />
  </Tooltip>
)