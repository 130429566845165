import React, { useEffect, useState } from 'react'
import { Modal, Form, Select, Button, Spin, Typography, notification } from 'antd'
import IntlMessages from '../../util/IntlMessages'
import { filterOptions } from '../../util/filter-options'
import { SlackAuth } from '../../services/auth/slack/slack'
import { MicrosoftAuth } from '../../services/auth/microsoft/microsoft'
import { IMicrosoftGroup } from '../../types/microsoft'
import { useAppSelector } from '../../store/hooks'
import { selectAuthCompanySlice } from '../../store/auth-company-slice'
import { selectAuthUserSlice } from '../../store/auth-user-slice'
import { DownOutlined } from '@ant-design/icons'
import { useIntl } from 'react-intl'
import { API, graphqlOperation } from 'aws-amplify'
import { getSlackBotDetails } from '../../graphql/custom-queries'
import { ISlackBotDetails } from '../../types/slack'
import DynamicFields from '../dynamic-fields'
import * as logger from '../../services/logger'

interface IChannel {
  id: string
  name?: string
}

interface Props {
  selectedMsGroup?: string
  handleCancelLoginRequired?: () => void
}

const { Paragraph } = Typography

if (!process.env.REACT_APP_MICROSOFT_CLIENT_ID || !process.env.REACT_APP_SLACK_CLIENT_ID) {
  throw new Error('Client ID are required')
}

const msAuth = new MicrosoftAuth(process.env.REACT_APP_MICROSOFT_CLIENT_ID)
const slackAuth = new SlackAuth(process.env.REACT_APP_SLACK_CLIENT_ID)

export const SelectChannelFormFragment: React.FC<Props> = ({ selectedMsGroup, handleCancelLoginRequired }: Props) => {
  const { formatMessage } = useIntl()
  const { authCompany } = useAppSelector(selectAuthCompanySlice)
  const { authUser } = useAppSelector(selectAuthUserSlice)
  const [channelsLoading, setChannelsLoading] = useState(true)
  const [channels, setChannels] = useState<IChannel[]>([])
  const [groups, setGroups] = useState<IMicrosoftGroup[]>([])
  const [selectedGroup, setSelectedGroup] = useState<string | null>(null)
  const [showMsLoginRequiredModal, setShowMsLoginRequiredModal] = useState<boolean>(false)
  const [showSlackLoginRequiredModal, setShowSlackLoginRequiredModal] = useState<boolean>(false)
  const [msAuthToken, setMsAuthToken] = useState<string>('')
  const [slackBotId, setSlackBotId] = useState<string>()

  useEffect(() => {
    setChannelsLoading(false)
  }, [channels])

  useEffect(() => {
    if (authCompany?.platform === 'microsoft') {
      fetchMsData()
    }
    if (authCompany?.platform === 'slack') {
      fetchSlackData()
    }
  }, [])

  const fetchMsData = () => {
    try {
      const tokens = msAuth.getTokens()
      const tokenPermissions = msAuth.getPermissions()
      if (!tokenPermissions.includes('Channel.ReadBasic.All')) {
        return setShowMsLoginRequiredModal(true)
      }
      fetchDataFromMsApi(tokens.accessToken)
    } catch(err) {
      setShowMsLoginRequiredModal(true)
    }
  }

  const fetchSlackData = async (id = authUser.id, maxRetries = 2) => {
    try {
      const response = await API.graphql(graphqlOperation(getSlackBotDetails, { id })) as ISlackBotDetails
      if (!response.data.slackId.slackBotId) {
        throw new Error('No Slack Bot ID required')
      }
      setSlackBotId(response.data.slackId.slackBotId)
      await fetchSlackChannels()
    } catch (error) {
      if (id === authUser.id && maxRetries > 0) {
        // TODO: @slobodan remove this after migration
        // Fetch user without prefix
        return fetchSlackData(authUser.id.replace('slack-', ''), --maxRetries)
      }
      logger.error('error', error)
    }
  }

  const fetchSlackChannels = async () => {
    try {
      slackAuth.setTokensFromStorage()
      await slackAuth.authTest()
      const response = await slackAuth.conversationsList()

      const channelsData = response.map(channel => {
        return {id: channel.id, name: `${channel.name}${channel.is_private ? ' (private channel)' : ''}` }
      }) as IChannel[]
      setChannels(channelsData)
    } catch (error) {
      if (error?.error === 'not_authed' || error?.error === 'invalid_auth' || error?.error === 'missing_scope' || error?.error === 'token_revoked') {
        logger.warning('error', error)
        setShowSlackLoginRequiredModal(true)
      }
      logger.error('error', error)
    }
  }

  const fetchMsChannels = async (msTeamId: string) => {
    try {
      const response = await msAuth.getChannels(msTeamId)

      const channelsData = response
        // Exclude private channels, as bot can't be a member
        .filter(channel => channel.membershipType === 'standard')
        .map((channel): IChannel => {
        // An empty channel ID is not possible, we add an empty string just to make TypeScript happy
          return {
            id: channel.id || '',
            name: channel.displayName,
          }
        })
      setChannels(channelsData)
    } catch (caughtErr) {
      let error = caughtErr
      if (typeof error === 'string' && JSON.parse(error)) {
        error = JSON.parse(error)
      }

      if (error.data.error.code === 'NotFound') {
        handleErrorNotification(
          {message: formatMessage({ id: 'error.microsoft.notMemberOfTeam' }, { msTeamId })},
          undefined,
          formatMessage({ id: 'error.microsoft.channelNotFound'})
        )
        if (handleCancelLoginRequired) {
          handleCancelLoginRequired()
        }
      } else if (error.data.error.code === 'InvalidAuthenticationToken') {
        setShowMsLoginRequiredModal(true)
      } else {
        handleErrorNotification(error)
        if (handleCancelLoginRequired) {
          handleCancelLoginRequired()
        }
      }
    }
  }

  const fetchGroups = async (msToken = msAuthToken) => {
    try {
      const response = await msAuth.getJoinedTeams(msToken)

      if (!response) {
        throw new Error('No groups')
      }

      setGroups(response)
    }
    catch (caughtErr) {
      let error = caughtErr
      if (typeof error === 'string' && JSON.parse(error)) {
        error = JSON.parse(error)
      }

      if (error.data.error.code === 'NotFound') {
        handleErrorNotification(
          {message: formatMessage({ id: 'components.selectChannelForm.errorNotInTeams' }) }
        )
        if (handleCancelLoginRequired) {
          handleCancelLoginRequired()
        }
      } else if (error.data.error.code === 'InvalidAuthenticationToken') {
        setShowMsLoginRequiredModal(true)
      } else {
        handleErrorNotification(error)
        if (handleCancelLoginRequired) {
          handleCancelLoginRequired()
        }
      }
      setGroups([])
    }
  }

  const setGroup = (group: string) => {
    setSelectedGroup(group)
    fetchMsChannels(group)
  }

  const getSlackTokens = () => {
    slackAuth.signin(
      ['chat:write', 'commands', 'team:read', 'users:read', 'users:read.email'],
      ['channels:write', 'groups:write', 'users:read', 'team:read', 'channels:read', 'groups:read', 'users.profile:write']
    ).then(async (response) => {
      logger.debug('reponse', response)
      slackAuth.setUserToken(response.authed_user.access_token)
      try {
        await API.post('SlackApi', '/update-tokens', {
          body: {
            token: response.authed_user.access_token,
            botToken: response.access_token,
          },
        })
      } catch (error) {
        logger.warning('UPDATE TOKEN ERROR', error.response)
      }
      setShowSlackLoginRequiredModal(false)
      await fetchSlackChannels()
    }).catch(error => {
      logger.warning('SLACK SIGNIN ERROR', error)
      handleErrorNotification({message: 'To proceed, please login to your Slack account'}, undefined, 'Missing Slack Scopes')
    })
  }

  const getBasicMsToken = () => {
    msAuth.signin(['user.readbasic.all', 'team.readbasic.all', 'Channel.ReadBasic.All'])
      .then(([tokens]) => {
        setShowMsLoginRequiredModal(false)
        return fetchDataFromMsApi(tokens.accessToken)
      })
  }

  const getMsTokens = () => {
    msAuth.signin(['user.readbasic.all', 'team.readbasic.all', 'group.read.all', 'Channel.ReadBasic.All'])
      .then(([tokens]) => {
        setShowMsLoginRequiredModal(false)
        return fetchDataFromMsApi(tokens.accessToken)
      })
      .catch(err => {
        logger.warning('getMsTokens', err)
        if (err === 'access-denied-cancel') {
          const consentLink = `https://login.microsoftonline.com/${msAuth.getTenantId()}/adminconsent?client_id=${process.env.REACT_APP_MICROSOFT_CLIENT_ID}`
          notification.error({
            key: err,
            message: formatMessage({ id: 'microsoft.adminConsentErrorTitle' }),
            description: (<>
              <Paragraph>{formatMessage({ id: 'microsoft.adminConsentErrorDescription1'})}</Paragraph>
              <Paragraph copyable={{ text: consentLink }}>
                <a
                  href={consentLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ wordBreak: 'break-word' }}
                >
                  {consentLink}
                </a>
              </Paragraph>
              <p>{formatMessage({ id: 'microsoft.adminConsentErrorDescription2'})}</p>
            </>),
            btn: (<Button onClick={() => {
              getBasicMsToken()
              notification.close(err)
            }}>
              {formatMessage({ id: 'microsoft.adminConsentErrorButton'})}
            </Button>),
            duration: 0,
          })
        }
        setShowMsLoginRequiredModal(false)
      })
  }

  const fetchDataFromMsApi = async (accessToken: string): Promise<void> => {
    setMsAuthToken(accessToken)
    if (!authCompany?.msTeamId) {
      throw new Error('Missing company.msTeamId')
    }

    try {
      if (authCompany.msTeamId === authCompany.organizationId && !selectedGroup) {
        await fetchGroups(accessToken)
        if (selectedMsGroup) {
          await fetchMsChannels(selectedMsGroup)
        }
      } else if (selectedGroup) {
        await fetchMsChannels(selectedGroup)
      } else {
        await fetchMsChannels(authCompany.msTeamId)
      }
    } catch(err) {
      logger.warning(err)
      getMsTokens()
    }
  }

  const handleErrorNotification = (error, correlationId?: string, title?: string): void => {
    const description = correlationId ?
      formatMessage({ id: 'error.notificationGeneral' }, { correlationId }) :
      error.response?.data?.message ? error.response?.data?.message : error.message ? error.message : JSON.stringify(error)
    notification.error({
      message: title ? title : formatMessage({ id: 'error.notificationGeneral' }),
      description,
      duration: 0,
    })
  }

  const onSelect = async (channel) => {
    if (authCompany?.platform === 'slack') {
      try {
        if (!slackBotId) {
          handleErrorNotification({message: 'Slack error: Slack Bot Id is required! Try refresh page!'})
          return
        }
        slackAuth.setTokensFromStorage()
        await slackAuth.authTest()
        await slackAuth.conversationsInvite(channel, slackBotId)
      } catch (error) {
        if (error.error === 'not_in_channel') {
          handleErrorNotification({message: 'You can add the bot only to the channels you`re a part of. '})
          return
        }
        if (error.error === 'invitee_is_not_a_member_of_this_workspace') {
          setShowSlackLoginRequiredModal(true)
          return
        }
        if (error.error !== 'already_in_channel') {
          handleErrorNotification({message: `Slack error: ${error.error}${error.needed ? ` ${error.needed}` : ''}`})
          return
        }
      }
    }
  }

  return (
    <>
      {(groups && groups.length > 0 && authCompany?.platform === 'microsoft') &&
        <Form.Item
          name="group"
          label={<IntlMessages id="components.selectChannelForm.team" />}
          rules={[{ required: true, message: <IntlMessages id="form.inputRequired" />}]}
        >
          <Select style={{ width: 300 }}
            showSearch
            filterOption={filterOptions}
            onSelect={(event) => {
              setChannelsLoading(true)
              setGroup(event ? event.toString() : '')
            }}
          >
            {groups?.map(group => <Select.Option key={group.id} value={group.id}>{group.displayName}</Select.Option>)}
          </Select>
        </Form.Item>
      }

      {(authCompany?.platform === 'microsoft' || authCompany?.platform === 'slack') &&
        <Form.Item
          label={
            <span>
              <IntlMessages id="components.selectChannelForm.channel" />&nbsp;
            </span>
          }
          name="channel"
          rules={[{ required: true, message: <IntlMessages id="form.inputRequired" /> }]}
        >
          <Select style={{ width: 300 }}
            showSearch
            filterOption={filterOptions}
            disabled={!channels.length}
            suffixIcon={channelsLoading ? <Spin style={{ marginTop: -3 }} size="small" /> : <DownOutlined />}
            onSelect={onSelect}
          >
            {channels.map(channel => <Select.Option key={channel.id} value={channel.id}>{channel.name}</Select.Option>)}
          </Select>
        </Form.Item>
      }

      {(authCompany?.platform === 'google' || authCompany?.platform === 'email') &&
        <DynamicFields
          name="channel"
          labelText="app.recipients"
          addNewText="app.addNewEmail"
          type="email"
          maxLength={50}
          required={true}
          rules={[{
            required: true,
            whitespace: true,
            message: <IntlMessages id="form.inputRequired" />,
          }, {
            type: 'email',
            message: <IntlMessages id="form.validEmailRequired" />,
          }]}
        />
      }

      {showMsLoginRequiredModal &&
        <Modal
          title={formatMessage({ id: 'error.microsoft.notificationsLoginRequiredTitle' })}
          visible={showMsLoginRequiredModal}
          onOk={getMsTokens}
          onCancel={() => {
            if (handleCancelLoginRequired) {
              handleCancelLoginRequired()
            }
            setShowMsLoginRequiredModal(false)
          }}
        >
          <p><IntlMessages id="microsoft.notificationsLoginRequiredDescription1" /></p>
          <p><IntlMessages id="microsoft.notificationsLoginRequiredDescription2" /></p>
        </Modal>
      }
      {showSlackLoginRequiredModal &&
        <Modal
          title={formatMessage({ id: 'error.slack.usersLoginRequiredTitle' })}
          visible={showSlackLoginRequiredModal}
          onOk={getSlackTokens}
          onCancel={() => {
            setShowSlackLoginRequiredModal(false)
          }}
        >
          <p><IntlMessages id="slack.notificationsLoginRequiredDescription1" /></p>
          <p><IntlMessages id="slack.notificationsLoginRequiredDescription2" /></p>
        </Modal>
      }
    </>
  )
}
