import { Form, Space, Select, Radio, Skeleton, Tooltip, TreeSelect } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { API, graphqlOperation } from 'aws-amplify'
import { find, isBoolean } from 'lodash'
import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'

import { filterOptions } from '../../util/filter-options'
import { selectAuthCompanySlice } from '../../store/auth-company-slice'
import { useAppSelector } from '../../store/hooks'
import { getConfigureUsersData } from '../../graphql/custom-queries'
import { getIntegrationPlatformName } from '@vacationtracker/shared/functions/get-integration-platform-name'
import { track } from '../../services/analytics/analytics'

import IntlMessages from '../../util/IntlMessages'

import { IData } from '../../types/data'
import { IGetConfigureUsersData, IGetLeaveTypeList } from '../../types/custom-queries'
import { IGetLocationsShort } from '../../types/locations'
import { IGetTeamsShort } from '../../types/teams'
import { WelcomeMessageOptions } from '../../routes/Users/ImportUsersPage/types'
import { Platform } from '@vacationtracker/shared/types/core-event'
import { IConfigureImportUsersOptionsForm, ISelectedLeaveType } from './types'


const formTailLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 12 },
}

export const getDontSendWelcomeOption = (formatMessage: Function): WelcomeMessageOptions => {
  return {
    platform: 'none',
    value: formatMessage({id: 'app.dontSend'}),
    channel: undefined,
  }
}

export const getWelcomeMessageOptionsDefault = (formatMessage: Function): WelcomeMessageOptions[] => {
  return [
    {
      platform: 'microsoft',
      value: `${formatMessage({id: 'app.yes'})}, ${formatMessage({id: 'app.via'})} ${formatMessage({id: 'app.microsoftTeams'})} ${formatMessage({id: 'app.message'})}`,
      channel: `${formatMessage({id: 'app.via'})} ${formatMessage({id: 'app.microsoftTeams'})}`,
    },
    {
      platform: 'slack',
      value: `${formatMessage({id: 'app.yes'})}, ${formatMessage({id: 'app.via'})} ${formatMessage({id: 'app.slack'})} ${formatMessage({id: 'app.message'})}`,
      channel: `${formatMessage({id: 'app.via'})} ${formatMessage({id: 'app.slack'})}`,
    },
    {
      platform: 'google',
      value: `${formatMessage({id: 'app.yes'})}, ${formatMessage({id: 'app.send'})} ${formatMessage({id: 'app.email'}).toLowerCase()}`,
      channel: `${formatMessage({id: 'app.email'})}`,
    },
    getDontSendWelcomeOption(formatMessage),
  ]
}

const ConfigureImportUsersOptionsForm = ({
  form,
  source,
  welcomeMessageOpts,
  onChangeGuestUsersImport,
  setSelectedWelcomeMessageOpt,
  setSelectedDepartment,
  setSelectedLocation,
  setSelectedLeaveTypes,
  setWelcomeMessageOpts,
  shouldShowAnnounce = true,
  shouldShowLocation = true,
  shouldShowDepartment = true,
  shouldShowProrate = true,
  shouldShowImportGuestUsers = false,
  formLayout = formTailLayout,
}: IConfigureImportUsersOptionsForm<typeof form.getFieldsValue>) => {
  const { formatMessage } = useIntl()
  const { authCompany } = useAppSelector(selectAuthCompanySlice)

  const [departments, setDepartments] = useState<IGetTeamsShort[]>([])
  const [locations, setLocations] = useState<IGetLocationsShort[]>([])
  const [allLeaveTypes, setAllLeaveTypes] = useState<IGetLeaveTypeList[]>([])
  const [locationLeaveTypes, setLocationLeaveTypes] = useState<ISelectedLeaveType[]>([])
  const [shouldProrate, setShouldProrate] = useState(true)
  const [importGuestUsers, setImportGuestUsers] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [shouldShowNoLeavePoliciesError, setShouldShowNoLeavePoliciesError] = useState(false)

  const radioOptions = [{
    value: true, label: formatMessage({ id: 'app.yes' }),
  }, {
    value: false, label: formatMessage({ id: 'app.no' }),
  }]

  const fetchData = async () => {
    const response = await API.graphql(graphqlOperation(getConfigureUsersData)) as IData<IGetConfigureUsersData>
    const {
      shouldProrate,
      proratedLeaveTypes,
      defaultDepartmentForAutomaticImport,
      defaultLocationForAutomaticImport,
      shouldImportGuestUsers,
      announceNewUsers,
    } = response.data.getCompany
    setDepartments(response.data.getTeamList)
    setLocations(response.data.getLocationList)
    setAllLeaveTypes(response.data.getLeaveTypeList)
    const defaultShouldImportGuestUsers = isBoolean(shouldImportGuestUsers) ? shouldImportGuestUsers : false
    const foundDepartment = defaultDepartmentForAutomaticImport
      ? response.data.getTeamList.find(department => department.id === defaultDepartmentForAutomaticImport)
      : response.data.getTeamList.find(department => department.isDefault)
    const foundLocation = defaultLocationForAutomaticImport
      ? response.data.getLocationList.find(location => location.id === defaultLocationForAutomaticImport)
      : response.data.getLocationList.find(location => location.isDefault)
    form.setFieldsValue({
      selectedDepartment: foundDepartment && foundDepartment.id,
      selectedLocation: foundLocation && foundLocation.id,
      shouldProrate: Boolean(shouldProrate),
      selectedLeaveTypes: proratedLeaveTypes && proratedLeaveTypes?.length > 0
        ? proratedLeaveTypes
        : response.data.getLeaveTypeList
          .map(leaveType => {
            const leavePoliciesLocations = leaveType.leavePolicies.map(lp => lp.locationId)
            if (foundLocation && leavePoliciesLocations.includes(foundLocation.id)) {
              return leaveType.id
            }
          })
          .filter(Boolean),
      shouldImportGuestUsers: defaultShouldImportGuestUsers,
    })
    setShouldProrate(Boolean(shouldProrate))
    setImportGuestUsers(Boolean(defaultShouldImportGuestUsers))
    setSelectedDepartment && setSelectedDepartment(foundDepartment)
    setSelectedLocation && setSelectedLocation(foundLocation)
    const selectedMessage = announceNewUsers
      ? welcomeMessageOpts.find(opt => opt.platform === authCompany?.platform) as WelcomeMessageOptions
      : getDontSendWelcomeOption(formatMessage)

    if (announceNewUsers) {
      setWelcomeMessageOpts([
        selectedMessage,
        getDontSendWelcomeOption(formatMessage),
      ])
    } else {
      setWelcomeMessageOpts([
        selectedMessage,
        welcomeMessageOpts.find(opt => opt.platform === authCompany?.platform),
      ])
    }
    setSelectedWelcomeMessageOpt && setSelectedWelcomeMessageOpt(selectedMessage)

    const computedLeaveTypes = proratedLeaveTypes && proratedLeaveTypes?.length > 0
      ? response.data.getLeaveTypeList
        .filter(leaveType => {
          const foundLeaveType = find(proratedLeaveTypes, proratedLeaveType => proratedLeaveType === leaveType.id)
          if (foundLeaveType) {
            return leaveType
          }
        })
        .map(lt => {
          return {
            name: lt.name,
            id: lt.id,
            locations: lt.leavePolicies,
          }
        })
      : response.data.getLeaveTypeList
        .map(leaveType => {
          const leavePoliciesLocations = leaveType.leavePolicies.map(lp => lp.locationId)
          if (foundLocation && leavePoliciesLocations.includes(foundLocation.id)) {
            return {
              name: leaveType.name,
              id: leaveType.id,
              locations: leaveType.leavePolicies,
            }
          }
        })
        .filter(Boolean)

    setSelectedLeaveTypes && setSelectedLeaveTypes(computedLeaveTypes)
    setLocationLeaveTypes(response.data.getLeaveTypeList
      .map(leaveType => {
        const leavePoliciesLocations = leaveType.leavePolicies.map(lp => lp.locationId)
        if (foundLocation && leavePoliciesLocations.includes(foundLocation.id)) {
          return {
            name: leaveType.name,
            id: leaveType.id,
            locations: leaveType.leavePolicies,
          }
        }
      })
      .filter(Boolean) as ISelectedLeaveType[])
    if (computedLeaveTypes.length === 0) {
      setShouldShowNoLeavePoliciesError(true)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [])

  const handleSelectWelcomeMessage = (option) => {
    const selectedMessage = welcomeMessageOpts.find(opt => opt.platform === option) as WelcomeMessageOptions
    setSelectedWelcomeMessageOpt && setSelectedWelcomeMessageOpt(selectedMessage)
    form.setFieldsValue({ sendWelcomeMessageTo: selectedMessage })
  }


  const handleSelectLocation = (selectedLocation: string) => {
    setSelectedLocation && setSelectedLocation(locations.find((location: IGetLocationsShort) => location.id === selectedLocation))
    const matchingLocationLeaveTypes = allLeaveTypes.filter(lt => lt.leavePolicies.map(lp => lp.locationId).includes(selectedLocation))
    if (matchingLocationLeaveTypes.length && setSelectedLeaveTypes) {
      setSelectedLeaveTypes(matchingLocationLeaveTypes.map(l => {
        return {
          name: l.name,
          id: l.id,
          locations: l.leavePolicies,
        }
      }).filter(Boolean))
      setLocationLeaveTypes(matchingLocationLeaveTypes.map(l => {
        return {
          name: l.name,
          id: l.id,
          locations: l.leavePolicies,
        }
      }).filter(Boolean) as ISelectedLeaveType[])
      setShouldShowNoLeavePoliciesError(false)
    } else {
      setShouldShowNoLeavePoliciesError(true)
      setSelectedLeaveTypes && setSelectedLeaveTypes([])
      setLocationLeaveTypes([])
    }
    form.setFieldsValue({
      selectedLocation,
      selectedLeaveTypes: matchingLocationLeaveTypes.map(l => l.id),
    })
    track('IMPORT_USERS_DEFAULT_LOCATION_CHANGED', {
      platform: authCompany?.platform,
      selectedLocation,
      source,
    })
  }

  const handleProrateChange = (v) => {
    v.preventDefault()
    setShouldProrate(v.target.value)
    track('IMPORT_USERS_PRORATE_CHANGED', {
      platform: authCompany?.platform,
      selectedValue: v.target.value,
      source,
    })
  }

  const handleSelectLeaveType = (selectedLeaveTypeId) => {
    let result: ISelectedLeaveType[] = []
    setSelectedLeaveTypes && setSelectedLeaveTypes(prevState => {
      if (find(prevState => prevState.id === selectedLeaveTypeId)) {
        return prevState
      } else {
        result = [...prevState, locationLeaveTypes.find(lt => lt.id === selectedLeaveTypeId)]
        return result
      }
    })
    track('IMPORT_USERS_PRORATE_LEAVE_TYPES_CHANGED', {
      platform: authCompany?.platform,
      selectedLeaveTypes: result.map(lt => lt.name).join(', '),
      source,
    })
  }

  const handleDeselectLeaveType = (selectedLeaveTypeId) => {
    let result: ISelectedLeaveType[] = []
    setSelectedLeaveTypes && setSelectedLeaveTypes(prevState => {
      result = prevState.filter(slt => slt.id !== selectedLeaveTypeId)
      return result
    })
    track('IMPORT_USERS_PRORATE_LEAVE_TYPES_CHANGED', {
      platform: authCompany?.platform,
      selectedLeaveTypes: result.map(lt => lt.name).join(', '),
      source,
    })
  }

  const doesSelectedLocationHasFiscalYear = find(locations, (loc) => loc.id === form.getFieldValue('selectedLocation'))?.resetQuotas === 'FISCAL_YEAR'

  return <>
    {isLoading ? <Space direction='vertical'>
      <Skeleton.Button size="large" shape="square" style={{ width: '40vw'}} active />
      <Skeleton.Button size="large" shape="square" style={{ width: '40vw'}} active />
      <Skeleton.Button size="large" shape="square" style={{ width: '40vw'}} active />
      <Skeleton.Button size="large" shape="square" style={{ width: '40vw'}} active />
      <Skeleton.Button size="large" shape="square" style={{ width: '40vw'}} active />
    </Space> : <>
      {authCompany?.platform === 'slack' && shouldShowImportGuestUsers && <Form.Item
        {...formLayout}
        className='wrap-label'
        name='shouldImportGuestUsers'
        style={{ marginBottom: '30px' }}
        required={true}
        label={<>
          <div style={{ marginBottom: '6px' }}><IntlMessages id='components.configureImport.guestUsers' /></div>
          <Tooltip className='info-tooltip' title={<IntlMessages id="components.configureImport.guestUsersTooltip" />}>
            <InfoCircleOutlined />
          </Tooltip>
        </>}>
        <Radio.Group
          optionType="button"
          value={importGuestUsers}
          onChange={(v) => {
            v.preventDefault()
            form.setFieldsValue({shouldImportGuestUsers: v.target.value})
            setImportGuestUsers(v.target.value)
            onChangeGuestUsersImport && onChangeGuestUsersImport(v.target.value)
            track('IMPORT_GUEST_USERS_CHANGED', {
              platform: 'slack',
              selectedValue: v.target.value,
              source,
            })
          }}>
          {radioOptions.map(option => (
            <Radio.Button value={option.value} key={option.label}>{option.label}</Radio.Button>)
          )}
        </Radio.Group>
      </Form.Item>}
      {shouldShowAnnounce && <Form.Item
        {...formLayout}
        className='wrap-label'
        name='sendWelcomeMessageTo'
        style={{ marginBottom: '30px' }}
        required={true}
        label={<>
          <IntlMessages id="general.announceNewUsers" />
          <Tooltip
            className='info-tooltip'
            title={<IntlMessages
              id="general.announceNewUsersTooltip"
              values={{ platform: getIntegrationPlatformName(authCompany?.platform as Platform) }}
            />}>
            <InfoCircleOutlined />
          </Tooltip>
        </>}
      >
        <Select
          style={{ width: 300 }}
          showSearch
          filterOption={filterOptions}
          onSelect={handleSelectWelcomeMessage}
        >
          {welcomeMessageOpts.map((option: WelcomeMessageOptions) => <Select.Option key={option?.value} value={option?.platform}>{option?.value}</Select.Option>)}
        </Select>
      </Form.Item>}
      {shouldShowDepartment && <Form.Item
        {...formLayout}
        className='wrap-label'
        name='selectedDepartment'
        style={{ marginBottom: '30px' }}
        required={true}
        label={<>
          <IntlMessages id='app.department' />
          <Tooltip className='info-tooltip' title={<IntlMessages id="components.configureImport.defaultDepartmentTooltip" />}>
            <InfoCircleOutlined />
          </Tooltip>
        </>}>
        <TreeSelect
          style={{ width: 300 }}
          showSearch
          treeData={departments}
          treeNodeFilterProp='name'
          treeDataSimpleMode={{id: 'id', pId: 'parentId'}}
          fieldNames={{label: 'name', value: 'id'}}
          onSelect={(selectedDepartment) => {
            setSelectedDepartment && setSelectedDepartment(departments.find((department: IGetTeamsShort) => department.id === selectedDepartment))
            form.setFieldsValue({ selectedDepartment })
            track('IMPORT_USERS_DEFAULT_DEPARTMENT_CHANGED', {
              platform: authCompany?.platform,
              selectedDepartment,
              source,
            })
          }}>
        </TreeSelect>
      </Form.Item>}
      {shouldShowLocation && <Form.Item
        {...formLayout}
        className='wrap-label'
        name='selectedLocation'
        style={{ marginBottom: '30px' }}
        required={true}
        label={<>
          <IntlMessages id='app.location' />
          <Tooltip className='info-tooltip' title={<IntlMessages id="components.configureImport.defaultLocationTooltip" />}>
            <InfoCircleOutlined />
          </Tooltip>
        </>}
      >
        <Select
          style={{ width: 300 }}
          showSearch
          filterOption={filterOptions}
          onSelect={(selectedLocation: string) => handleSelectLocation(selectedLocation)}>
          {locations.map(option => <Select.Option key={option.id} value={option.id}>{option.name}</Select.Option>)}
        </Select>
      </Form.Item>}
      {shouldShowProrate && doesSelectedLocationHasFiscalYear && <Form.Item
        {...formLayout}
        className='wrap-label'
        name='shouldProrate'
        required={true}
        label={<>
          <IntlMessages id='components.configureImport.proRateLabel' />
          <Tooltip className='info-tooltip' title={<IntlMessages id="components.configureImport.proRateTooltip" />}>
            <InfoCircleOutlined />
          </Tooltip>
        </>}>
        <Radio.Group
          optionType="button"
          value={shouldProrate}
          onChange={(v) => handleProrateChange(v)}>
          {radioOptions.map(option => (
            <Radio.Button value={option.value} key={option.label}>{option.label}</Radio.Button>)
          )}
        </Radio.Group>
      </Form.Item>}
      {shouldShowProrate && shouldProrate && doesSelectedLocationHasFiscalYear && <Form.Item
        {...formLayout}
        className='wrap-label'
        name='selectedLeaveTypes'
        rules={[{
          required: true,
          message: form.getFieldValue('shouldProrate') && shouldShowNoLeavePoliciesError
            ? <IntlMessages id="location.leavePoliciesNotExistInfo" />
            : <IntlMessages id='components.configureImport.configureProratedLeaveTypesErrorMessage' />,
        }]}
        label={<>
          <IntlMessages id='components.configureImport.proRateLeaveTypes' />
          <Tooltip className='info-tooltip' title={<IntlMessages id="components.configureImport.proRateLeaveTypesTooltip" />}>
            <InfoCircleOutlined />
          </Tooltip>
        </>}>
        <Select
          mode="multiple"
          maxTagCount="responsive"
          onSelect={handleSelectLeaveType}
          onDeselect={handleDeselectLeaveType}
        >
          {locationLeaveTypes.map(lt => <Select.Option key={lt.id}>
            {lt.name}
          </Select.Option>)}
        </Select>
      </Form.Item>}
    </>

    }

  </>
}

export default ConfigureImportUsersOptionsForm