import React, { useEffect, useRef } from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import { Observable, ZenObservable } from 'zen-observable-ts'
import { useIntl } from 'react-intl'
import { isNumber } from 'lodash'

import * as logger from '../../services/logger'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { selectAuthCompanySlice, setAuthCompanyPlan, setAuthCompany } from '../../store/auth-company-slice'
import { selectAuthUserSlice, setAuthUserLocationWorkWeek, setAuthUserWorkWeek } from '../../store/auth-user-slice'
import { setLeaveRequestActionEvent } from '../../store/leave-request-action-event-slice'
import { setCompanySettingsBillingUpdatedActionEvent } from '../../store/company-settings-billing-updated-action-event-slice'
import {
  companyUpdateSubscription,
  getCompanyBillingInfo,
  getConnectedCalendars,
  getLeaveRequestByIdForSubscription,
  getToilRequestByIdForSubscription,
  getUserForLeaveRequestUpdateNotification
} from '../../graphql/custom-queries'
import { handleUnsubscribe } from '../../util/notifications'

import { IData } from '../../types/data'
import { ISubscriptionEventMessage } from '@vacationtracker/shared/types/notification'
import { IGetConnectedCalendarsResponse, ILeaveRequestUpdate, IToilRequestUpdate, IUserForLeaveRequestUpdate } from '../../types/custom-queries'
import { selectLocaleSlice } from '../../store/locale-slice'
import { BillingTypesEnum } from '@vacationtracker/shared/types/billing'
import { IGetCompanyBillingInfoData } from '../../types/company'
import { updateLeaveRequestNotification } from '../../components/update-leave-request-notification'
import { updateToilRequestNotification } from '../../components/update-toil-request-notification'
import { setToilRequestActionEvent } from '../../store/toil-request-action-event-slice'

let delay = 15

const CompanyUpdatesNotifications = () => {
  const { authCompany } = useAppSelector(selectAuthCompanySlice)
  const { authUser} = useAppSelector(selectAuthUserSlice)
  const { locale} = useAppSelector(selectLocaleSlice)
  const dispatch = useAppDispatch()
  const { formatMessage } = useIntl()
  const sub = useRef<ZenObservable.Subscription[]>([])

  const handleSubscription = () => {
    const subscription = API.graphql(
      graphqlOperation(companyUpdateSubscription, { companyId: authCompany?.id })
    ) as Observable<object>

    const subs = subscription.subscribe({
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      next: (data: any) => {
        delay = 10
        let subscriptionEvent: ISubscriptionEventMessage | null = null

        try {
          subscriptionEvent = JSON.parse(data.value.data.companyUpdates.originalEvent)
          const result = JSON.parse(data.value.data.companyUpdates.result)
          handleSubscriptionEvent(subscriptionEvent, result)
        } catch(err) {
          // Ignoring the error
        }
      },
      error: () => {
        if ((delay * 2) < 3.6e+6) {
          delay *= 2
        }
        setTimeout(() => {
          handleSubscription()
        }, delay)
      },
    })
    return subs
  }

  const handleSubscriptionEvent = async (event, result) => {
    if (!authCompany || !authUser.id) {
      return
    }

    switch (event.eventType) {
      case 'SUBSCRIPTION_CHANGED':
        dispatch(setAuthCompanyPlan(event.plan))
        if (event.paymentProcessor === 'microsoft-billing') {
          dispatch(setAuthCompany({
            ...authCompany,
            billing: {
              ...authCompany?.billing,
              quantity: event.quantity,
              subscriptionPeriod: event.subscriptionPeriod,
              msSubscriptionStatus: event.msSubscriptionStatus,
              msPeriodStartDate: event.msPeriodStartDate,
              msPeriodEndDate: event.msPeriodEndDate,
            },
          }))
        }
        break
      case 'PAYMENT_PROCESSOR_UPDATED':
        if (event.paymentProcessor === 'stripe'
          && authCompany.billing?.billingType === BillingTypesEnum.seatBased
          && isNumber(authCompany.billing?.seats)
        ) {
          const billingResponse = await API.graphql(graphqlOperation(getCompanyBillingInfo, { companyId: authCompany.id })) as IData<IGetCompanyBillingInfoData>

          dispatch(setAuthCompany({
            ...authCompany,
            billing: {
              ...authCompany?.billing,
              seats: billingResponse.data.getCompany.billing.seats,
            },
          }))
          dispatch(setCompanySettingsBillingUpdatedActionEvent(event))
        }
        break
      case 'LOCATION_WORK_WEEK_UPDATED':
        dispatch(setAuthUserLocationWorkWeek(event))
        break
      case 'USER_WORK_WEEK_UPDATED':
        dispatch(setAuthUserWorkWeek(event))
        break
      case 'LEAVE_REQUEST_UPDATED':
      case 'LEAVE_REQUEST_CREATED':
      case 'LEAVE_REQUEST_CANCELLED':
      case 'LEAVE_REQUEST_APPROVED':
      case 'LEAVE_REQUEST_DENIED':
      case 'LEAVE_REQUEST_EXPIRED':
      case 'LEAVE_REQUEST_ADDED':
      case 'LEAVE_REQUEST_DELETED':
        
        if (event.isMigration) return
        if (result?.eventAction === 'LeaveRequestAction') {
          let isCalendarConnected = false
          try {
            const leaveRequestResponse = await API.graphql(graphqlOperation(getLeaveRequestByIdForSubscription, {
              leaveRequestId: result.id,
            })) as IData<ILeaveRequestUpdate>
            let userResponse
            if (authUser.role === 'Admin') {
              userResponse = await API.graphql(graphqlOperation(getUserForLeaveRequestUpdateNotification, {
                id: event.creator,
              })) as IData<IUserForLeaveRequestUpdate>
            } else if (authUser.approverTo.find(u => u.id === leaveRequestResponse.data.getLeaveRequest.user.id)) {
              userResponse = await API.graphql(graphqlOperation(getUserForLeaveRequestUpdateNotification, {
                id: event.creator,
              })) as IData<IUserForLeaveRequestUpdate>
            } else if (authUser.id === leaveRequestResponse.data.getLeaveRequest.user.id) {
              userResponse = await API.graphql(graphqlOperation(getUserForLeaveRequestUpdateNotification, {
                id: leaveRequestResponse.data.getLeaveRequest.user.id,
              })) as IData<IUserForLeaveRequestUpdate>
            } else {
              return // regular users cant see other users profiles anyway
            }

            const calendarResponse = await API.graphql(graphqlOperation(getConnectedCalendars, { userId: authUser.id })) as IData<IGetConnectedCalendarsResponse>
            if (calendarResponse.data?.getConnectedGooogleCalendar?.calendarId || calendarResponse.data?.getConnectedOutlookCalendar?.calendarId) {
              isCalendarConnected = true
            }

            if (leaveRequestResponse.data && userResponse.data) {
              updateLeaveRequestNotification({
                subscriptionEvent: event,
                leaveRequest: {
                  getLeaveRequest: leaveRequestResponse.data.getLeaveRequest,
                  getUser: userResponse.data.getUser,
                },
                result,
                authUser,
                authCompany,
                formatMessage,
                locale: locale.locale,
                isCalendarConnected,
              })
            }
          } catch (err) {
            logger.error('ERROR FETCHING LEAVE REQUEST', err)
          }
          dispatch(setLeaveRequestActionEvent(event))
        }
        break
      case 'TOIL_REQUEST_UPDATED':
      case 'TOIL_REQUEST_CREATED':
      case 'TOIL_REQUEST_APPROVED':
      case 'TOIL_REQUEST_DENIED':
      case 'TOIL_REQUEST_EXPIRED':
      case 'TOIL_REQUEST_ADDED':
      case 'TOIL_REQUEST_DELETED':
      case 'TOIL_REQUEST_CANCELLED':
        if (event.isMigration) return
        if (result?.eventAction === 'ToilRequestAction') {
          try {
            const toilRequestResponse = await API.graphql(graphqlOperation(getToilRequestByIdForSubscription, {
              toilRequestId: result.id,
            })) as IData<IToilRequestUpdate>
            let userResponse
            if (authUser.role === 'Admin') {
              userResponse = await API.graphql(graphqlOperation(getUserForLeaveRequestUpdateNotification, {
                id: event.creator,
              })) as IData<IUserForLeaveRequestUpdate>
            } else if (authUser.approverTo.find(u => u.id === toilRequestResponse.data.getToilRequest.user.id)) {
              userResponse = await API.graphql(graphqlOperation(getUserForLeaveRequestUpdateNotification, {
                id: event.creator,
              })) as IData<IUserForLeaveRequestUpdate>
            } else {
              userResponse = await API.graphql(graphqlOperation(getUserForLeaveRequestUpdateNotification, {
                id: toilRequestResponse.data.getToilRequest.user.id,
              })) as IData<IUserForLeaveRequestUpdate>
            }
            if (toilRequestResponse.data && userResponse.data) {
              updateToilRequestNotification({
                subscriptionEvent: event,
                toilRequest: {
                  getToilRequest: toilRequestResponse.data.getToilRequest,
                  getUser: userResponse.data.getUser,
                },
                result,
                authUser,
                authCompany,
                formatMessage,
                locale: locale.locale,
              })
            }
          } catch (err) {
            logger.error('ERROR FETCHING TOIL REQUEST', err)
          }
          dispatch(setToilRequestActionEvent(event))
        }
        break  
      default:
        break
    }
  }

  useEffect(() => {
    if (!authCompany || !authUser.id) {
      handleUnsubscribe(sub.current)
      return
    }

    sub.current.push(handleSubscription() as ZenObservable.Subscription)

    return () => {
      handleUnsubscribe(sub.current)
    }
  }, [authCompany, authUser])
  return (<></>)
}

export default CompanyUpdatesNotifications
