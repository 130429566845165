import React, { useEffect, useState, useContext } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Breadcrumb, Table, Tag, Modal, Button, Spin, Tooltip, notification } from 'antd'
import { DeleteOutlined, SendOutlined, ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons'
import { useIntl } from 'react-intl'
import { ColumnsType } from 'antd/lib/table'
import { format, addMonths, setDate, setHours, setMinutes, isBefore } from 'date-fns'
import { API, graphqlOperation } from 'aws-amplify'

import * as logger from '../../../../services/logger'
import { getScheduledReports } from '../../../../graphql/custom-queries'
import { selectAuthUserSlice } from '../../../../store/auth-user-slice'
import { useAppSelector } from '../../../../store/hooks'
import { getTextWidth } from '../../../../util/get-text-width'
import { notificationStore } from '../../../../context/notificationsContext/store'
import { useShouldEnableFeatures } from '../../../../store/use-should-enable-features'
import { TimeUtcToLocal } from '../../../../util/NotificationTimezoneConverter'
import { convertHourFormats } from '@vacationtracker/shared/functions/convert-between-hour-formats'

import CircularProgress from '../../../../components/circular-progress'
import IntlMessages from '../../../../util/IntlMessages'
import NotFoundPage from '../../../NotFoundPage'

import { ISendReportMailPayload } from '@vacationtracker/shared/types/scheduled-report'
import { IGetScheduledReportsData, IGetScheduledReport } from '@vacationtracker/shared/types/scheduled-report'
import { SubscriptionPlanEnum } from '@vacationtracker/shared/types/company'
import { NotificationType } from '@vacationtracker/shared/types/notification'
import { FeatureFlagEnum } from '@vacationtracker/shared/types/feature-flags'
import { HourFormatEnum } from '@vacationtracker/shared/types/user'


const { confirm } = Modal

const ScheduledReportsPage: React.FC = () => {
  const now = new Date()
  const history = useHistory()
  const { actionNotifications, setActionNotifications } = useContext(notificationStore)
  const { formatMessage } = useIntl()
  const { authUser: { hourFormat } } = useAppSelector(selectAuthUserSlice)


  const [isLoading, setIsLoading] = useState(true)
  const [sendingMail, setSendingMail] = useState<string[]>([])
  const [sheduledReportList, setScheduledReportList] = useState<IGetScheduledReport[]>([])
  const shouldEnableFeatures = useShouldEnableFeatures(SubscriptionPlanEnum.complete, FeatureFlagEnum.scheduledReports)

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    fetchData()
  }, [actionNotifications])

  const fetchData = async () => {
    try {
      const response = await API.graphql(graphqlOperation(getScheduledReports)) as IGetScheduledReportsData
      setScheduledReportList(response.data.getScheduledReportList)

      setIsLoading(false)
    } catch (err) {
      logger.error('error fetching user by id', err)
    }
  }

  const deleteReport = async (scheduledReportId: string, name: string) => {
    let response
    try {
      response = await API.post('CoreEvent', '/core/event', {
        body: {
          eventType: 'SCHEDULED_REPORT_DELETED',
          eventGroup: 'REPORT',
          scheduledReportId,
        },
      })

      notification.open({
        key: response.correlationId,
        message: formatMessage({ id: 'scheduledReports.deleteInProgress' }, { scheduledReportName: name }),
        icon: <LoadingOutlined />,
        duration: 0,
      })
      setActionNotifications([
        ...actionNotifications,
        response.correlationId,
      ])
    } catch (error) {
      logger.error(error)
      if (error.response?.data?.error) {
        notification.error({
          message: formatMessage({ id: 'app.deleteFailed' }),
          description: formatMessage({ id: error.response.data.error }),
          duration: 0,
        })
      } else {
        const description = response.correlationId ? formatMessage({ id: 'app.correlationIdError' }, { correlationId: response.correlationId }) : JSON.stringify(error)

        notification.error({
          message: formatMessage({ id: 'app.deleteFailed' }),
          description,
          duration: 0,
        })
      }
    }
  }

  const showConfirmDelete = (row, event) => {
    event.stopPropagation()
    confirm({
      title: formatMessage({ id: 'scheduledReports.deleteReportTitle' }),
      icon: <ExclamationCircleOutlined />,
      content: formatMessage({ id: 'scheduledReports.deleteReportConfirm' }, { name: row.name }),
      okText: formatMessage({ id: 'app.delete' }),
      okType: 'danger',
      maskClosable: true,
      onOk() {
        deleteReport(row.id, row.name)
      },
    })
  }

  const sendMail = async (row, event) => {
    event.stopPropagation()
    setSendingMail(oldValue => [...oldValue, row.id])
    try {
      const body: ISendReportMailPayload = {
        name: row.name,
        reportType: row.reportType,
        teams: row.teams.map(team => team.id),
        locations: row.locations.map(location => location.id),
        labels: row.labels.map(label => label.id),
        receivers: row.receivers,
      }

      if (row.reportType === NotificationType.leaveBalanceReport) {
        body.period = row.period
      }

      if (row.reportType === NotificationType.leaveHistoryReport) {
        body.leaveStatus = row.leaveStatus
        body.period = row.period
      }

      await API.post('CoreEvent', '/core/send-report-mail', { body })
      notification.success({
        message: formatMessage({ id: 'scheduledReports.reportSentSuccessfully' }),
      })
    } catch (error) {
      logger.error('ERROR HANDLE SEND REPORT NOW', error)
      notification.error({
        message: formatMessage({ id: 'app.deliveryFailed' }),
        description: formatMessage({ id: 'error.somethingWentWrong' }),
      })
    }
    setSendingMail(oldValue => oldValue.filter(value => value !== row.id))
  }

  const openReport = (row) => {
    history.push(`/app/reports/scheduled-reports/${row.id}/edit`)
  }

  const scheduledReportColumns: ColumnsType<IGetScheduledReport> = [
    {
      title: <IntlMessages id="app.name" />,
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: <IntlMessages id="scheduledReports.locations" />,
      dataIndex: 'locations',
      key: 'locations',
      // eslint-disable-next-line react/display-name
      render: (locations: string, row: IGetScheduledReport) => {
        if(locations.length === 0) {
          return <IntlMessages id="scheduledReports.allLocations" />
        } else {
          return row.locations.length > 0 ?
            row.locations.map((loc, idx) => {
              return <span key={loc.id}>{loc.name}{idx === row.locations.length - 1 ? '' : ', '}</span>
            }) :
            <Tag color="red"><IntlMessages id="error.deletedLocation" /></Tag>
        }
      },
    },
    {
      title: <IntlMessages id="app.departments" />,
      dataIndex: 'teams',
      key: 'teams',
      // eslint-disable-next-line react/display-name
      render: (teams: string, row) => {
        if(teams.length === 0) {
          return <IntlMessages id="scheduledReports.allTeams" />
        } else {
          return row.teams.length > 0 ? row.teams[0].name : <Tag color="red"><IntlMessages id="error.deletedTeam" /></Tag>
        }
      },
    },
    {
      title: <IntlMessages id="app.reportType" />,
      dataIndex: 'reportType',
      key: 'reportType',
      // eslint-disable-next-line react/display-name
      render: (reportType: string) => {
        return <IntlMessages id={`export.${reportType}`} />
      },
    },
    {
      title: <IntlMessages id="scheduledReports.deliveryDate" />,
      dataIndex: 'sendDayOfMonth',
      key: 'sendDayOfMonth',
      // eslint-disable-next-line react/display-name
      render: (sendDayOfMonth: string, row: IGetScheduledReport) => {
        const { hour, minute } = TimeUtcToLocal(row.sendMinute, row.sendHour, row.timezone)
        const { value, amOrPm } = convertHourFormats(hourFormat || HourFormatEnum.twentyFour, Number(hour))

        const nextReport = setDate(setHours(setMinutes(now, Number(minute)), Number(hour)), Number(sendDayOfMonth))
        let nextDeliveryDate = `${format(now, 'MMM')} ${sendDayOfMonth}`
        if(isBefore(nextReport, now)) {
          nextDeliveryDate = `${format(addMonths(now, 1), 'MMM')} ${sendDayOfMonth}`
        }

        return (<p style={{ whiteSpace: 'pre-line', margin: 0 }}>{nextDeliveryDate} {value}:{minute} {amOrPm}</p>)
      },
    },
    {
      title: <IntlMessages id="app.recipients" />,
      dataIndex: 'receivers',
      key: 'receivers',
      width: 330,
      render: (receivers: string[]) => {
        let show
        let moreItems = 0
        receivers.forEach((receiver, index) => {
          if(index === 0) {
            show = receiver
            return
          }
          const width = getTextWidth(`${show}, ${receiver}`, 'normal 14px NoirPro')
          if(width > 540) {
            moreItems++
          } else {
            if(index === receivers.length) {
              show = `${show} and ${receiver}`
            } else {
              show = `${show}, ${receiver}`
            }
          }
        })
        if(moreItems > 0) {
          show = `${show} and ${moreItems} more`
        }
        return show
      },
    },
    {
      title: '',
      className: 'action',
      width: 124,
      dataIndex: 'id',
      key: 'id',
      // eslint-disable-next-line react/display-name
      render: (id: string, row) => (<>
        <Tooltip title={<IntlMessages id="app.sendNow" />}>
          <Button
            type="link"
            disabled={row.error ? true : false}
            onClick={(event) => sendMail(row, event)}>{sendingMail.includes(id) ? <Spin size="small" /> : <SendOutlined />}
          </Button>
        </Tooltip>
        <Button type="link" onClick={(event) => showConfirmDelete(row, event)}><DeleteOutlined /></Button>
      </>),
    },
  ]

  if (!shouldEnableFeatures) {
    return <NotFoundPage />
  }

  return (
    <div className='main-content'>
      <div className="main-content-header">
        <div className="main-content-header-title">
          <span><IntlMessages id="app.scheduledReports" /></span>
        </div>
        <div className="main-content-header-breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/app/dashboard"><IntlMessages id="sidebar.dashboard" /></Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item><IntlMessages id="sidebar.reports" /></Breadcrumb.Item>
            <Breadcrumb.Item><IntlMessages id="app.scheduledReports" /></Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <div className="main-content-body">
        {isLoading ?
          <CircularProgress /> :
          <>
            <div className="btn-actions" style={{ paddingBottom: 10 }}>
              <Link to="/app/reports/scheduled-reports/create" className="ant-btn ant-btn-default"><IntlMessages id="scheduledReports.add" /></Link>
            </div>
            <Table
              dataSource={sheduledReportList}
              columns={scheduledReportColumns}
              className="clickable-table"
              rowClassName={record => {
                if(record.error) {
                  return 'scheduled-report-error'
                }
                return ''
              }}
              onRow={(record) => {
                return {
                  onClick: () => openReport(record),
                }
              }}
              rowKey="id"
              pagination={false}
            />
          </>
        }
      </div>
    </div>
  )
}

export default ScheduledReportsPage
