import React, { useEffect, useState } from 'react'
import { Modal, Form, Select, notification as antdNotification, Button, Typography, FormInstance } from 'antd'
import { useIntl } from 'react-intl'
import { API } from 'aws-amplify'

import * as logger from '../../services/logger'
import { MicrosoftAuth } from '../../services/auth/microsoft/microsoft'
import { useAppSelector } from '../../store/hooks'
import { selectAuthCompanySlice } from '../../store/auth-company-slice'

import IntlMessages from '../../util/IntlMessages'
import { filterOptions } from '../../util/filter-options'

import { IMicrosoftGroup } from '../../types/microsoft'
import { INotificationForm } from '../../types/notifications'


interface IChannel {
  id: string
  name?: string
}

interface ISelectChannelFromMicrosoft {
  name: string[]
  msGroupName: string[]
  form: FormInstance
  notification: INotificationForm
  onSelectDestination: (e) => void
  setChannelName: (e) => void
}

const { Option } = Select
const { Paragraph } = Typography

if (!process.env.REACT_APP_MICROSOFT_CLIENT_ID) throw new Error('REACT_APP_MICROSOFT_CLIENT_ID is required')
const msAuth = new MicrosoftAuth(process.env.REACT_APP_MICROSOFT_CLIENT_ID)

export const SelectChannelFromMicrosoft = ({ name, msGroupName, onSelectDestination, setChannelName, form, notification }: ISelectChannelFromMicrosoft): React.ReactElement => {
  const { formatMessage } = useIntl()
  const { authCompany } = useAppSelector(selectAuthCompanySlice)
  const [channels, setChannels] = useState<IChannel[]>([])
  const [groups, setGroups] = useState<IMicrosoftGroup[]>([])
  const [selectedGroup, setSelectedGroup] = useState<string | undefined>()
  const [showMsLoginRequiredModal, setShowMsLoginRequiredModal] = useState<boolean>(false)

  useEffect(() => {
    fetchMsData()
  }, [])

  useEffect(() => {
    if(notification && notification[name[0]] && notification[name[0]].msGroup) {
      const msGroup = notification[name[0]].msGroup
      setSelectedGroup(msGroup)
      fetchMsChannels(msGroup)
    }
  }, [notification])

  useEffect(() => {
    if(notification && !notification[name[0]]) {
      const rawStateFromStorage = localStorage.getItem('notificationForm')
      const stateFromStorage = rawStateFromStorage && JSON.parse(rawStateFromStorage)

      const msGroup = stateFromStorage && stateFromStorage[msGroupName[0]].msGroup
      if (msGroup) {
        setSelectedGroup(msGroup)
        fetchMsChannels(msGroup)
      }
    }
  }, [notification])

  const fetchMsData = () => {
    try {
      const tokens = msAuth.getTokens()
      fetchDataFromMsApi(tokens.accessToken)
    } catch(err) {
      setShowMsLoginRequiredModal(true)
    }
  }

  const cleanTeamAndChannelFromStorage = () => {
    const rawStateFromStorage = localStorage.getItem('notificationForm')
    const stateFromStorage = rawStateFromStorage && JSON.parse(rawStateFromStorage)

    if (stateFromStorage && stateFromStorage[msGroupName[0]]) {
      delete stateFromStorage[msGroupName[0]]
      localStorage.setItem('notificationForm', JSON.stringify(stateFromStorage))
    }
  }

  const fetchDataFromMsApi = async (accessToken: string, shouldReload?: boolean): Promise<void> => {
    if (!authCompany?.msTeamId) {
      throw new Error('Missing company.msTeamId')
    }

    if (shouldReload) {
      if (notification?.destinations?.length === 0) {
        cleanTeamAndChannelFromStorage()
      }
      window.location.reload()
    }

    try {
      const tenantId = await API.get('CoreEvent', '/microsoft/get-tenant', {}) as string
      if (authCompany.msTeamId === tenantId) {
        await fetchJoinedTeams(accessToken)
      } else {
        await fetchMsChannels(authCompany.msTeamId)
      }
    } catch(error) {
      logger.warning(error)
      getMsTokens()
    }
  }

  const fetchJoinedTeams = async (msToken) => {
    try {
      const response = await msAuth.getJoinedTeams(msToken)

      if (!response) {
        throw new Error('No groups')
      }

      setGroups(response)
    } catch (caughtErr) {
      let error = caughtErr
      if (typeof error === 'string' && JSON.parse(error)) {
        error = JSON.parse(error)
      }

      if (error.data.error.code === 'NotFound') {
        handleErrorNotification({ message: formatMessage({ id: 'components.selectChannelForm.errorNotInTeams' }) })
      } else if (error.data.error.code === 'InvalidAuthenticationToken' || error.data.error.code === 'Unauthorized') {
        setShowMsLoginRequiredModal(true)
      } else {
        handleErrorNotification(error)
      }
      setGroups([])
    }
  }

  const fetchMsChannels = async (msTeamId: string) => {
    try {
      const response = await msAuth.getChannels(msTeamId)

      const channelsData = response
      // Exclude private channels, as bot can't be a member
        .filter(channel => channel.membershipType === 'standard')
        .map((channel): IChannel => {
          // An empty channel ID is not possible, we add an empty string just to make TypeScript happy
          return {
            id: channel.id || '',
            name: channel.displayName,
          }
        })
      setChannels(channelsData)

      const formData = form.getFieldsValue()
      if (name[0] && formData[name[0]]) {
        const channelId = formData[name[0]].addresses
        if (channelId) {
          const channel = channelsData.find(v => channelId === v.id)
          setChannelName(channel?.name)
        }
      }
    } catch (caughtErr) {
      let error = caughtErr
      if (typeof error === 'string' && JSON.parse(error)) {
        error = JSON.parse(error)
      }

      if (error.data.error.code === 'NotFound') {
        handleErrorNotification(
          { message: formatMessage({ id: 'error.microsoft.notMemberOfTeam' }, { msTeamId }) },
          undefined,
          formatMessage({ id: 'error.microsoft.channelNotFound' })
        )
      } else if (error.data.error.code === 'InvalidAuthenticationToken' || error.data.error.code === 'Unauthorized') {
        setShowMsLoginRequiredModal(true)
      } else {
        handleErrorNotification(error)
      }
    }
  }

  const getMsTokens = (shouldReload?: boolean) => {
    msAuth.signin(['user.readbasic.all', 'team.readbasic.all', 'Channel.ReadBasic.All'])
      .then(([tokens]) => {
        setShowMsLoginRequiredModal(false)
        return fetchDataFromMsApi(tokens.accessToken, shouldReload)
      })
      .catch(err => {
        if (err === 'access-denied-cancel') {
          const consentLink = `https://login.microsoftonline.com/${msAuth.getTenantId()}/adminconsent?client_id=${process.env.REACT_APP_MICROSOFT_CLIENT_ID}`
          antdNotification.error({
            key: err,
            message: formatMessage({ id: 'microsoft.adminConsentErrorTitle' }),
            description: (<>
              <Paragraph>{formatMessage({ id: 'microsoft.adminConsentErrorDescription1'})}</Paragraph>
              <Paragraph copyable={{ text: consentLink }}>
                <a
                  href={consentLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ wordBreak: 'break-word' }}
                >
                  {consentLink}
                </a>
              </Paragraph>
              <p>{formatMessage({ id: 'microsoft.adminConsentErrorDescription2'})}</p>
            </>),
            btn: (<Button onClick={() => {
              getMsTokens()
              antdNotification.close(err)
            }}>
              {formatMessage({ id: 'microsoft.adminConsentErrorButton'})}
            </Button>),
            duration: 0,
          })
        }
        setShowMsLoginRequiredModal(false)
      })
  }

  const handleErrorNotification = (error, correlationId?: string, title?: string): void => {
    if (error.data?.error?.message) {
      antdNotification.error({
        message: formatMessage({ id: 'connect.interactionRequiredMSErrorTitle' }),
        description: <div>
          <Paragraph>{ formatMessage({ id: 'connect.interactionRequiredMSErrorDescription1' }) }</Paragraph>
          <Paragraph>{ formatMessage({ id: 'connect.interactionRequiredMSErrorDescription2' }) }</Paragraph>
          <Paragraph keyboard copyable>{ error.data?.error?.message }</Paragraph>
        </div>,
        duration: 0,
        key: 'permission-error',
        btn: <Button onClick={() => {
          antdNotification.close('permission-error')
          getMsTokens(true)
        }}>{ formatMessage({ id: 'connect.login' }) }</Button>,
      })
    } else {
      const description = correlationId ?
        formatMessage({ id: 'error.notificationGeneral' }, { correlationId }) :
        error.response?.data?.message
          ? error.response?.data?.message
          : error.message
            ? error.message
            : JSON.stringify(error)
      antdNotification.error({
        message: title ? title : formatMessage({ id: 'error.notificationGeneral' }),
        description,
        duration: 0,
      })
    }
  }

  const onSelect = (channelId) => {
    onSelectDestination(channelId)
    const channelName = channels.find(channel => channel.id === channelId)?.name
    setChannelName(channelName)
  }

  const setGroup = (group: string) => {
    setSelectedGroup(group)
    fetchMsChannels(group)
  }

  return (
    <>
      {groups && groups.length > 0 &&
        <Form.Item
          name={msGroupName}
          rules={[{ required: true, message: <IntlMessages id="form.inputRequired" />}]}
        >
          <Select style={{ width: 250, marginLeft: 5 }}
            placeholder={<IntlMessages id="components.selectChannelFormMicrosoft.teamPlaceholder" />}
            showSearch
            filterOption={filterOptions}
            onSelect={(event) => {
              setGroup(event ? event.toString() : '')
            }}
            value={selectedGroup}
          >
            {groups.map(group => <Select.Option key={group.id} value={group.id}>{group.displayName}</Select.Option>)}
          </Select>
        </Form.Item>
      }

      <Form.Item
        name={name}
        noStyle
        rules={[{ required: true, message: <IntlMessages id="form.inputRequired" /> }]}
      >
        <Select style={{ width: 250, marginLeft: 5 }}
          placeholder={<IntlMessages id="components.selectChannelFormMicrosoft.channelPlaceholder" />}
          showSearch
          filterOption={filterOptions}
          disabled={!channels.length}
          loading={!channels.length && Boolean(selectedGroup)}
          onSelect={onSelect}
        >
          {channels.map(channel => <Option key={channel.id} value={channel.id}>{channel.name}</Option>)}
        </Select>
      </Form.Item>

      {showMsLoginRequiredModal &&
        <Modal
          title={formatMessage({ id: 'error.microsoft.notificationsLoginRequiredTitle' })}
          visible={showMsLoginRequiredModal}
          onOk={() => getMsTokens()}
          onCancel={() => {
            setShowMsLoginRequiredModal(false)
          }}
        >
          <p><IntlMessages id="microsoft.notificationsLoginRequiredDescription1" /></p>
          <p><IntlMessages id="microsoft.notificationsLoginRequiredDescription2" /></p>
        </Modal>
      }
    </>
  )
}
