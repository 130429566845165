import React, { useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Modal, notification, Spin, Typography, Input, Form, Col, Row } from 'antd'
import Icon, { GoogleOutlined, SlackOutlined, WindowsFilled, MailOutlined } from '@ant-design/icons'
import { useIntl } from 'react-intl'
import qs from 'qs'
import * as Sentry from '@sentry/react'
import { random, find } from 'lodash'
import axios from 'axios'
import { Auth, API, graphqlOperation } from 'aws-amplify'
import { usePostHog } from 'posthog-js/react'
import { getCompanyAndUserInfo } from '../../../graphql/custom-queries'
import { updateSlackToken } from '../../../services/api/slack'

import { MicrosoftAuth } from '../../../services/auth/microsoft/microsoft'
import { SlackAuth } from '../../../services/auth/slack/slack'
import { getUserId as getMsUserId } from '../../../services/api/microsoft'
import { getUserId as getGoogleUserId } from '../../../services/api/google'
import { GoogleAuth } from '../../../services/auth/google/google'
import { track } from '../../../services/analytics/analytics'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { setAuthCompany } from '../../../store/auth-company-slice'
import { setUserId } from '../../../store/user-id-slice'
import { setAuthUser, selectAuthUserSlice } from '../../../store/auth-user-slice'
import { logout } from '../../../services/auth/logout-handler'
import { setLocale } from '../../../store/locale-slice'
import { availableLanguages } from '@vacationtracker/shared/i18n'
import { setCrispSessionInfo } from '../../../util/set-crisp-session-info'

import IntlMessages from '../../../util/IntlMessages'
import OtherPlatformLoginModal from '../../../components/other-platform-login-modal'
import SignupWithAnotherPlatformModal from '../../../components/signup-with-another-platform-modal'
import CompanyAlreadySignedUpModal from '../../../components/company-already-signed-up-modal'
import CompanyExistsModal from '../../../components/modal-company-exists'
import { SeoTags } from '../../../components/seo-tags'
import VtLogo from '../../../assets/images/logo-purple.svg'

import capterraRating from '../../../assets/images/capterra-rating.png'

import { IGetCompanyAndUserInfo } from '../../../types/custom-queries'
import { wait } from '@vacationtracker/shared/functions/wait'
import { ICheckUserId } from '@vacationtracker/shared/types/company'

import { testimonials } from '../../../data/testimonials'
import { FrontendUrls } from '../../../types/urls'
import { IConnect } from '../types'
import { IContactAdminData } from '../../../types/auth'
import { CrispSegmentsEnum } from '../../../types/crisp'
import { Platform } from '@vacationtracker/shared/types/core-event'
import { EmailNotValid, UserExistsException, UserNotFound } from '@vacationtracker/shared/errors/users'
import { openSupportChat } from '../../../util/open-support-chat'
import { GoogleLogo } from '../../../components/custom-svg-icons'
import { getCookieByName, parseAndPrepareUtmCookie } from '../../../util/get-and-parse-cookie'
import { SLACK_REQUIRED_BOT_SCOPES, SLACK_REQUIRED_USER_SCOPES } from '@vacationtracker/shared/data/slack'

const { Link, Paragraph, Text, Title } = Typography

if (!process.env.REACT_APP_MICROSOFT_CLIENT_ID || !process.env.REACT_APP_SLACK_CLIENT_ID || !process.env.REACT_APP_GOOGLE_CLIENT_ID) {
  throw new Error('Client ID are required')
}

const msAuth = new MicrosoftAuth(process.env.REACT_APP_MICROSOFT_CLIENT_ID)
const slackAuth = new SlackAuth(process.env.REACT_APP_SLACK_CLIENT_ID)
const googleAuth = new GoogleAuth(process.env.REACT_APP_GOOGLE_CLIENT_ID)

const SignupA = ({ onStateChange, location, authState }: IConnect): React.ReactElement => {
  const { formatMessage } = useIntl()
  const history = useHistory()
  const [form] = Form.useForm()
  const dispatch = useAppDispatch()
  const posthog = usePostHog()

  const { authUser } = useAppSelector(selectAuthUserSlice)
  const [redirectUrl, setRedirectUrl] = useState(FrontendUrls.dashboard)
  const [selectedPlatform, setSelectedPlatform] = useState('')
  const [userEmail, setUserEmail] = useState('')
  const [userWorkspaceName, setUserWorkspaceName] = useState('')
  const [signinInProgress, setSigninInProgress] = useState(false)
  const [showSlackLoginRequiredModal, setShowSlackLoginRequiredModal] = useState(false)
  const [companyExists, setCompanyExists] = useState(false)
  const [contactAdminModal, setContactAdminModal] = useState<IContactAdminData | null>(null)
  const [otherPlatformLogin, setOtherPlatformLogin] = useState(false)
  const [existsOnAnotherPlatform, setExistsOnAnotherPlatform] = useState('')
  const [existingOrganizationName, setExistingOrganizationName] = useState('')
  const [companyAlreadySignedUpShowModal, setCompanyAlreadySignedUpShowModal] = useState(false)
  const [signupWithAnotherPlatformShowModal, setSignupWithAnotherPlatformShowModal] = useState(false)
  const [domainPlatform, setDomainPlatform] = useState<string | null>(null)
  const [focusedPlatform, setFocusedPlatform] = useState<Platform | null>(null)

  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true })
  const randomized = useRef(random(1, 12))

  useEffect(() => {
    if (queryParams?.redirect) {
      setRedirectUrl(queryParams.redirect as FrontendUrls)
    }

    if (queryParams?.subscriptionPlan && ['Core', 'Complete'].includes(queryParams.subscriptionPlan as string)) {
      localStorage.setItem('vtSubscriptionPlan', queryParams.subscriptionPlan as string)
    }
    if (queryParams?.affiliateCode) {
      localStorage.setItem('vtAffiliateCode', queryParams.affiliateCode as string)
    }

    if (authUser.id && authState === 'signedIn') {
      history.push(redirectUrl)
    }
  }, [authState, authUser])

  useEffect(() => {
    onStateChange('signUp')
    track('SIGNUP_PAGE_VIEWED', {})
    localStorage.removeItem('vtCreateUser')
    localStorage.removeItem('vtCreateCompany')
    localStorage.removeItem('vtSelectedUsers')
    localStorage.removeItem('vtEmailInvites')
    setCrispSessionInfo(
      [CrispSegmentsEnum.chat, CrispSegmentsEnum.support, CrispSegmentsEnum.dashboard, CrispSegmentsEnum.onSignupPage],
      { signupStep: 'signup-screen'}
    )
    const emailFromSessionStorage = sessionStorage.getItem('vtUserEmail')
    if (emailFromSessionStorage) {
      form.setFieldsValue({ email: emailFromSessionStorage })
      setUserEmail(emailFromSessionStorage)
    }

    // Handle redirects
    if (queryParams?.redirect || queryParams?.forgotpassword) {
      history.push(FrontendUrls.signin + location.search)
      return
    }

    if (queryParams?.platform) {
      setFocusedPlatform(queryParams.platform as Platform)
    }
  }, [])

  useEffect(() => {
    if (userEmail) {
      sessionStorage.setItem('vtUserEmail', userEmail)
    }
  }, [userEmail])

  const getSlackTokens = () => {
    slackAuth.signin(
      SLACK_REQUIRED_BOT_SCOPES,
      SLACK_REQUIRED_USER_SCOPES
    ).then(async (response) => {
      setUserWorkspaceName(response.team.name)
      slackAuth.setBotToken(response.access_token)
      slackAuth.setUserToken(response.authed_user.access_token)
      await updateSlackToken(response.authed_user.access_token, response.access_token)
      setShowSlackLoginRequiredModal(false)
      dispatch(setUserId(localStorage.getItem('userId') as string))
    }).catch(error => {
      notification.error({
        message: formatMessage({ id: 'error.notificationGeneral' }),
        description: `Slack error: ${error?.error}${error.needed ? ` ${error.needed}` : ''}`,
        duration: 0,
      })
    })
  }

  const getUsersByEmail = async (email: string) => {
    const res = await axios.post(`${process.env.REACT_APP_API_URL}/core/auth-info`, {
      email,
    })
    const platform: Platform = res.data.platform
    const companyName: string = res.data.companyName
    setSelectedPlatform('email')
    if (platform !== 'email') {
      setExistsOnAnotherPlatform(platform)
      setExistingOrganizationName(companyName)
      setCompanyAlreadySignedUpShowModal(true)
      return
    } else if (platform === 'email') {
      throw new Error('ALREADY_SIGNED_UP')
    }
    localStorage.setItem('vtCreateUser', JSON.stringify({
      platform: 'email',
      mail: email,
      userEmail: email,
    }))
    setUserEmail(email)
  }

  let numberOfRetry = 0
  const getCompanyAndUser = async (id: string) => {
    try {
      const response = await API.graphql(graphqlOperation(getCompanyAndUserInfo, { userId: id })) as IGetCompanyAndUserInfo
      if (response.data.getCompany && response.data.getUser && response.data.getUser.name) {
        dispatch(setAuthCompany(response.data.getCompany))
        dispatch(setAuthUser(response.data.getUser))
        if (response.data.getUser.locale) {
          dispatch(setLocale(availableLanguages[response.data.getUser.locale]))
        }
        Sentry.setUser({
          id: response.data.getUser.id,
          companyId: response.data.getCompany.id,
        })
      } else {
        throw new Error('No current user, retry')
      }
    } catch (error) {
      console.debug('ERROR GET COMPANY AND USER', error)
      if (numberOfRetry >= 10) {
        logout({
          onStateChange,
          history,
          reduxDispatch: dispatch,
          userId: id,
        })
      } else if (![FrontendUrls.signin, FrontendUrls.signup].includes(location.pathname as FrontendUrls)) {
        numberOfRetry++
        await wait(200 * numberOfRetry)
        return await getCompanyAndUser(id)
      }
    }
  }

  const authSigin = async (username: string, token: string, platform: string, botToken?: string) => {
    const signInResponse = await Auth.signIn(username)
    if (signInResponse.challengeName === 'CUSTOM_CHALLENGE' && signInResponse.challengeParam.question === 'token') {
      const cognitoResponse = await Auth.sendCustomChallengeAnswer(signInResponse, token, { loginType: platform })
      localStorage.setItem('userId', cognitoResponse.username)

      if (platform === 'slack') {
        try {
          await updateSlackToken(token, botToken)
        } catch (error) {
          if(error?.response?.data?.error && (error?.response.data.error === 'error.botTokenNotExist' || error?.response.data.error === 'error.botTokenNotValid')) {
            setSigninInProgress(false)
            setShowSlackLoginRequiredModal(true)
            return
          }
        }
      }
      dispatch(setUserId(cognitoResponse.username))
      await getCompanyAndUser(cognitoResponse.username)
      track('LOGIN_COMPLETED', {
        platform,
        timestamp: new Date().toDateString(),
        source: 'app',
        status: 'completed',
        fromPage: 'singup', // For testig mix panel
      })
      onStateChange('signedIn')
      history.push(redirectUrl)
    }
  }

  const handleUserData = (userData: ICheckUserId, platform, otherCreateUserData) => {
    setUserEmail(userData.mail || '')
    setSelectedPlatform(platform)
    if(userData.type === 'USER_NOT_FOUND' && userData.subscriptionStatus === 'canceled') {
      notification.error({
        message: formatMessage({ id: 'connect.subscriptionExpiredTitle' }),
        description: formatMessage({ id: 'error.subscriptionExpired' }),
        duration: 0,
      })
      return
    }

    if(userData.type === 'USER_NOT_FOUND') {
      setContactAdminModal({
        platform,
        organizationName: userData.organizationName || '',
        contactEmail: userData.contactEmail || '',
        adminContacts: userData.adminContacts || [],
      })
      track('ORG_ALREADY_EXISTS_PAGE_VIEWED', { platform, organizationName: userData.organizationName })
      setCompanyExists(true)
      setSigninInProgress(false)
      return
    }

    if (userData.type === 'COMPANY_NOT_FOUND') {
      localStorage.setItem('vtCreateUser', JSON.stringify({
        name: userData.name,
        id: `${platform}-${userData.id}`,
        mail: userData.mail,
        userEmail: userData.mail,
        platform,
        ...otherCreateUserData,
      }))

      if (userData.existsOnAnotherPlatform && userData.existingOrganizationName) {
        track('ORG_EXISTS_ON_ANOTHER_PLATFORM_OPEN_MODAL', { platform, existingOrganizationName: userData.existingOrganizationName })
        setExistsOnAnotherPlatform(userData.existsOnAnotherPlatform)
        setExistingOrganizationName(userData.existingOrganizationName)
        setOtherPlatformLogin(true)
        return
      }
    }
    goToSignupStep(platform)
  }

  const handleSigninError = (error, platform) => {
    setSigninInProgress(false)
    const errorMessage = (typeof error === 'object' && !(error instanceof Error)) ? (
      error.error_description ? error.error_description : (
        error.error ? error.error : JSON.stringify(error, null, 2)
      )
    ) : (
      typeof error === 'string' ? error : error.toString()
    )
    let platformText
    if (platform === 'microsoft') {
      track('CONNECT_WITH_MICROSOFT_ERROR', { errorMessage, platform })
      platformText = 'Microsoft Teams'
    } else if (platform === 'google') {
      track('CONNECT_WITH_GOOGLE_ERROR', { errorMessage, platform })
      platformText = 'Google'
    } else if (platform === 'slack') {
      track('CONNECT_WITH_SLACK_ERROR', { errorMessage, platform })
      platformText = 'Slack'
    }

    // This can turn out to be a switch statement if we encounter more error cases
    // that require different actions/descriptions
    if (errorMessage.includes('interaction_required') || errorMessage.includes('invalid_grant')) {
      notification.error({
        message: formatMessage({ id: 'connect.interactionRequiredMSErrorTitle' }),
        description: <div>
          <Paragraph>{ formatMessage({ id: 'connect.interactionRequiredMSErrorDescription1' }) }</Paragraph>
          <Paragraph>{ formatMessage({ id: 'connect.interactionRequiredMSErrorDescription2' }) }</Paragraph>
          <Paragraph keyboard copyable>{ errorMessage }</Paragraph>
        </div>,
        duration: 0,
        btn: <Button onClick={() => connectWithMicrosoft(true)}>{ formatMessage({ id: 'connect.login' }) }</Button>,
      })
    } else {
      notification.error({
        message: formatMessage({ id: 'connect.platformErrorTitle' }),
        description: <div>
          <Paragraph>{ formatMessage({ id: 'connect.platformErrorDescription1' }) }</Paragraph>
          <Paragraph keyboard copyable>{ errorMessage }</Paragraph>
          <Paragraph>{ formatMessage({ id: 'connect.platformErrorDescription2' }, { platform: platformText }) }</Paragraph>
        </div>,
        duration: 0,
      })
      // Do not track Slack "access_denied" in Sentry, as it's handled above
      if (!errorMessage.includes('access_denied')) {
        Sentry.captureException(error)
      }
    }
  }

  const connectWithSlackNew = () => {
    const platform = 'slack'
    setSigninInProgress(true)

    const utmCookie = getCookieByName('_vt_sc')

    let utmCookieDecoded
    if (utmCookie) {
      try {
        utmCookieDecoded = parseAndPrepareUtmCookie(utmCookie)
      } catch (error) {
        console.log('Error while parsing utm cookie', error)
      }
    }
    track('CONNECT_WITH_SLACK_BUTTON_CLICKED', { platform })
    track('SIGNUP_STARTED', {
      platform,
      timestamp: new Date().toDateString(),
      source: 'app',
      status: 'started',
      utmCookie: utmCookieDecoded,
    })

    slackAuth.connect()
      .then(async result => {
        const user = await slackAuth.getUser(result.authed_user.id, result.authed_user.access_token)
        history.push(`${FrontendUrls.installSlackBot}/${result.team.id}?realName=${user.real_name || ''}`)
      })
      .catch(e => {
        console.log('SLACK SIGNIN ERROR', e)
        handleSigninError(e, 'slack')
      })
  }

  const connectWithMicrosoft = (interactionRequired = false) => {
    const platform = 'microsoft'
    setSigninInProgress(true)
    const utmCookie = getCookieByName('_vt_sc')
    let utmCookieDecoded
    if (utmCookie) {
      try {
        utmCookieDecoded = parseAndPrepareUtmCookie(utmCookie)
      } catch (error) {
        console.log('Error while parsing utm cookie', error)
      }
    }
    track('CONNECT_WITH_MICROSOFT_BUTTON_CLICKED', { platform })
    track('SIGNUP_STARTED', {
      platform,
      timestamp: new Date().toDateString(),
      source: 'app',
      status: 'started',
      utmCookie: utmCookieDecoded,
    })
    // This requires promises, not async/await because window.open requires sync not async flow
    msAuth.signin(['user.readbasic.all', 'team.readbasic.all'], interactionRequired)
      .then(async ([tokens, msUser, tenantId]) => {
        try {
          await authSigin(`microsoft-${msUser.id}`, tokens.accessToken, platform)
        } catch (error) {
          msAuth.setTokens(tokens)
          const userData: ICheckUserId = await getMsUserId(tenantId, tokens.accessToken)
          handleUserData(userData, platform, { tenantId, msUserId: userData.id })
        }
      })
      .catch(e => handleSigninError(e, 'microsoft'))
  }

  const connectWithGoogle = () => {
    const platform = 'google'
    const utmCookie = getCookieByName('_vt_sc')
    let utmCookieDecoded
    if (utmCookie) {
      try {
        utmCookieDecoded = parseAndPrepareUtmCookie(utmCookie)
      } catch (error) {
        console.log('Error while parsing utm cookie', error)
      }
    }
    track('LOGIN_PAGE_LOGIN_WITH_GOOGLE_BUTTON_CLICKED', { platform })
    track('SIGNUP_STARTED', {
      platform,
      timestamp: new Date().toDateString(),
      source: 'app',
      status: 'started',
      utmCookie: utmCookieDecoded,
    })
    setSigninInProgress(true)
    googleAuth.signin()
      .then(async () => {
        const googleUser = googleAuth.getSignedInUser()
        if (!googleUser.hd) {
          notification.error({
            message: formatMessage({ id: 'connect.google.notWorkspaceUserTitle' }),
            description: formatMessage({ id: 'connect.google.notWorkspaceUserDescription' }),
            duration: 0,
          })
          return
        }
        try {
          await authSigin(`google-${googleUser.sub}`, googleAuth.getAccessToken() , platform)
        } catch (error) {
          const userData: ICheckUserId = await getGoogleUserId(googleAuth.getIdToken())
          handleUserData(userData, platform, {
            googleDomain: googleUser.hd,
            imageUrl: googleUser.picture,
            googleUserId: googleUser.sub,
          })
        }
      })
      .catch(e => handleSigninError(e, 'google'))
  }

  const connectWithEmail = () => {
    history.push(FrontendUrls.signup + '?platform=email')
    setFocusedPlatform('email')
  }

  const connectWithMail = async (v) => {
    setSigninInProgress(true)
    const utmCookie = getCookieByName('_vt_sc')
    let utmCookieDecoded
    if (utmCookie) {
      try {
        utmCookieDecoded = parseAndPrepareUtmCookie(utmCookie)
      } catch (error) {
        Sentry.captureException(error)
      }
    }
    track('SIGNUP_STARTED', {
      platform: 'email',
      timestamp: new Date().toDateString(),
      source: 'app',
      status: 'started',
      ...utmCookieDecoded,
    })
    const { email } = await form.validateFields()
    try {
      if (email) {
        setUserEmail(email)
        await getUsersByEmail(email)
      } else {
        throw new Error('EMAIL_REQUIRED')
      }
    } catch (error) {
      console.log('ERR', error.message, error.code, error.status, error.statusCode)
      setSigninInProgress(false)
      if (error.response?.data?.code === UserExistsException.code) {
        const { data } = error.response.data
        notification.error({
          message: formatMessage({ id: 'error.signup.alreadySignedUp.title' }),
          description: data.status.toUpperCase() === 'ACTIVE' ? (<>
            <Paragraph>{formatMessage({ id: 'error.signup.alreadySignedUp.descriptionLine1' }, {
              email: data.email,
              text: (chunks) => <Text keyboard>{...chunks}</Text>,
            })}</Paragraph>
            <Paragraph>{formatMessage({ id: 'error.signup.alreadySignedUp.descriptionLine2' })}</Paragraph>
          </>) : (<>
            <Paragraph>{formatMessage({ id: 'error.signup.alreadySignedUpInactive.descriptionLine1' }, {
              email: data.email,
              text: (chunks) => <Text keyboard>{...chunks}</Text>,
            })}</Paragraph>
            <Paragraph>{formatMessage({ id: 'error.signup.alreadySignedUpInactive.descriptionLine2' })}</Paragraph>
            <Paragraph code copyable>{ JSON.stringify({ cid: data.companyId, uid: data.id }, null, 4) }</Paragraph>
          </>),
          key: UserExistsException.code,
          btn: data.status.toUpperCase() === 'ACTIVE' ? (
            <Button onClick={() => {
              notification.close(UserExistsException.code)
              goToLogin()
            }}>{ formatMessage({ id: 'app.login' }) }</Button>
          ) : (
            <Button onClick={() => {
              notification.close(UserExistsException.code)
              openSupportChat()
            }}>{ formatMessage({ id: 'app.contactSupport' }) }</Button>
          ),
        })
        return
      }

      if (error.response?.data?.code === EmailNotValid.code) {
        notification.error({
          message: formatMessage({ id: 'error.emailError' }),
          key: 'NOT_VALID_EMAIL',
          description: <Text>{
            formatMessage({ id: 'form.validEmailRequired' })
          }</Text>,
        })
        return
      }
      if (error.message === 'ALREADY_SIGNED_UP') {
        notification.error({
          message: formatMessage({ id: 'error.signup.alreadySignedUp.title' }),
          description: <>
            <Text>{
              formatMessage(
                { id: 'error.signup.alreadySignedUp.descriptionLine1' },
                {
                  email,
                  text: (values) => <Text keyboard>{ ...values }</Text>,
                }
              )
            }</Text>
            <Paragraph>{ formatMessage({ id: 'error.signup.alreadySignedUp.descriptionLine2' }) }</Paragraph>
          </>,
          key: 'ALREADY_SIGNED_UP',
          btn: (<>
            <Button onClick={() => {
              goToLogin()
              notification.close('ALREADY_SIGNED_UP')
            }}>{ formatMessage({ id: 'app.login' }) }</Button>
            <Button onClick={() => {
              goToLogin('forgotpassword')
              notification.close('ALREADY_SIGNED_UP')
            }}>{ formatMessage({ id: 'app.resetPassword' }) }</Button>
          </>),
          duration: 0,
        })
        return
      } else if (error.message.includes('404')) {
        const { data } = error.response.data
        if (error.response?.data?.code === UserNotFound.code && data.domainPlatform) {
          setDomainPlatform(data.domainPlatform)
          setSignupWithAnotherPlatformShowModal(true)
          return
        }
        goToSignupStep('email', email as string)
      } else {
        Sentry.captureException(error)
      }
    }
  }

  const connectWithPlatform = (platform: string) => {
    track('ORG_EXISTS_ON_ANOTHER_PLATFORM_CHOOSE_EXISTING', { platform })
    localStorage.removeItem('createUser')
    setOtherPlatformLogin(false)
    if (platform === 'slack') {
      connectWithSlackNew()
    } else if (platform === 'microsoft') {
      connectWithMicrosoft()
    } else if (platform === 'google') {
      connectWithGoogle()
    }
  }

  const closeOtherPlatformLoginModal = () => {
    track('ORG_EXISTS_ON_ANOTHER_PLATFORM_CLOSE_MODAL', {})
    goToLogin()
  }

  const testimonial = find(testimonials, t => t.id === randomized.current)

  const goToLogin = (state?: string) => {
    onStateChange('signIn')
    const url = FrontendUrls.signin + (state ? `?${state}=true` : '')
    history.push(url)
  }

  const goToSignupStep = (platform: Platform, email?: string) => {
    if (process.env.REACT_APP_ENVIRONMENT === 'production') {
      posthog.startSessionRecording()
    }
    if (platform === 'email') {
      localStorage.setItem('vtCreateUser', JSON.stringify({
        platform: 'email',
        mail: email,
        userEmail: email,
      }))
      localStorage.setItem('vtEmailInvites', JSON.stringify([]))
    }
    onStateChange('signUp')
    history.push(FrontendUrls.createCompanyStep1)
  }

  const handleSignupWithMail = () => {
    if (process.env.REACT_APP_ENVIRONMENT === 'production') {
      posthog.startSessionRecording()
    }
    setSignupWithAnotherPlatformShowModal(false)
    localStorage.setItem('vtCreateUser', JSON.stringify({
      platform: 'email',
      mail: userEmail,
      userEmail: userEmail,
    }))
    localStorage.setItem('vtEmailInvites', JSON.stringify([]))
    history.push(FrontendUrls.createCompany)
  }

  return (
    <div className="auth-wrap">
      <SeoTags
        title='connect.meta.title.signup'
        description='connect.meta.description'
      />
      <div className="auth-container">
        <Row align="middle" justify="space-around" gutter={[{ xs: 16, lg: 48 },{ xs: 16, lg: 48 }]}>
          <Col span={24}>
            <div className="auth-sidebar-logo">
              <VtLogo />
            </div>
          </Col>
          <Col xs={{ span: 20 }} lg={{ span: 12 }}>
            <Spin spinning={signinInProgress}>
              <Title level={3} style={{ textAlign: 'center' }}>{<IntlMessages id='connect.signUpInfo' />}</Title>
              { (!focusedPlatform || focusedPlatform !== 'email') &&
                <div className="auth-sidebar-content-button">
                  {
                    (!focusedPlatform || focusedPlatform === 'slack') &&
                    <Button icon={<SlackOutlined />} size="large" data-qa="slack-connect" className="vt-auth" type="primary" block onClick={() => connectWithSlackNew()}>
                      <IntlMessages id="connect.signUpWithSlack" />
                    </Button>
                  }
                  {
                    (!focusedPlatform || focusedPlatform === 'microsoft') &&
                    <Button icon={<WindowsFilled />} size="large" data-qa="microsoft-connect" className="vt-auth" type="primary" block onClick={() => connectWithMicrosoft()}>
                      <IntlMessages id="connect.signUpWithMicrosoft" />
                    </Button>
                  }
                  {(!focusedPlatform || focusedPlatform === 'google') && process.env.REACT_APP_GOOGLE_VERIFICATION === 'true' &&
                    <Button
                      icon={<Icon component={GoogleLogo} />}
                      size='large'
                      data-qa="google-connect"
                      type="primary"
                      block
                      onClick={() => connectWithGoogle()}
                      className="google-btn vt-auth"
                    >
                      <IntlMessages id="connect.signInWithGoogleVerification" />
                    </Button>
                  }
                  {
                    (!focusedPlatform || focusedPlatform === 'google') && process.env.REACT_APP_GOOGLE_VERIFICATION !== 'true' &&
                    <Button icon={<GoogleOutlined />} size='large' data-qa="google-connect" className="vt-auth" type="primary" block onClick={() => connectWithGoogle()}>
                      <IntlMessages id="connect.signUpWithGoogle" />
                    </Button>
                  }
                  {
                    (!focusedPlatform) &&
                    <Button icon={<MailOutlined />} size='large' type="primary" className="vt-auth" data-qa="email-connect" block onClick={() => connectWithEmail()}>
                      <IntlMessages id="connect.signUpWithEmail" />
                    </Button>
                  }
                </div>
              }
              {(focusedPlatform && focusedPlatform === 'email') &&
                <Paragraph>
                  <Title style={{ textAlign: 'center', fontWeight: 400 }} level={5}><IntlMessages id="connect.signUp" /></Title>
                  <Form
                    name="connect-with-email"
                    form={form}
                    layout="horizontal"
                    onFinish={connectWithMail}
                  >
                    <Form.Item
                      name="email"
                      style={{ width: '100%' }}
                      rules={[{ required: true, type: 'email', message: (<IntlMessages id="connect.pleaseInputValidEmail" />) }]}
                    >
                      <Input className="email-input data-hj-allow" data-qa='email-signup-mail'  size="large" placeholder={formatMessage({ id: 'connect.inputEmailPlaceholder' })} />
                    </Form.Item>
                    <Form.Item help={<IntlMessages id='createCompany.steps.selectPlan.noCC' />}>
                      <Button
                        size="large"
                        type="primary"
                        block
                        htmlType="submit"
                        data-qa='email-signup-go-to-wizard'
                      >
                        <IntlMessages id="app.next" />
                      </Button>
                    </Form.Item>
                  </Form>
                </Paragraph>
              }
              <Paragraph style={{ textAlign: 'center', marginTop: 30 }}>
                <IntlMessages id="connect.alreadyHaveAnAccount" />&nbsp;
                <Link onClick={() => goToLogin()}>
                  <IntlMessages id="app.login" />
                </Link>
              </Paragraph>
            </Spin>
          </Col>
          { !focusedPlatform &&
            <Col xs={{ span: 20 }} lg={{ span: 12 }}>
              {/* <div className="auth-sidebar" style={{ display: 'flex', justifyContent: 'center', borderStyle: 'none' }}> */}
              <div className="auth-sidebar-capterra">
                <Paragraph >&quot;{testimonial?.text}&quot;</Paragraph>
                <Paragraph strong>{testimonial?.creator}</Paragraph>
                <div>
                  <img
                    alt="Rating image"
                    className="rating-logo"
                    src={capterraRating}
                  />
                </div>
              </div>
              {/* </div> */}
            </Col>
          }
        </Row>
      </div>

      {companyExists && contactAdminModal &&
        <CompanyExistsModal
          companyExists={companyExists}
          setCompanyExists={setCompanyExists}
          contactAdminModal={contactAdminModal}
          userEmail={userEmail}
          userWorkspaceName={userWorkspaceName}
        />
      }

      {showSlackLoginRequiredModal &&
        <Modal
          title={formatMessage({ id: 'error.slack.usersLoginRequiredTitle' })}
          visible={showSlackLoginRequiredModal}
          onOk={getSlackTokens}
          cancelText={formatMessage({ id: 'app.cancel'})}
          okText={formatMessage({ id: 'connect.slack.usersLoginRequiredButton'})}
          onCancel={() => {
            dispatch(setUserId(localStorage.getItem('userId') as string))
            setShowSlackLoginRequiredModal(false)
          }}
        >
          <p><IntlMessages id="connect.slack.usersLoginRequiredDescription1" /></p>
        </Modal>
      }

      {companyAlreadySignedUpShowModal &&
        <CompanyAlreadySignedUpModal
          showModal={companyAlreadySignedUpShowModal}
          existsOnAnotherPlatform={existsOnAnotherPlatform}
          connectWithPlatform={connectWithPlatform}
          handleCloseCompanyAlreadySignedUpModal={() => {
            setCompanyAlreadySignedUpShowModal(false)
            setSigninInProgress(false)
          }}
        />
      }

      {otherPlatformLogin && selectedPlatform &&
        <OtherPlatformLoginModal
          otherPlatformLogin={otherPlatformLogin}
          connectWithPlatform={connectWithPlatform}
          closeOtherPlatformLoginModal={closeOtherPlatformLoginModal}
          existingOrganizationName={existingOrganizationName}
          existsOnAnotherPlatform={existsOnAnotherPlatform}
          selectedPlatform={selectedPlatform}
        />
      }

      {signupWithAnotherPlatformShowModal && domainPlatform &&
        <SignupWithAnotherPlatformModal
          showModal={signupWithAnotherPlatformShowModal}
          domainPlatform={domainPlatform}
          connectWithPlatform={connectWithPlatform}
          handleSignupWithMail={handleSignupWithMail}
          handleCloseSignupWithAnotherPlatformModal={() => setSignupWithAnotherPlatformShowModal(false)}
        />
      }
    </div>
  )
}

export default SignupA