import React, { useEffect, useState, useContext } from 'react'
import { useIntl } from 'react-intl'
import { Link, useHistory } from 'react-router-dom'

import { API, graphqlOperation } from 'aws-amplify'
import moment from 'moment'

import { Breadcrumb, Form, Button, Typography, Tooltip, Radio, notification } from 'antd'
import { LoadingOutlined, WarningOutlined, InfoCircleOutlined } from '@ant-design/icons'

import * as logger from '../../../services/logger'
import { useAppSelector } from '../../../store/hooks'
import { selectAuthCompanySlice } from '../../../store/auth-company-slice'
import { useShouldEnableFeatures } from '../../../store/use-should-enable-features'
import { notificationStore } from '../../../context/notificationsContext/store'

import LeavePolicyForm from '../../../components/leave-policy-form'
import { ILeavePolicyBody } from '../../../components/location-leave-policies/types'

import IntlMessages from '../../../util/IntlMessages'

import { getLocationByIdLeaveTypesAllLeavePolicies, getUsersWithCustomLeaveQuota } from '../../../graphql/custom-queries'

import { IGetLocationGeneral, IGetLocationLeaveTypesAllLeavePoliciesData, ILocationGeneralLeavePolicies } from '../../../types/locations'
import { ILeavePolicyForm, RolloverStatus } from '../../../types/leave-types'
import { ILocation } from '@vacationtracker/shared/types/location'
import { getShortestLeaveInterval } from '@vacationtracker/shared/functions/get-shortest-leave-interval'
import { getDaysOrHours, setDaysOrHours } from '@vacationtracker/shared/functions/get-days-or-hours'
import { AccrualTypeEnum, BroughtForwardExpirationSettings, ShortestLeaveIntervalEnum } from '@vacationtracker/shared/types/leave-policy'
import { SubscriptionPlanEnum } from '@vacationtracker/shared/types/company'
import { FeatureFlagEnum } from '@vacationtracker/shared/types/feature-flags'
import { IGetUsersWithCustomQuota, IUserWithCustomQuota } from '../../../types/custom-queries'
import { IData } from '../../../types/data'
import isNull from 'lodash/isNull'
import dayjs from 'dayjs'
import { getUserWorkingHoursPerDay } from '@vacationtracker/shared/functions/work-week'

interface ILocationLeavePolicyPage {
  match: {
    params: {
      locationId: string
      leaveTypeId: string
    }
  }
}
type OverrideChangeType = 'hasUnlimitedDays' | 'daysPerYear'
const { Paragraph } = Typography

const LeavePoliciesPage = ({ match }: ILocationLeavePolicyPage): React.ReactElement => {

  const { formatMessage } = useIntl()
  const history = useHistory()
  const [form] = Form.useForm()
  const [loading, setLoading] = useState<boolean>(false)
  const [showOverride, setShowOverride] = useState<boolean>(false)
  const [overrideChangeType, setOverrideChangeType] = useState<OverrideChangeType>('hasUnlimitedDays')
  const [cancelUserSettings, setCancelUserSettings] = useState<boolean>(false)
  const [location, setLocation] = useState<IGetLocationGeneral>()
  const [leavePolicy, setLeavePolicy] = useState<ILocationGeneralLeavePolicies>()
  const [usersWithCustomLeaveQuotas, setUsersWithCustomLeaveQuotas] = useState<IUserWithCustomQuota[]>([])
  const [defaultValue, setDefaultValue] = useState<any>()
  const { authCompany } = useAppSelector(selectAuthCompanySlice)
  const hourlyLeaveAccounting = Boolean(authCompany?.hourlyLeaveAccounting)
  const { actionNotifications, setActionNotifications } = useContext(notificationStore)
  const shouldEnableHourIntervals = useShouldEnableFeatures(SubscriptionPlanEnum.complete, FeatureFlagEnum.shortestLeaveInterval)
  const shouldEnableAccruals = useShouldEnableFeatures(SubscriptionPlanEnum.complete, FeatureFlagEnum.accruals)

  const tailLayout = {
    wrapperCol: {xs: { offset: 0, span: 12 }, md: { offset: 8, span: 12}},
  }

  const formLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  }

  const returnRolloverType = (numberOfRolloverDays) => {
    if (typeof numberOfRolloverDays === 'undefined') {
      return RolloverStatus.no
    }

    if (numberOfRolloverDays >= 365) {
      return RolloverStatus.all
    }

    if (numberOfRolloverDays > 0) {
      return RolloverStatus.limited
    }

    return RolloverStatus.no
  }
  
  useEffect(() => {
    fetchLocationAndSetDefaultValues(match.params.locationId)
    fetchUsersWithCustomLeaveQuota(match.params.locationId, match.params.leaveTypeId)
  }, [match.params.locationId])

  const fetchLocationAndSetDefaultValues = async (id: string) => {
    try {
      const response = await API.graphql(graphqlOperation(getLocationByIdLeaveTypesAllLeavePolicies, { id })) as IGetLocationLeaveTypesAllLeavePoliciesData
      const location = response.data.getLocation
      const leavePolicy = response.data.getLocation.leavePolicies.find(
        leavePolicy => `${match.params.locationId}#${match.params.leaveTypeId}` === leavePolicy.id
      ) as unknown as ILocationGeneralLeavePolicies
      const broughtForwardExpirationSettings = getBroughtForwardExpirationSettings(leavePolicy, location)
      setLeavePolicy({
        ...leavePolicy,
        ...broughtForwardExpirationSettings,
      })     
      setLocation(location)
      setDefaultValue({
        id: leavePolicy?.id || null,
        leaveTypeId: leavePolicy?.leaveType?.id || null,
        accrualType: leavePolicy?.accrualType || 'NONE',
        cancelUserSettings: false,
        shortestLeaveInterval: getShortestLeaveInterval(leavePolicy?.shortestLeaveInterval, leavePolicy?.allowHalfDays),
        daysPerYear: getDaysOrHours(leavePolicy?.daysPerYear ?? 0, hourlyLeaveAccounting, getUserWorkingHoursPerDay(location.workHours)),
        hasUnlimitedDays: leavePolicy?.hasUnlimitedDays || false,
        hideLeaveType: leavePolicy?.hideLeaveType || false,
        isApprovalRequired: typeof leavePolicy?.isApprovalRequired !== 'undefined' ? leavePolicy?.isApprovalRequired : true,
        isReasonRequired: leavePolicy?.isReasonRequired || false,
        maxRolloverDays: getDaysOrHours(leavePolicy?.maxRolloverDays ?? 0, hourlyLeaveAccounting, getUserWorkingHoursPerDay(location.workHours)),
        negativeBallanceAllowed: typeof leavePolicy?.negativeBallanceAllowed !== 'undefined' ? leavePolicy?.negativeBallanceAllowed : false,
        rolloverType: returnRolloverType(leavePolicy?.maxRolloverDays),
        firstEarningDate: moment(leavePolicy?.firstEarningDate),
        accrualPeriod: leavePolicy?.accrualPeriodStart ? // for bi-weekly and range picker should be array
          [moment(leavePolicy?.accrualPeriodStart), moment(leavePolicy?.accrualPeriodStart).add(13, 'days')] :
          [moment(), moment().add(13, 'days')],
        allowLeaveInPast: leavePolicy?.allowLeaveInPast || false,
        ...broughtForwardExpirationSettings,
        allowAdvanceAccrualUsage: leavePolicy?.allowAdvanceAccrualUsage || false,
        pauseAccrualsWhenUserIsDeactivated: isNull(leavePolicy?.pauseAccrualsWhenUserIsDeactivated) || leavePolicy?.pauseAccrualsWhenUserIsDeactivated,
      })
    } catch (err) { logger.error('error fetching location', err) }
  }

  const getBroughtForwardExpirationSettings = (leavePolicy, location) => {
    if(typeof leavePolicy?.broughtForwardExpirationSettings?.enabled === 'boolean') {
      return {
        enableRolloverExpiry: leavePolicy?.broughtForwardExpirationSettings.enabled,
        rolloverExpiryAfterDays: leavePolicy?.broughtForwardExpirationSettings.afterDays,
        rolloverExpiryDay: leavePolicy?.broughtForwardExpirationSettings.day,
        rolloverExpiryMonth: leavePolicy?.broughtForwardExpirationSettings.month,
      }
    } else if (leavePolicy?.enableRolloverExpiry) {
      return {
        enableRolloverExpiry: leavePolicy?.enableRolloverExpiry,
        rolloverExpiryDay: leavePolicy?.rolloverExpiryDay,
        rolloverExpiryMonth: leavePolicy?.rolloverExpiryMonth,
        rolloverExpiryAfterDays: leavePolicy?.rolloverExpiryAfterDays,
      }
    } else if (!location?.rolloverNeverExpireDays) {
      return {
        enableRolloverExpiry: !location?.rolloverNeverExpireDays,
        rolloverExpiryDay: location?.rolloverExpiryDay,
        rolloverExpiryMonth: location?.rolloverExpiryMonth,
        rolloverExpiryAfterDays: location?.rolloverExpiryAfterDays,
      }
    }
  }

  const fetchUsersWithCustomLeaveQuota = async (locationId: string, leaveTypeId: string) => {
    const resultUsersWithCustomLeaveQuota = await API.graphql(graphqlOperation(getUsersWithCustomLeaveQuota, {
      leaveTypeId,
      locationId,
    })) as IData<IGetUsersWithCustomQuota>
    setUsersWithCustomLeaveQuotas(resultUsersWithCustomLeaveQuota.data.getUsersWithCustomQuota.users)
  }

  const onFormChange = (change, all) => {
    if (change.hasUnlimitedDays === undefined && change.daysPerYear === undefined) {
      return
    }
    setOverrideChangeType(Object.keys(change)[0] as OverrideChangeType)
    const showOverrideCondition = 
      leavePolicy &&
      usersWithCustomLeaveQuotas.length > 0 &&
      (leavePolicy.daysPerYear !== all.daysPerYear || leavePolicy.hasUnlimitedDays !== all.hasUnlimitedDays)
    setShowOverride(Boolean(showOverrideCondition))
  }

  const onFinish = async () => {
    try {
      const values = await form.validateFields()
      if (values.rolloverType === 'all') {
        values.maxRolloverDays = hourlyLeaveAccounting ? 2928 : 366
      } else if (values.rolloverType === 'no') {
        values.maxRolloverDays = 0
      } else {
        values.maxRolloverDays = values.maxRolloverDays || 0
      }
      delete values.rolloverType

      if (values.accrualType && values.accrualType !== AccrualTypeEnum.none) {
        values.firstEarningDate = values.firstEarningDate.format('YYYY-MM-DD')
        values.accrualPeriodStart = values.accrualPeriod[0].format('YYYY-MM-DD')
      }
      const formValuesData = values as ILeavePolicyForm
      if (typeof values.enableRolloverExpiry !== 'boolean') {
        values.enableRolloverExpiry = false
      }

      saveLeavePolicy(formValuesData)
    } catch (error) {
      logger.error(error)
    }
  }

  const saveLeavePolicy = async (leavePolicy) => {
    try {
      setLoading(true)
      const response = await enableLeavePolicy(leavePolicy)
      notification.open({
        key: response.correlationId,
        message: formatMessage({ id: 'components.locationLeavePolicy.updateInProgress' }),
        icon: (<LoadingOutlined />),
        duration: 0,
      })
      setActionNotifications([ ...actionNotifications, response.correlationId ])
      goToLocationPoliciesPage()
    } catch (error) {
      logger.error(error)
      handleErrorNotification(error)
    }
  }

  const enableLeavePolicy = async (leavePolicy) => {
    let shortestLeaveInterval = getShortestLeaveInterval(leavePolicy?.shortestLeaveInterval as ShortestLeaveIntervalEnum, leavePolicy?.allowHalfDays as boolean)
    if (shortestLeaveInterval === ShortestLeaveIntervalEnum.oneHour && !shouldEnableHourIntervals) {
      shortestLeaveInterval = ShortestLeaveIntervalEnum.halfDay
    }
    let accrualType = leavePolicy.accrualType
    if (leavePolicy.accrualType !== AccrualTypeEnum.none && !shouldEnableAccruals) {
      accrualType = AccrualTypeEnum.none
    }

    const broughtForwardExpirationSettings: BroughtForwardExpirationSettings = {
      enabled: leavePolicy.enableRolloverExpiry,
    }
    if (broughtForwardExpirationSettings.enabled && typeof leavePolicy.rolloverExpiryAfterDays === 'number') {
      broughtForwardExpirationSettings.afterDays = leavePolicy.rolloverExpiryAfterDays
    }
    if (broughtForwardExpirationSettings.enabled && typeof leavePolicy.rolloverExpiryDay === 'number' && typeof leavePolicy.rolloverExpiryMonth === 'number') {
      broughtForwardExpirationSettings.day = leavePolicy.rolloverExpiryDay
      broughtForwardExpirationSettings.month = leavePolicy.rolloverExpiryMonth
    }

    const body: ILeavePolicyBody = {
      toil: leavePolicy.toil,
      eventType: 'LEAVE_POLICY_ENABLED',
      eventGroup: 'LEAVE_POLICY',
      leaveTypeId: leavePolicy?.leaveType?.id || leavePolicy?.leaveTypeId,
      negativeBallanceAllowed: leavePolicy.negativeBallanceAllowed,
      locationId: location?.id as string,
      daysPerYear: leavePolicy.daysPerYear,
      maxRolloverDays: leavePolicy.maxRolloverDays,
      hasUnlimitedDays: leavePolicy.hasUnlimitedDays,
      shortestLeaveInterval,
      hideLeaveType: leavePolicy.hideLeaveType,
      accrualType: accrualType,
      accrualCapRate: leavePolicy.accrualCapRate,
      isApprovalRequired: leavePolicy.isApprovalRequired,
      isReasonRequired: leavePolicy.isReasonRequired,
      cancelUserSettings,
      allowLeaveInPast: Boolean(leavePolicy.allowLeaveInPast),
      broughtForwardExpirationSettings,
      allowAdvanceAccrualUsage: Boolean(leavePolicy.allowAdvanceAccrualUsage),
      pauseAccrualsWhenUserIsDeactivated: isNull(leavePolicy?.pauseAccrualsWhenUserIsDeactivated) || leavePolicy?.pauseAccrualsWhenUserIsDeactivated,
      version: 3,
    }
    if (leavePolicy.firstEarningDate) {
      body.firstEarningDate = leavePolicy.firstEarningDate
    }
    if (leavePolicy.accrualPeriodStart) {
      body.accrualPeriodStart = leavePolicy.accrualPeriodStart
    }
    if (typeof leavePolicy.applyBroughtForwardSettingsToThePastYear === 'boolean') {
      body.applyBroughtForwardSettingsToThePastYear = leavePolicy.applyBroughtForwardSettingsToThePastYear
    }
    if (leavePolicy.toil) {
      body.toilShortestInterval = leavePolicy.toilShortestInterval
      body.toilRequestsAllowedForUsers = leavePolicy.toilRequestsAllowedForUsers
      body.toilExpiration = leavePolicy.toilExpiration
      body.toilExpirationMonths = 0
      body.toilExpirationDate = undefined
      if (leavePolicy.toilExpiration) {
        leavePolicy.toilExpirationMonths === 'calendar-year'
          ? body.toilExpirationDate = dayjs().endOf('year').format('YYYY-MM-DD')
          : body.toilExpirationMonths = leavePolicy.toilExpirationMonths
      }
    }

    body.daysPerYear = setDaysOrHours(body?.daysPerYear, hourlyLeaveAccounting, getUserWorkingHoursPerDay(location?.workHours))
    body.maxRolloverDays = setDaysOrHours(body?.maxRolloverDays, hourlyLeaveAccounting, getUserWorkingHoursPerDay(location?.workHours))
    body.leaveTypeId = match.params.leaveTypeId
    
    return await API.post('CoreEvent', '/core/event', { body })
  }

  const handleErrorNotification = (error, correlationId?: string, title?: string): void => {
    const description = correlationId ?
      formatMessage({ id: 'error.notificationGeneral' }, { correlationId }) :
      error.response?.data?.message ? error.response?.data?.message : error.message ? error.message : JSON.stringify(error)
    notification.error({
      message: title ? title : formatMessage({ id: 'error.notificationGeneral' }),
      description,
      duration: 0,
    })
  }

  const goToLocationPoliciesPage = (): void => {
    history.push(`/app/settings/locations/${location?.id}/leave-policies`)
  }

  return (
    <div className='main-content'>
      <div className="main-content-header">
        <div className="main-content-header-title">
          <span><IntlMessages id="components.leavePolicyForm.editTitle" values={{ leavePolicyName: leavePolicy?.leaveType?.name, locationName: location?.name}} /></span>
        </div>
        <div className="main-content-header-breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/app/dashboard"><IntlMessages id="sidebar.dashboard" /></Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item><IntlMessages id="sidebar.settings" /></Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/app/settings/locations"><IntlMessages id="app.locations" /></Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/app/settings/locations/${location?.id}/leave-policies`}>{location?.name}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={'/app/settings/leave-types'}>{leavePolicy?.leaveType.name}</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <div className="main-content-body leave-policies-page">
        {defaultValue && <Form
          {...formLayout}
          form={form}
          name="LeavePolicyForm"
          onFinish={onFinish}
          initialValues={defaultValue}
          onValuesChange={onFormChange}
        >
          <LeavePolicyForm
            leavePolicy={leavePolicy}
            location={location as unknown as ILocation}
            form={form}
            showAccruals={true}
            yearStartDay={location?.yearStartDay}
            hourlyLeaveAccounting={hourlyLeaveAccounting}
          />
          {showOverride && (
            <div id="override-notice">
              <Paragraph>
                <IntlMessages
                  id="components.leavePolicyForm.override.change"
                  values={{
                    unlimited: (...chunks) => overrideChangeType === 'hasUnlimitedDays' ? <strong>{chunks}</strong> : '',
                    customDays: (...chunks) => overrideChangeType === 'daysPerYear' ? <strong>{chunks}</strong> : '',
                    strong: (...chunks) => <strong>{chunks}</strong>,
                  }} />
              </Paragraph>
              <Paragraph>
                <IntlMessages
                  id="components.leavePolicyForm.override.affectedUsers"
                  values={{
                    totalUsers: usersWithCustomLeaveQuotas.length,
                    strong: (...chunks) => <strong>{chunks}</strong>,
                  }} />
                <Tooltip className='info-tooltip' title={usersWithCustomLeaveQuotas.map(s => s.name).join(', ')}><InfoCircleOutlined /></Tooltip>
              </Paragraph>
              <Paragraph>
                <WarningOutlined style={{color: '#faad14', fontSize: 'large', marginRight: 5 }} />
                <IntlMessages
                  id="components.leavePolicyForm.override.customLeaveQuotaInfo"
                  values={{
                    totalUsers: usersWithCustomLeaveQuotas.length,
                    strong: (...chunks) => <strong>{chunks}</strong>,
                  }} />
              </Paragraph>
              <Form.Item name="cancelUserSettings">
                <Radio.Group value={cancelUserSettings} onChange={() => setCancelUserSettings(!cancelUserSettings)}>
                  <Radio value={false}><IntlMessages id="components.leavePolicyForm.override.overrideEnable" /></Radio>
                  <Radio value={true} style={{ whiteSpace: 'normal', display: 'inline-flex', width: '100%' }}>
                    <IntlMessages id="components.leavePolicyForm.override.overrideDisable" />
                  </Radio>
                </Radio.Group>
              </Form.Item>
              <Paragraph>
                <IntlMessages id="components.leavePolicyForm.override.note" />
              </Paragraph>
            </div>
          )}
          <br />
          <Form.Item {...tailLayout}>
            <Button type="default" style={{ marginRight: 10 }} onClick={goToLocationPoliciesPage}>
              <IntlMessages id="app.cancel" />
            </Button>
            <Button disabled={loading} type="primary" loading={loading} htmlType="submit" data-qa='submit-notification'>
              <IntlMessages id="app.update" />
            </Button>
          </Form.Item>
        </Form>}
      </div>
    </div>
  )
}

export default LeavePoliciesPage
