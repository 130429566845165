export enum FeatureFlagEnum {
  qaMode = 'qa-mode',
  shortestLeaveInterval = 'shortest-leave-interval',
  accruals = 'accruals',
  toil = 'toil',
  labels = 'labels',
  scheduledReports = 'scheduled-reports',
  hours = 'hours',
  calculationStartYear = 'calculation-start-year',
  companyLogs = 'company-logs',
  importUsers = 'import-users',
  aiBotHelper = 'ai-bot-helper',
  noLimitTeams = 'no-limit-teams',
  noLimitLocation = 'no-limit-location',
  // Start Automation:
  automation = 'automation', // This is the feature flag for all automations
  addon = 'addon', // This is the feature flag for all addons
  blackOutPeriod = 'blackout-period',
  entitlementByRole = 'entitlement-by-role',
  seniorityEntitlement = 'seniority-entitlement',
  probationPeriod = 'probation-period',
  // End Automation
  // Start Addons
  visibility = 'visibility',
  // End Addons
  syncSharedCalendars = 'sync-shared-calendars',
  substituteApprovers = 'substitute-approvers',
  aiAssistedOnboarding = 'ai-assisted-onboarding',
  googleAdminPermissions = 'google-admin-permissions',
  workday = 'workday',
}
