import React, { useState } from 'react'
import { Modal, Form } from 'antd'
import IntlMessages from '@vacationtracker/shared/components/utils/IntlMessages'
import MFACodeInput from '../mfa-code'
import * as logger from '../../services/logger'

interface EnableMFAModalProps {
  onSave: (code: string) => void
}

const MFACodeModal = ({ onSave }: EnableMFAModalProps): React.ReactElement => {

  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState(false)

  const onFinish = () => {
    form
      .validateFields()
      .then(values => {
        setIsLoading(true)
        onSave(values.code as string)
      })
      .catch(info => {
        logger.error('Validate Failed:', info)
      })
  }

  return (
    <Modal
      closable={false}
      visible={true}
      onOk={onFinish}
      cancelButtonProps={{ style: { display: 'none' } }}
      confirmLoading={isLoading}
      title={<IntlMessages id="app.mfaEnterCode" />}
      okText={<IntlMessages id="components.modal.verify" />}
    >
      <Form
        key="MFACodeForm"
        form={form}
        name="MFACodeForm"
      >
        <div style={{ marginBottom: '15px' }}><IntlMessages id="app.mfaCodePlaceholder"/></div>
        <div style={{ maxWidth: '220px' }}>
          <MFACodeInput handleEnter={onFinish} />
        </div>
      </Form>
    </Modal>
  )
}

export default MFACodeModal