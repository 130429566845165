import React, { useEffect, useState, useContext } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Form, Select, Breadcrumb, Button, TimePicker, Input, notification as antdNotification, Checkbox, Radio, Alert } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { useIntl } from 'react-intl'
import moment from 'moment'
import isEmpty from 'lodash/isEmpty'
import flatMap from 'lodash/flatMap'


import { API, graphqlOperation } from 'aws-amplify'
import { getLocationsApproverTeamsAndLabels, getLocationsTeamsAndLabelsShort, getNotificationById, getUsersForNotifictions } from '../../../graphql/custom-queries'

import * as logger from '../../../services/logger'
import { notificationStore } from '../../../context/notificationsContext/store'
import { useShouldEnableFeatures } from '../../../store/use-should-enable-features'
import { useAppSelector } from '../../../store/hooks'
import { selectAuthUserSlice } from '../../../store/auth-user-slice'
import { selectAuthCompanySlice } from '../../../store/auth-company-slice'
import { WorkWeek } from '../../../util/WorkWeek'
import { filterOptions } from '../../../util/filter-options'
import timezone from '@vacationtracker/shared/data/timezone'

import CircularProgress from '../../../components/circular-progress'
import NotificationDestinations from '../../../components/notification-destination'
import NotificationSummaryInfo from '../../../components/notification-summary-info'
import IntlMessages from '@vacationtracker/shared/components/utils/IntlMessages'
import FilterAdvanced from '@vacationtracker/shared/components/filter-advanced'
import { availableLanguages } from '@vacationtracker/shared/i18n'
import { dayOfWeekMap } from '../../../constants'

import { INotificationForm, INotificationsFormPage, IDestination, IDestinationForm, IGetNotificationById } from '../../../types/notifications'
import { IGetLocationsApproverTeamsAndLabelsShort, IGetLocationsTeamsAndLabelsShort, IGetUsersForNotifications } from '../../../types/custom-queries'
import { IGetLabelsShort } from '../../../types/labels'
import { IFilter } from '@vacationtracker/shared/types/filter'
import { ITeamShort } from '@vacationtracker/shared/types/team'
import { ILocationShort } from '@vacationtracker/shared/types/location'
import { ISelected } from '@vacationtracker/shared/components/filter-advanced/types'
import { NotificationPlatform } from '@vacationtracker/shared/types/notification-v2'
import { IData } from '../../../types/data'
import { LocaleEnum } from '@vacationtracker/shared/types/i18n'
import { SubscriptionPlanEnum } from '@vacationtracker/shared/types/company'
import { FeatureFlagEnum } from '@vacationtracker/shared/types/feature-flags'
import { IGetTeamsShort } from '../../../types/teams'
import { IGetLocationsShort } from '../../../types/locations'
import { HourFormatEnum } from '@vacationtracker/shared/types/user'


export interface ISelectedDestination {
  type: string
  values: string | string[]
}

const { Option } = Select

const defaultNotificationState = {
  id: '',
  name: '',
  firstDayOfWeek: 1,
  frequency: 'DAILY',
  day: undefined,
  hour: 10,
  minute: Number('00'),
  timezone: '',
  enabled: true,
  locations: [],
  teams: [],
  labels: [],
  groupBy: 'TEAM',
  sendEmpty: false,
  locale: LocaleEnum.en,
  sendOnDays: [1, 2, 3, 4, 5],
  owner: {
    id: '',
    name: '',
  },
}

const NotificationsFormPage = ({ match }: INotificationsFormPage): React.ReactElement => {
  const [form] = Form.useForm()

  const { authCompany } = useAppSelector(selectAuthCompanySlice)
  const { authUser: { id, role, locale, hourFormat } } = useAppSelector(selectAuthUserSlice)
  const history = useHistory()
  const { formatMessage } = useIntl()
  const { actionNotifications, setActionNotifications } = useContext(notificationStore)

  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingNotification, setIsLoadingNotification] = useState(true)
  const [isApiInProgress, setIsApiInProgress] = useState(false)
  const [teams, setTeams] = useState<ITeamShort[]>([])
  const [locations, setLocations] = useState<ILocationShort[]>([])
  const [labels, setLabels] = useState<IGetLabelsShort[]>([])
  const [initialFilterValues, setInitialFilterValues] = useState([{}])
  const [initialDestination, setInitialDestination] = useState<ISelectedDestination[] | [{}]>([{}])
  const [filter, setFilter] = useState<IFilter>({
    locationIds: [],
    teamIds: [],
    labelIds: [],
  })
  const [notification, setNotification] = useState<INotificationForm>(defaultNotificationState as INotificationForm)
  const [notificationFrequency, setNotificationFrequency] = useState<'DAILY' | 'WEEKLY' | undefined>('DAILY')
  const [totalUsers, setTotalUsers] = useState<number>(0)
  const [channelName, setChannelName] = useState('')
  const [shouldResetFilters, setShouldResetFilters] = useState(false)
  const [shouldResetNotificationDestination, setShouldResetNotificationDestination] = useState(false)
  const isUpadateNotification = match.params.id

  const shouldEnableLabels = useShouldEnableFeatures(SubscriptionPlanEnum.complete, FeatureFlagEnum.labels)
  const amIAdmin = role.toLowerCase() === 'admin'
  // TODO: if there are no user show meaningful message
  const amIApprover = role.toLowerCase() === 'approver'

  useEffect(() => {
    if (isUpadateNotification && isLoadingNotification) {
      fetchNotification(match.params.id)
    } else {
      const notificationForm = localStorage.getItem('notificationForm')
      if (notificationForm) {
        const notificationFormObject = JSON.parse(notificationForm)
        setNotificationFrequency(notificationFormObject.frequency)
        form.setFieldsValue(notificationFormObject)
        handleSetInitialDestinationFromStorage(notificationFormObject)
      } else {
        localStorage.setItem('notificationForm', JSON.stringify({
          ...defaultNotificationState,
          locale,
          timestamp: Date.now(),
        }))
        setInitialDestination([{}])
      }

      setIsLoadingNotification(false)
    }
  }, [match.params.id])

  useEffect(() => {
    if (isLoading) {
      fetchData()
    }
  }, [isLoading])

  const handleResetForm = () => {
    const defaultNotification = {
      ...defaultNotificationState,
      locale,
      timestamp: Date.now(),
    }
    localStorage.setItem('notificationForm', JSON.stringify(defaultNotification))
    form.resetFields()
    setInitialDestination([{}])
    setFilter({
      locationIds: [],
      teamIds: [],
      labelIds: [],
    })
    setInitialFilterValues([{}])
    setShouldResetFilters(true)
    setShouldResetNotificationDestination(true)
  }

  const handleSetInitialDestinationFromStorage = notificationFormObject => {
    setInitialDestination(() => {
      const destinations: ISelectedDestination[] | [{}] = []
      for (const [key] of Object.entries(notificationFormObject)) {
        if(key.includes('destination-') && !isEmpty(notificationFormObject[key])) {
          destinations.push({
            type: notificationFormObject[key].type,
            values: notificationFormObject[key].addresses,
          })
        }
      }
      return destinations.length > 0 ? destinations : [{}]
    })
  }

  const getTotalUsers = async (locationIds: string[] = [], teamIds: string[] = [], labelIds: string[] = []) => {
    const usersResponse = await API.graphql(graphqlOperation(getUsersForNotifictions, { locationIds, teamIds, labelIds })) as IData<IGetUsersForNotifications>
    setTotalUsers(usersResponse.data.getUsersFiltered.totalUsers)
  }

  const fetchData = async () => {
    try {
      let response
      if (amIAdmin) {
        response = await API.graphql(graphqlOperation(getLocationsTeamsAndLabelsShort)) as IGetLocationsTeamsAndLabelsShort
        setTeams(response.data.getTeamListV2 as IGetTeamsShort[])
      }
      if (amIApprover) {
        response = await API.graphql(graphqlOperation(getLocationsApproverTeamsAndLabels, { id } )) as IGetLocationsApproverTeamsAndLabelsShort
        setTeams(response.data.getUser.approverToTeams as ITeamShort[])
      }
      if (!isUpadateNotification) {
        getTotalUsers()
      }
      setLocations(response.data.getLocationList as IGetLocationsShort[])
      setLabels(response.data.getLabels as IGetLabelsShort[])
      setIsLoading(false)
    } catch (err) {
      logger.error('ERROR FETCH DATA', err)
    }
  }

  const fetchNotification = async (id) => {
    try {
      const response = await API.graphql(graphqlOperation(getNotificationById, { id })) as IData<IGetNotificationById>
      const notificationData = response.data.getNotification
      notificationData.destinations.map((destination, index) => {
        notificationData[`destination-${index}`] = {
          type: destination.platform === 'email' ? 'Email' : formatMessage({ id: 'components.selectChannelForm.channel' }),
          addresses: destination.platform === 'email' ? destination.addresses : destination.addresses[0],
          msGroup: destination.platform === 'microsoft' ? destination.msGroup : undefined,
        }
      })

      setInitialDestination(notificationData.destinations.map(destination => {
        return {
          type: destination.platform === 'email' ? 'Email' : formatMessage({ id: 'components.selectChannelForm.channel' }),
          values: destination.platform === 'email' ? destination.addresses : destination.addresses[0],
        }
      }))

      await getTotalUsers(notificationData.locations, notificationData.teams, notificationData.labels)

      setInitialFilterValues(() => {
        const data: ISelected[] = []

        if (notificationData) {
          if (notificationData.locations.length > 0) {
            data.push({ type: 'Locations', values: notificationData.locations })
          }
          if (notificationData.teams.length > 0) {
            data.push({ type: 'Departments', values: notificationData.teams })
          }
          if (notificationData?.labels && notificationData.labels.length > 0) {
            data.push({ type: 'Labels', values: notificationData.labels})
          }
        }
        setFilter({
          labelIds: notificationData.labels,
          locationIds: notificationData.locations,
          teamIds: notificationData.teams,
        })

        if(data.length === 0) {
          return [{}]
        }
        return data
      })

      if(notificationData.frequency === 'WEEKLY') {
        notificationData.currentWeek = notificationData.currentWeek ? 1 : 0
      }

      setNotificationFrequency(notificationData.frequency)
      setNotification(notificationData)
      setIsLoadingNotification(false)
    } catch (err) {
      logger.error('ERROR FETCHING NOTIFICATION', err)
    }
  }

  const onFinish = async () => {
    setIsApiInProgress(true)
    let response
    try {
      const values = await form.validateFields()

      const destinations: IDestination[] = []
      for (const [key, val] of Object.entries(values)) {
        if(key.includes('destination-')) {
          const value: IDestinationForm = val as IDestinationForm
          const platform: NotificationPlatform = authCompany?.platform as NotificationPlatform
          destinations.push({
            platform: value.type === 'Email' ?  'email' : platform,
            addresses: value.type === 'Email' ? value.addresses : [value.addresses as string],
            msGroup: value.msGroup,
          })
        }
      }

      const data = {
        name: values.name,
        firstDayOfWeek: values.firstDayOfWeek,
        frequency: values.frequency,
        destinations,
        day: values.day,
        hour: notification.hour,
        minute: notification.minute,
        timezone: values.timezone,
        enabled: isUpadateNotification ? notification.enabled : true,
        locations: filter.locationIds,
        teams: filter.teamIds,
        labels: filter.labelIds,
        groupBy: values.groupBy,
        sendEmpty: values.sendEmpty,
        currentWeek: notificationFrequency === 'WEEKLY' ? Boolean(values.currentWeek) : undefined,
        sendOnDays: values.sendOnDays,
        locale: values.locale,
        version: 2,
      }

      if (notificationFrequency === 'WEEKLY') {
        data.day = values.day
        delete data.sendOnDays
      }

      response = await API.post('CoreEvent', '/core/event', {
        body: {
          eventType: isUpadateNotification ? 'NOTIFICATION_UPDATED' : 'NOTIFICATION_CREATED',
          eventGroup: 'NOTIFICATION',
          notificationId: isUpadateNotification ? match.params.id : undefined,
          ...data,
        },
      })

      antdNotification.open({
        key: response.correlationId,
        message: match.params.id ?
          formatMessage({ id: 'notificationsForm.updateInProgress'}, { notificationName: response.name }) :
          formatMessage({ id: 'notificationsForm.createInProgress' }, { notificationName: response.name }),
        icon: (<LoadingOutlined />),
        duration: 0,
      })
      setActionNotifications([
        ...actionNotifications,
        response.correlationId,
      ])

      if (!isUpadateNotification) {
        if (localStorage.getItem('notificationForm')) {
          localStorage.removeItem('notificationForm')
        }

        if (localStorage.getItem('notificationFormFilters')) {
          localStorage.removeItem('notificationFormFilters')
        }
      }

      setIsApiInProgress(false)
      history.push('/app/notifications')

      if (!match.params.id) {
        form.resetFields()
      }
    } catch (error) {
      logger.error('ERROR HANDLE SUBMIT', error)
      setIsApiInProgress(false)
      const description = response?.correlationId ?
        formatMessage({ id: 'error.saveFailed' }, { correlationId: response.correlationId }) :
        error.response?.data?.message ? error.response?.data?.message : error.message ? error.message : JSON.stringify(error)

      antdNotification.error({
        message: formatMessage({ id: 'error.saveFailed' }),
        description,
        duration: 0,
      })
    }
  }

  const onChangeTime = (time) => {
    setShouldResetFilters(false)
    setShouldResetNotificationDestination(false)
    setNotification(prevValue => ({
      ...prevValue,
      hour: Number(moment(time).format('HH')),
      minute: Number(moment(time).format('mm')),
    }))
  }

  const handleLocalStorageDestinationChanges = (newValues) => {
    if (!isUpadateNotification) {
      localStorage.setItem('notificationForm', JSON.stringify(newValues))
    }
  }

  const handleValuesChange = (changedValues) => {
    setShouldResetFilters(false)
    setShouldResetNotificationDestination(false)
    const fieldName = Object.keys(changedValues)[0]
    const currentNotification = form.getFieldsValue(true)

    if (!isUpadateNotification) {
      localStorage.setItem('notificationForm', JSON.stringify(currentNotification))
    }

    if(fieldName === 'frequency') {
      const value = changedValues[fieldName]
      setNotificationFrequency(value)
      if (value === 'DAILY') {
        form.setFieldsValue({currentWeek: undefined, day: undefined, sendEmpty: notification.id ? notification.sendEmpty : false })
      }
      if (value === 'WEEKLY') {
        form.setFieldsValue({currentWeek: notification.currentWeek || 0, day: notification.day, sendEmpty: notification.id ? notification.sendEmpty : true})
      }
    }
  }

  const handleFilter = (filterArg: IFilter) => {
    setFilter(filterArg)
    setShouldResetFilters(false)
    setShouldResetNotificationDestination(false)
    getTotalUsers(filterArg.locationIds, filterArg.teamIds, filterArg.labelIds)
  }

  const layout = {
    labelCol: { xs: { span: 24 }, sm: { span: 24 }, md: { span: 6 }, lg: { span: 7 }, xl: { span: 6 } },
    wrapperCol: { xs: { span: 24 }, sm: { span: 24 }, md: { span: 10 }, lg: { span: 17 }, xl: { span: 14 } },
  }

  const tailLayout = {
    wrapperCol: { xs: { span: 24, offset: 0 }, sm: { span: 24, offset: 0 }, md: { span: 12, offset: 6 }, lg: { span: 12, offset: 7 } },
  }

  return <div className='main-content vt-notifications-form-page'>
    <div className="main-content-header">
      <div className="main-content-header-title">
        {isUpadateNotification ?
          <IntlMessages id="notifications.update" /> :
          <IntlMessages id="notifications.add" />
        }
      </div>
      <div className="main-content-header-breadcrumb">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/app/dashboard"><IntlMessages id="sidebar.dashboard" /></Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/app/notifications"><IntlMessages id="app.notifications" /></Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            {isUpadateNotification ? notification?.name : <IntlMessages id="notifications.add" />}
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
    </div>
    <div className="main-content-body">
      {((match.params.id && isLoadingNotification) || isLoading) ?
        <CircularProgress /> :
        <>
          {notification.error &&
            <Alert
              message={<IntlMessages id={notification.error} />}
              type="error"
              showIcon
              style={{ marginBottom: '20px' }}
            />
          }
          <Form
            layout="horizontal"
            form={form}
            name="notificationsForm"
            initialValues={notification}
            className="team-from"
            onFinish={onFinish}
            scrollToFirstError
            {...layout}
            onValuesChange={handleValuesChange}
          >
            <Form.Item
              className='wrap-label'
              label={<IntlMessages id="notificationsForm.name" />}
              name="name"
              rules={[{ required: true, message: <IntlMessages id="form.inputRequired" /> }]}
            >
              <Input
                style={{ maxWidth: 300 }}
                data-qa='notification-name'
              />
            </Form.Item>

            <FilterAdvanced
              isForm={true}
              data={{
                Locations: locations,
                Departments: teams,
                Labels: labels,
              }}
              onChangeFilter={handleFilter}
              showLabels={shouldEnableLabels}
              toolTipInfo="notificationsForm.advancedFilter.tooltipInfo"
              initialValues={initialFilterValues}
              filterByWidth={160}
              page={isUpadateNotification ? undefined : 'notificationForm'}
              shouldReset={shouldResetFilters}
            />

            <Form.Item
              name='frequency'
              className='wrap-label'
              label={<IntlMessages id="notifications.frequency" />}
              rules={[{ required: true, message: <IntlMessages id="form.inputRequired" /> }]}
            >
              <Select style={{ width: 160 }}>{['DAILY', 'WEEKLY'].map(f => <Option key={f} value={f}><IntlMessages id={`app.${f.toLowerCase()}`} /></Option>)}</Select>
            </Form.Item>

            {notificationFrequency === 'WEEKLY' &&
              <>
                <Form.Item
                  name="currentWeek"
                  className='wrap-label'
                  label={<IntlMessages id="notificationsForm.periodCovered" />}
                  rules={[{ required: true, message: <IntlMessages id="form.inputRequired" /> }]}
                >
                  <Select style={{ width: 160 }}>
                    <Option key="NextWeek" value={0}><IntlMessages id="notificationsForm.nextWeek" /></Option>
                    <Option key="CurrentWeek" value={1}><IntlMessages id="notificationsForm.currentWeek" /></Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  name="firstDayOfWeek"
                  className='wrap-label'
                  label={<IntlMessages id="notifications.weekStartsOn"/>}
                  rules={[{ required: true, message: <IntlMessages id="form.inputRequired" /> }]}
                >
                  <Select style={{ width: 160 }}>
                    <Option key="0" value={0}><IntlMessages id="app.sunday" /></Option>
                    <Option key="1" value={1}><IntlMessages id="app.monday" /></Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  name="day"
                  className='wrap-label'
                  label={<IntlMessages id="notificationsForm.sendOnDays" />}
                  tooltip={<IntlMessages id="notificationsForm.sendOnDaysInfo" />}
                  rules={[{ required: true, message: <IntlMessages id="form.inputRequired" /> }]}
                >
                  <Radio.Group>
                    {dayOfWeekMap.map(day => <Radio key={day.value} value={day.value}>
                      <IntlMessages id={`${day.name}`} />
                    </Radio>)}
                  </Radio.Group>
                </Form.Item>
              </>
            }

            {notificationFrequency === 'DAILY' &&
              <Form.Item
                name="sendOnDays"
                className='wrap-label'
                label={<IntlMessages id="notificationsForm.sendOnDays" />}
                tooltip={<IntlMessages id="notificationsForm.sendOnDaysInfo" />}
                rules={[{ required: true, message: <IntlMessages id="form.inputRequired" /> }]}
              >
                <Checkbox.Group>
                  {WorkWeek.map(day =>
                    <Checkbox key={day.value} value={day.value}>{day.label}</Checkbox>)
                  }
                </Checkbox.Group>
              </Form.Item>
            }

            <Form.Item
              wrapperCol={{ xs: { span: 24 }, sm: { span: 24 }, md: { span: 24 }, lg: { span: 24 } }}
              className='wrap-label'
              label={<IntlMessages id="app.time" />}
            >
              <div className='form-inline'>
                <Form.Item style={{ width: 125, paddingRight: 20 }}>
                  <TimePicker
                    minuteStep={15}
                    format={hourFormat === HourFormatEnum.twelve ? 'h:mm A' : 'HH:mm'}
                    onSelect={onChangeTime}
                    value={moment(`${notification?.hour}:${notification?.minute}`, `${hourFormat === HourFormatEnum.twelve ? 'hh:mm a' : 'HH:mm'}`)}
                    defaultValue={moment('10:00', `${hourFormat === HourFormatEnum.twelve ? 'hh:mm a' : 'HH:mm'}`)}
                    use12Hours={hourFormat === HourFormatEnum.twelve}
                  />
                </Form.Item>
                <Form.Item
                  name="timezone"
                  className='wrap-label'
                  label={<IntlMessages id="app.timezone" />}
                  rules={[{ required: true, message: <IntlMessages id="form.inputRequired" /> }]}
                >
                  <Select style={{ minWidth: 230 }} showSearch filterOption={filterOptions} data-qa='timezone-select'>
                    {timezone.map(tz => <Option key={tz.id} value={tz.id}>{tz.text}</Option>)}
                  </Select>
                </Form.Item>
              </div>
            </Form.Item>
            <NotificationDestinations
              platform={authCompany?.platform as NotificationPlatform}
              setChannelName={setChannelName}
              form={form}
              initialDestination={initialDestination}
              notification={notification}
              onValueChange={handleLocalStorageDestinationChanges}
              shouldReset={shouldResetNotificationDestination}
            />
            <Form.Item
              style={{ marginTop: 20 }}
              name='locale'
              className='wrap-label'
              label={<IntlMessages id="notificationsForm.locale"/>}
              rules={[{ required: true, message: <IntlMessages id="form.inputRequired" /> }]}
            >
              <Select style={{ width: 150 }} defaultValue={ locale || LocaleEnum.en }>
                {
                  Object.values(availableLanguages).map(language =>
                    <Option key={language.locale} value={language.locale}>{language.name}</Option>
                  )
                }
              </Select>
            </Form.Item>

            <Form.Item
              style={{ marginTop: 20 }}
              name='groupBy'
              className='wrap-label'
              label={<IntlMessages id="notificationsForm.groupBy"/>}
              tooltip={<IntlMessages id="notificationsForm.groupBy.tooltip" />}
            >
              <Select style={{ width: 150 }}>
                {['TEAM', 'LOCATION', 'NONE'].map(g => {
                  if (g === 'TEAM') {
                    return <Option key={g} value={g}><IntlMessages id="app.department"/></Option>
                  }
                  if (g === 'LOCATION') {
                    return <Option key={g} value={g}><IntlMessages id="app.location"/></Option>
                  }
                  return <Option key={g} value={g}><IntlMessages id={`notificationsForm.${g}`}/></Option>
                })}
              </Select>
            </Form.Item>

            <Form.Item
              name='sendEmpty'
              className='wrap-label'
              label={<IntlMessages id="notificationsForm.sendEmpty" />}
            >
              <Radio.Group className='vt-notification-radio-btn'>
                <Radio value={true}><IntlMessages id="notificationsForm.sendEmptyYes" /></Radio>
                <Radio value={false}><IntlMessages id="notificationsForm.sendEmptyNo" /></Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item
              wrapperCol={{ xs: { span: 24 }, sm: { span: 24 }, md: { span: 12, offset: 6 }, lg: { span: 12, offset: 4 } }}
              shouldUpdate
            >
              {({ getFieldValue, getFieldsValue }) => {
                const formData = getFieldsValue(true)

                const numberOfEmailAddress = flatMap(formData, item => {
                  if(item?.type === 'Email' && item.addresses) {
                    return item.addresses.length
                  }
                }).filter(Boolean)[0]

                return ( 
                  <div className='vt-notification-message'>
                    <NotificationSummaryInfo
                      filters={filter}
                      noOfUsers={totalUsers}
                      channelName={channelName}
                      numberOfEmailAddress={numberOfEmailAddress}
                      sendOnDays={getFieldValue('sendOnDays')}
                      day={getFieldValue('day')}
                      hour={notification.hour}
                      minute={notification.minute}
                      frequency={notificationFrequency}
                    />
                  </div>
                )
              }}
            </Form.Item>

            <Form.Item {...tailLayout}>
              <div className='d-flex vt-gap-10'>
                <Button type="default" style={{ marginRight: 10 }} onClick={() => history.push('/app/notifications')}>
                  <IntlMessages id="app.cancel" />
                </Button>
                {!match.params.id && <Button className='vt-btn-reset'
                  type="text"
                  style={{ marginRight: 100 }}
                  onClick={handleResetForm}>
                  <IntlMessages id="app.reset" />
                </Button>}
                <Button 
                  type="primary" 
                  loading={isLoadingNotification || isLoading || isApiInProgress} 
                  htmlType="submit"    data-qa='submit-notification' 
                  className='vt-save-notification'>
                  {isUpadateNotification ? <IntlMessages id="notificationsForm.update" /> : <IntlMessages id="notificationsForm.submit" />}
                </Button>
              </div>
            </Form.Item>
          </Form>
        </>
      }
    </div>
  </div>
}

export default NotificationsFormPage