import React, { useContext, useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Button, notification, Table, Tooltip, Modal, Switch, message, Breadcrumb } from 'antd'
import { WarningTwoTone, DeleteOutlined, ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons'
import { API, graphqlOperation } from 'aws-amplify'

import { getAutomations } from '../../graphql/custom-queries'
import IntlMessages from '../../util/IntlMessages'
import CircularProgress from '../circular-progress'
import { notificationStore } from '../../context/notificationsContext/store'
import HandleSubscribeAutomations from '../handle-subscribe-automations'

import { track } from '../../services/analytics/analytics'
import FormattedDate from '@vacationtracker/shared/components/formatted-date'
import EmptyData from '../empty-data'

import { IAutomations, IGetAutomations } from '../../types/custom-queries'
import { IData, ITableSort } from '../../types/data'
import { FrontendUrls } from '../../types/urls'
import { IAutomationViewPage } from '@vacationtracker/shared/types/automations'
import { AddonsStatusEnum } from '@vacationtracker/shared/types/addons'

const { confirm } = Modal

const BlackoutPeriodView = ({
  formatMessage,
  amIAdmin,
  deleteAutomation,
  authUserId,
  dateFormat,
  handleSubscribe,
  deleteCorrelationId,
  status,
  userCount,
}: IAutomationViewPage) => {
  const history = useHistory()
  const { actionNotifications, setActionNotifications } = useContext(notificationStore)

  const [isLoading, setIsLoading] = useState(true)
  const [activeAutomationStatus, setActiveAutomationStatus] = useState<{id: string; isActive: boolean} | null>(null)
  const [sortedInfo, setSortedInfo] = useState<ITableSort>({
    columnKey: 'name',
    order: 'ascend',
  })
  const [automations, setAutomations] = useState<IAutomations[]>([])
  const [correlationId, setCorrelationId] = useState<string | null>()
  const [deleteAutomationId, setDeleteAutomationId] = useState<string | null>(null)

  useEffect(() => {
    if(deleteCorrelationId) {
      setCorrelationId(deleteCorrelationId)
    }
  }, [deleteCorrelationId])

  useEffect(() => {
    fetchData()
    if (correlationId && !actionNotifications.includes(correlationId) && activeAutomationStatus) {
      setActiveAutomationStatus(null)
      setCorrelationId(null)
      setDeleteAutomationId(null)
    }
  }, [actionNotifications])

  const fetchData = async () => {
    const response = await API.graphql(graphqlOperation(getAutomations)) as IData<IGetAutomations>
    setAutomations(response.data.getAutomations.filter(automation => automation.automationType === 'BLACKOUT_PERIOD'))

    setIsLoading(false)
  }

  const createAuomation = () => {
    history.push(`${FrontendUrls.addOns}/blackout-period/create`)
  }

  const showConfirmDelete = (row: IAutomations, event) => {
    event.stopPropagation()

    confirm({
      title: formatMessage({ id: 'notifications.deleteAutomationTitle' }),
      icon: <ExclamationCircleOutlined />,
      content: formatMessage(
        { id: 'notifications.deleteAutomationConfirm' },
        { name: row.name, strong: (...chunks) => <strong>{chunks}</strong> }
      ),
      okText: formatMessage({ id: 'app.delete' }),
      okType: 'danger',
      width: 500,
      maskClosable: true,
      onOk() {
        setDeleteAutomationId(row.id)
        deleteAutomation(row.id as string, row.name as string, row.automationType as string)
      },
    })
  }

  const updateAutomations = async (row, status) => {
    setActiveAutomationStatus({
      id: row.id,
      isActive: status,
    })
    track('AUTOMATION_TOGGLE_CHANGED', {
      automationType: row.automationType,
      automationStatus: status ? 'enabled' : 'disabled',
    })

    const response = await API.post('CoreEvent', '/core/event', {
      body: {
        eventType: 'BLACKOUT_PERIOD_UPDATED',
        eventGroup: 'AUTOMATION',
        name: row.name,
        leaveTypeIds: row.leaveTypeIds,
        startDate: row.startDate,
        endDate: row.endDate,
        recurring: row.recurring,
        reason: row.reason,
        locations: row.locations,
        teams: row.teams,
        labels: row.labels,
        automationId: row.id,
        isActive: status,
      },
    })
    setCorrelationId(response.correlationId as string)

    notification.open({
      key: response.correlationId,
      message: formatMessage({ id: 'automations.blackoutPeriod.updateInProgress' }),
      icon: (<LoadingOutlined />),
      duration: 0,
    })
    setActionNotifications([ ...actionNotifications, response.correlationId ])
  }

  const confirmUpdateAutomations = (row, checked) => {
    if (!checked) {
      confirm({
        title: formatMessage({ id: 'notifications.updateAutomationTitle' }),
        icon: <ExclamationCircleOutlined />,
        content: formatMessage({ id: 'notifications.updateAutomationConfirm' }),
        okText: formatMessage({ id: 'app.ok' }),
        okType: 'danger',
        width: 500,
        maskClosable: true,
        onOk() {
          updateAutomations(row, checked)
        },
      })
    } else {
      updateAutomations(row, checked)
    }
  }

  const handleTableChange = (pagination, filters, sorter: ITableSort) => {
    setSortedInfo(sorter)
  }

  const automationsColumns = [
    {
      title: <IntlMessages id="app.active" />,
      dataIndex: 'isActive',
      key: 'isActive',
      width: 100,
      sorter: ((a, b) => b.isActive - a.isActive),
      sortOrder: (sortedInfo.columnKey === 'isActive' && sortedInfo.order) || null,
      render: (id: string, row) => {
        if (row.error) {
          return (
            <Tooltip title={<IntlMessages id={row.error} />}>
              <div style={{ width: '100%', height: '100', textAlign: 'center'}}>
                <WarningTwoTone twoToneColor="red" style={{ fontSize: 20 }} />
              </div>
            </Tooltip>
          )
        }
        return (
          <Switch checked={row.isActive}
            loading={Boolean(activeAutomationStatus && activeAutomationStatus.id === row.id)}
            onClick={(checked, event) => {
              event.stopPropagation()
              confirmUpdateAutomations(row, checked)
            }}
            disabled={(!amIAdmin && authUserId !== row.owner?.id) || status === AddonsStatusEnum.CANCELED}
          />
        )
      },
    },
    {
      title: <IntlMessages id="app.name" />,
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortOrder: (sortedInfo.columnKey === 'name' && sortedInfo.order) || null,
    },
    {
      title: <IntlMessages id="automationsForm.period" />,
      dataIndex: 'startDate',
      key: 'startDate',
      render: (startDate, row: IAutomations) => {
        if (startDate === row.endDate) {
          return <FormattedDate value={startDate} format={dateFormat} />
        } else {
          return (
            <>
              <FormattedDate value={startDate} format={dateFormat} /> - <FormattedDate value={row.endDate as string} format={dateFormat} />
            </>
          )
        }

      },
    },
    {
      title: <IntlMessages id="app.created" />,
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
      sortOrder: (sortedInfo.columnKey === 'createdAt' && sortedInfo.order) || null,
      render: (createdAt) => <FormattedDate value={createdAt.split('T')[0]} format={dateFormat} />,
    },
    {
      title: '',
      className: 'action',
      width: 78,
      dataIndex: 'id',
      key: 'id',
      render: (id: string, row: IAutomations) => (
        <Button
          type="link"
          disabled={(!amIAdmin && authUserId !== row.owner?.id) || status === AddonsStatusEnum.CANCELED}
          onClick={(event) => showConfirmDelete(row, event)}
          loading={Boolean(deleteAutomationId && deleteAutomationId === id)}
        >
          {!(deleteAutomationId && deleteAutomationId === id) && <DeleteOutlined />}
        </Button>
      ),
    },
  ]

  return (
    <div className='main-content addons-view-page'>
      <div className="main-content-header">
        <div className="main-content-header-title">
          <IntlMessages id="automations.BLACKOUT_PERIOD" />
        </div>
        <div className="main-content-header-breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/app/dashboard"><IntlMessages id="sidebar.dashboard" /></Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/app/add-ons"><IntlMessages id="sidebar.addons" /></Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item><IntlMessages id="automations.BLACKOUT_PERIOD" /></Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <div className="main-content-body">
        {amIAdmin && status !== AddonsStatusEnum.GRANT_PERIOD &&
          <HandleSubscribeAutomations
            handleSubscribe={handleSubscribe}
            userCount={userCount}
            status={status}
            automationType="BLACKOUT_PERIOD"
            formatMessage={formatMessage}
          />
        }
        <div className="main-content-info">
          <IntlMessages id="choiceAutomations.blackoutPeriodInfo" />
          <Button type="primary" disabled={status === AddonsStatusEnum.CANCELED} onClick={createAuomation}>
            <IntlMessages id="automations.createAutomation" />
          </Button>
        </div>
        {isLoading ?
          <CircularProgress /> :
          <>
            {automations.length > 0 ?
              <Table
                dataSource={automations}
                columns={automationsColumns}
                className="clickable-table"
                onChange={(pagination, filters, sorter) => {
                  handleTableChange(pagination, filters, sorter as ITableSort)
                }}
                rowClassName={(record) => {
                  if(record.error) {
                    return 'clickable-table-error'
                  }
                  return ''
                }}
                onRow={(record) => {
                  if (record.error) {
                    return {onClick: () => { return }}
                  }
                  if (amIAdmin || authUserId === record.owner?.id) {
                    return {
                      onClick: () => history.push(`${FrontendUrls.addOns}/${record.automationType.replace(/_/g, '-').toLowerCase()}/${record.id}/edit`),
                    }
                  } else {
                    return {onClick: () => {
                      message.error(formatMessage({ id: 'automations.cantOpenAutomations' }) as string)
                    }}
                  }
                }}
                rowKey="id"
                pagination={false}
              /> :
              <EmptyData
                title={<IntlMessages id="automations.emptyViewTitle" />}
                subTitle={<IntlMessages id="automations.emptyViewMessage" />}
              />
            }
          </>
        }
      </div>
    </div>
  )
}

export default BlackoutPeriodView
