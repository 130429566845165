import React, { useState, useEffect, useContext } from 'react'
import { Badge, Button, Menu, Tag, Tooltip } from 'antd'
import { useLocation, useHistory, Link } from 'react-router-dom'
import { isBefore } from 'date-fns'
import Icon, { StarOutlined, GiftOutlined } from '@ant-design/icons'

import { TimelineVert } from '../../components/custom-svg-icons'
import { ThemeContext } from '../../context/themeContext'
import { SURVEY_TRIAL_EXTEND } from '../../components/zoho-pop-up-survey'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { selectAuthCompanySlice } from '../../store/auth-company-slice'
import { selectAuthUserSlice } from '../../store/auth-user-slice'
import { useShouldEnableFeatures } from '../../store/use-should-enable-features'
import { handleOnboardingVisible, selectOnboardingSlice } from '../../store/onboarding-slice'
import CustomScrollbars from '../../util/CustomScrollbars'
import IntlMessages from '../../util/IntlMessages'

import SidebarLogo from './SidebarLogo'
import menus from './main-menu'

import { SubscriptionPlanEnum } from '@vacationtracker/shared/types/company'
import { Platform } from '@vacationtracker/shared/types/core-event'
import { IMainMenu } from '../../types/sidebar-content'
import { FrontendUrls } from '../../types/urls'
import { FeatureFlagEnum } from '@vacationtracker/shared/types/feature-flags'
import { useFeatureFlagEnabled } from 'posthog-js/react'

const SidebarContent = () => {
  const { authUser } = useAppSelector(selectAuthUserSlice)
  const { authCompany } = useAppSelector(selectAuthCompanySlice)
  const { progressCompleted, totalChecklist, hideOnboardingFlow, onboardingCompleted, onboardingSkipped } = useAppSelector(selectOnboardingSlice)
  const dispatch = useAppDispatch()
  const aiAssistedOnboardingEnabled = useFeatureFlagEnabled(FeatureFlagEnum.aiAssistedOnboarding)

  const { navCollapsed, miniMenu, setNavCollapsed, changeSelectedKeys } = useContext(ThemeContext)

  const history = useHistory()
  const { pathname } = useLocation()

  const [rootSubmenuKeys] = useState(menus.map(item => item.path))
  const [openKeys, setOpenKeys] = useState(() => {
    let subMenu
    rootSubmenuKeys.forEach(key => {
      if (pathname.startsWith(key)) {
        subMenu = key
      }
    })
    return subMenu ? [subMenu] : [pathname]
  })
  const [selectedKeys, setSelectedKeys] = useState<string[]>()
  const [showButtonMenu, setShowButtonMenu] = useState(false)
  const [companyPaymentProcessor, setCompanyPaymentProcessor] = useState<string | null>(null)

  useEffect(() => {
    const findRootKey = (path) => {
      const rootPath = menus.find(item => path.startsWith(item.path))
      return rootPath ? rootPath.path : '/'
    }

    const rootKey = findRootKey(pathname)
    setSelectedKeys([pathname, rootKey])
    setOpenKeys(prevKeys => [...new Set([...prevKeys, rootKey])])
  }, [pathname, menus])

  useEffect(() => {
    if (changeSelectedKeys) {
      if (authUser.role === 'Approver') {
        setOpenKeys(['/app/leaves'])
      } else {
        setOpenKeys(['/app/settings'])
      }
    }
  }, [changeSelectedKeys])

  useEffect(() => {
    setTimeout(() => {
      setShowButtonMenu(true)
    }, 100)
  }, [])

  useEffect(() => {
    if (authCompany?.billing?.paymentProcessor) {
      setCompanyPaymentProcessor(authCompany.billing.paymentProcessor)
    }
  }, [authCompany])

  const handleMenuClick = (event) => {
    if (navCollapsed) {
      setNavCollapsed(!navCollapsed)
    }
    if (event.key.includes('https')) {
      return
    }
    history.push(event.key)
  }

  const handleOpenChange = (event) => {
    setOpenKeys(event)
  }

  const RenderMenuItem = (menu) => {
    const {
      title,
      path,
      icon,
      children,
      isLink,
      roles,
      joyRideClass,
      platform,
      featureFlagName,
      ignorePlan,
      shouldDisableForBrowser,
      onlyFeatureFlag,
      plan,
      tooltip,
      paymentProcessor,
      isInitialUser,
      posthogFeatureFlagName,
    }: IMainMenu = menu

    
    //TODO refactor this component 
    const shouldEnableFeatures = useShouldEnableFeatures(SubscriptionPlanEnum.complete, featureFlagName, onlyFeatureFlag)

    if (
      (featureFlagName || posthogFeatureFlagName || onlyFeatureFlag)
      && !shouldEnableFeatures
      && !posthogFeatureFlagName
    ) {
      return
    }

    if (posthogFeatureFlagName === FeatureFlagEnum.aiAssistedOnboarding && !aiAssistedOnboardingEnabled) {
      return
    }

    if (shouldDisableForBrowser) {
      return
    }

    if (path === FrontendUrls.aiAssistant && !isInitialUser) {
      return
    }

    const accessRole = roles && roles.indexOf(authUser.role) !== -1
    if (!accessRole) {
      return
    }

    const accessPlatform = platform && platform.indexOf(authCompany?.platform as Platform) !== -1
    if (!accessPlatform) {
      return
    }

    if (typeof paymentProcessor === 'string' && paymentProcessor !== companyPaymentProcessor) {
      return
    }

    if (!ignorePlan && (plan && plan !== authCompany?.plan && !onlyFeatureFlag)) {
      return
    }

    if (path.endsWith('request-toil') && authUser.role === 'User') {
      const userCanRequestToil = authUser.location.leavePolicies?.find(lp => lp.toil && lp.toilRequestsAllowedForUsers)
      if (!userCanRequestToil) {
        return
      }
    }

    return (
      children ? renderSubMenuItem(menu) : (
        <Menu.Item key={path} className={joyRideClass || ''}>
          {icon}
          {!isLink ?
            <span>
              <Link to={path}><IntlMessages id={title} /> {
                tooltip && isBefore(new Date(), tooltip.hideAfter) && <Tag color="purple">
                  {tooltip.tagTitle}
                </Tag>
              }</Link>

            </span> :
            <span><a href={path} target="_blank" rel="noopener noreferrer"><IntlMessages id={title} /></a></span>
          }
        </Menu.Item>
      )
    )
  }

  const renderSubMenuItem = (menu) => {
    const { title, icon, path, children, roles, joyRideClass, platform } = menu

    const accessRole = roles && roles.indexOf(authUser.role) !== -1
    if (!accessRole) {
      return
    }

    const accessPlatform = platform && platform.indexOf(authCompany?.platform) !== -1
    if (!accessPlatform) {
      return
    }

    return (
      <Menu.SubMenu
        key={path}
        title={
          <span>
            {icon}
            <span><IntlMessages id={title} /></span>
          </span>
        }
        className={joyRideClass || ''}
      >
        {children && children.map(child => {
          const copy = { ...child }
          copy.path = (copy.isLink || copy.hasCleanPath) ? child.path : `${path}${child.path}`

          return RenderMenuItem(copy)
        })}
      </Menu.SubMenu>
    )
  }

  return (<>
    <SidebarLogo />
    <div className="layout-sidebar-content">
      <CustomScrollbars className="layout-sider-scrollbar">
        <Menu
          theme="light"
          mode="inline"
          selectedKeys={selectedKeys}
          defaultOpenKeys={openKeys}
          openKeys={openKeys}
          onClick={handleMenuClick}
          onOpenChange={handleOpenChange}
        >
          { menus.map(menu => {
            // TODO: IVAN - uncomment this when AI Assistant is ready
            // if (menu.path === FrontendUrls.aiAssistant && authCompany?.subscriptionStatus !== 'trialing' ) {
            //   return null
            // }

            return RenderMenuItem({
              ...menu,
              isInitialUser: authUser.initialUser,
            })
          }) }
        </Menu>
        {showButtonMenu &&
          <div className="sidebar-bottom">
            {!(hideOnboardingFlow || (onboardingCompleted || onboardingSkipped)) &&
              <Tooltip placement="right" title={<IntlMessages id="sidebar.onboarding" />} overlayClassName={!miniMenu ? 'display-none' : ''}>
                <Button type="link" className="onboarding" onClick={() => dispatch(handleOnboardingVisible())}>
                  <Badge count={`${progressCompleted}/${totalChecklist}`} offset={[15, 0]} style={{ backgroundColor: '#52c41a' }}>
                    <StarOutlined /> <span className="text"><IntlMessages id="sidebar.onboarding" /></span>
                  </Badge>
                </Button>
              </Tooltip>
            }
            {authUser.role === 'Admin'
              && authCompany?.trialPeriod === 'in_trial'
              && authCompany.billing?.paymentProcessor !== 'microsoft-billing'
              && !authCompany?.billing?.couponCodes?.includes(SURVEY_TRIAL_EXTEND)
              ? <Tooltip placement="right" title={<IntlMessages id="sidebar.changelog" />} overlayClassName={!miniMenu ? 'display-none' : ''}>
                <Link className="changelog" to={'/app/dashboard?showSurvey=true'}>
                  <GiftOutlined />
                  <IntlMessages id='app.trialExtend' />
                </Link>
              </Tooltip>
              : hideOnboardingFlow || (onboardingCompleted || onboardingSkipped) ?
                <Tooltip placement="right" title={<IntlMessages id="sidebar.changelog" />} overlayClassName={!miniMenu ? 'display-none' : ''}>
                  <a href="https://vacationtracker.io/changelog/" className="changelog" rel="noopener noreferrer" target="_blank">
                    <Icon component={TimelineVert} /> <span className="text"><IntlMessages id="sidebar.changelog" /></span>
                  </a>
                </Tooltip> : null}
          </div>
        }
      </CustomScrollbars>

    </div>
  </>)
}

export default SidebarContent

