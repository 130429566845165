import React, { useContext, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Button, notification, Breadcrumb, Form, Select, Alert, Col, Row, Typography } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { API, graphqlOperation } from 'aws-amplify'

import * as logger from '../../services/logger'
import { getAutomations } from '../../graphql/custom-queries'
import IntlMessages from '../../util/IntlMessages'
import CircularProgress from '../circular-progress'
import { notificationStore } from '../../context/notificationsContext/store'
import HandleSubscribeAutomations from '../handle-subscribe-automations'

import { IGetAutomations } from '../../types/custom-queries'
import { IData } from '../../types/data'
import { AddonsStatusEnum, AddonTypeEnum, IAddOnVisibilityForm, VisibilityLevel } from '@vacationtracker/shared/types/addons'
import YesNoSwitch from '../yes-no-switch'

const { Title, Paragraph } = Typography

const VisibilityAddonView = ({
  formatMessage,
  amIAdmin,
  handleSubscribe,
  status,
  userCount,
  backToAddons,
}: IAddOnVisibilityForm) => {
  const [form] = Form.useForm()
  const { actionNotifications, setActionNotifications } = useContext(notificationStore)

  const [isLoading, setIsLoading] = useState(true)
  const [btnLoading, setBtnLoading] = useState(false)
  const [addOn, setAddOn] = useState<{automationId?: string | null; isActive: boolean; visibilityLevel?: VisibilityLevel[]}>({
    automationId: null,
    isActive: false,
    visibilityLevel: [],
  })
  const [correlationId, setCorrelationId] = useState<string | null>()

  useEffect(() => {
    fetchData()
  }, [addOn.automationId])


  useEffect(() => {
    if (correlationId && !actionNotifications.includes(correlationId)) {
      setCorrelationId(null)
      setBtnLoading(false)
      fetchData()
    }
  }, [actionNotifications])


  const fetchData = async () => {
    setIsLoading(true)
    if(!amIAdmin || !status) {
      // if non admin user tries to access this page directly through url or we don't have status return them to the addons page
      backToAddons()
    }

    const response = await API.graphql(graphqlOperation(getAutomations)) as IData<IGetAutomations>
    const currentVisibilityAddon = response.data.getAutomations.filter(automation => automation.automationType === 'VISIBILITY')
    if (currentVisibilityAddon.length > 0) {
      const {id, isActive, visibilityLevel } = currentVisibilityAddon[0]
      setAddOn({ automationId: id, isActive, visibilityLevel })
      form.setFieldsValue({ isActive, visibilityLevel })
    }

    setIsLoading(false)
  }


  const onFinish = () => {
    if (status === AddonsStatusEnum.CANCELED || !amIAdmin) return

    form
      .validateFields()
      .then(async (values) => {
        setBtnLoading(true)
        let response
        try {
          const data = {
            automationId: addOn.automationId,
            isActive: values.isActive,
            visibilityLevel: values.visibilityLevel || [],
          }

          if (!addOn.automationId) {
            delete data.automationId
          } 

          response = await API.post('CoreEvent', '/core/event', {
            body: {
              eventType: addOn.automationId ? 'VISIBILITY_ADDON_UPDATED' : 'VISIBILITY_ADDON_CREATED',
              eventGroup: 'AUTOMATION',
              ...data,
            },
          })
  

          notification.open({
            key: response.correlationId,
            message: formatMessage({ id: addOn ? 'automations.visibility.updateInProgress' : 'automations.visibility.createInProgress' }),
            icon: (<LoadingOutlined />),
            duration: 0,
          })
          setActionNotifications([ ...actionNotifications, response.correlationId ])
          setCorrelationId(response.correlationId)
        } catch (error) {
          setBtnLoading(false)
          if (error.response?.data?.message) {
            notification.error({
              message: formatMessage({ id: 'error.generic' }),
              description: formatMessage({ id: error.response.data.message }),
              duration: 0,
            })
          } else if (typeof error?.message === 'string') {
            notification.error({
              message: formatMessage({ id: 'error.generic' }),
              description: formatMessage({ id: error.message }),
              duration: 0,
            })
          } else {
            const description = response?.correlationId ? formatMessage({ id: 'app.correlationIdError' }, { correlationId: response.correlationId }) : JSON.stringify(error)

            notification.error({
              message: formatMessage({ id: 'error.generic' }),
              description,
              duration: 0,
            })
          }
        }
      })
      .catch(info => {
        logger.warning('Validate Failed:', info)
        setBtnLoading(false)
      })
  }



  const onLimitVisibilityChange = (value) => {
    if (!value) {
      form.setFieldsValue({ visibilityLevel: [] })
      setAddOn({ ...addOn, isActive: false, visibilityLevel: [] })
      return
    }
    setAddOn({ ...addOn, isActive: value })
  }

  const onVisibilityTypeChange = (value: VisibilityLevel[]) => {
    setAddOn({ ...addOn, visibilityLevel: value })
  }


  return (
    <div className='main-content addons-view-page'>
      <div className='main-content-header'>
        <div className='main-content-header-title'>
          <IntlMessages id='automations.VISIBILITY' />
        </div>
        <div className='main-content-header-breadcrumb'>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to='/app/dashboard'>
                <IntlMessages id='sidebar.dashboard' />
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to='/app/add-ons'>
                <IntlMessages id='sidebar.addons' />
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <IntlMessages id='automations.VISIBILITY' />
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <div className='main-content-body'>
        {amIAdmin && status !== AddonsStatusEnum.GRANT_PERIOD && (
          <HandleSubscribeAutomations
            handleSubscribe={handleSubscribe}
            userCount={userCount}
            status={status}
            automationType={AddonTypeEnum.VISIBILITY}
            formatMessage={formatMessage}
          />
        )}
        {isLoading && <CircularProgress />}
        {!isLoading && 
          <Form
            form={form}
            name='visibilityForm'
            initialValues={addOn}
            onFinish={onFinish}
            layout="vertical"
            requiredMark="optional"
            labelWrap
            size='large'
            disabled={status === AddonsStatusEnum.CANCELED}
          >
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col xxl={8} xl={8} lg={6} md={6} sm={24} xs={24}>
                <Title level={4}><IntlMessages id="automations.visibilityInfo.settings.nameTitle" /></Title>
                <Paragraph type="secondary"><IntlMessages id="choiceAutomations.visibilityInfo" /></Paragraph>
              </Col>
              <Col xxl={16} xl={16} lg={18} md={18} sm={24} xs={24}>
                <YesNoSwitch
                  name="isActive"
                  label={<IntlMessages id="automations.visibilityForm.limitVisibility" />}
                  onChange={onLimitVisibilityChange}
                />

                {addOn.isActive && (
                  <Form.Item
                    name='visibilityLevel'
                    label={
                      <IntlMessages id='automations.visibilityForm.visibilityLevel' />
                    }
                    rules={[
                      {
                        required: true,
                        message: (
                          <IntlMessages id='automations.visibilityForm.visibilityTypeValidationRequired' />
                        ),
                      },
                    ]}
                  >
                    <Select
                      mode='multiple'
                      placeholder='Select visibility scope'
                      onChange={onVisibilityTypeChange}
                    >
                      {Object.values(VisibilityLevel).map((visibilityLevel) => (
                        <Select.Option key={visibilityLevel} value={visibilityLevel}>
                          <IntlMessages
                            id={`automations.visibilityForm.${visibilityLevel}`}
                          />
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}
              </Col>
            </Row>

            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span={24}>
                <Form.Item style={{ textAlign: 'right' }}>
                  <Button type='default' style={{marginRight: 10}} onClick={backToAddons} disabled={btnLoading}>
                    <IntlMessages id='app.cancel' />
                  </Button>
                  <Button type='primary' loading={btnLoading} htmlType='submit'>
                    <IntlMessages id='app.save' />
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        }

        {addOn.visibilityLevel?.includes(VisibilityLevel.DEPARTMENT) && (
          <Alert
            message={<IntlMessages id='automations.departmentVisibilityLevelInfo' />}
            style={{marginBottom: '30px'}}
          />
        )}
        {addOn.isActive && (
          <Alert
            message={<IntlMessages id='automations.visibilityManagerNotificationsInfo' />}
            style={{marginBottom: '30px'}}
            type='warning'
          />
        )}
        {!addOn.visibilityLevel?.length && addOn.automationId && !addOn.isActive && (
          <Alert
            message={<IntlMessages id='automations.deactivateVisibilityWarn' />}
            style={{marginBottom: '30px'}}
            type='warning'
          />
        )}
        <IntlMessages 
          id="automations.stepByStepHelpDesk" 
          values={{
            automationName: () => <IntlMessages id="automations.VISIBILITY" />,
            link: (...chunks) => 
              <a 
                href="https://vacationtracker.crisp.help/en/article/how-do-i-limit-users-visibility-1xbdrm6/?bust=1723123359283" 
                target="_blank" 
                id="helpdesk" 
                rel="noopener noreferrer"
              >
                {chunks}
              </a>,
          }}
        />
      </div>
    </div>
  )
}

export default VisibilityAddonView
