import React, { useEffect, useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { Breadcrumb, Button, message, Modal, Switch, Table, notification, Tag, Space, Tooltip } from 'antd'
import {
  CloseOutlined,
  CheckOutlined,
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  MenuOutlined,
  LoadingOutlined,
  WarningOutlined,
  PlusOutlined
} from '@ant-design/icons'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import arrayMove from 'array-move'
import sortBy from 'lodash/sortBy'
import differenceWith from 'lodash/differenceWith'
import isEqual from 'lodash/isEqual'
import * as Sentry from '@sentry/react'
import { API, graphqlOperation } from 'aws-amplify'
import { getLeaveTypesListAndLocations } from '../../../graphql/custom-queries'
import * as logger from '../../../services/logger'

import { useAppSelector } from '../../../store/hooks'
import { selectAuthUserSlice } from '../../../store/auth-user-slice'
import { selectAuthCompanySlice } from '../../../store/auth-company-slice'
import { notificationStore } from '../../../context/notificationsContext/store'
import { invertHexColor } from '../../../util/invert-color-wrapper'

import IntlMessages from '../../../util/IntlMessages'
import CreateLeaveTypeForm from '../../../components/create-leave-type-form'
import EditLeaveTypeForm from '../../../components/edit-leave-type-form'

import { ColumnsType } from 'antd/lib/table'
import { IGetLeaveTypesListAndLocationsData, ILeaveTypeUpdates, ILeaveTypeWithAssignLocations } from '../../../types/leave-types'
import { IGetLeaveTypeList } from '../../../types/custom-queries'
import { IGetLocationsShort } from '../../../types/locations'
import { ISimilarLeaveType } from '@vacationtracker/shared/types/leave-type'
// import { showHelpInfo } from '../../../services/help-info/show-help-info'

const { confirm } = Modal

const LeaveTypesPage = () => {
  const { formatMessage } = useIntl()
  const { authUser: { platform } } = useAppSelector(selectAuthUserSlice)
  const { authCompany } = useAppSelector(selectAuthCompanySlice)

  const { actionNotifications, setActionNotifications, eventsNotifications } = useContext(notificationStore)
  const [loading, setLoading] = useState(true)
  const [updateLoading, setUpdateLoading] = useState(false)
  const [isCreateLeaveTypeAndAssignPolicyWizardVisible, setIsCreateLeaveTypeAndAssignPolicyWizardVisible] = useState(false)
  const [isUpdateLeaveTypeFormVisible, setUpdateLeaveTypeFormVisibility] = useState(false)
  const [leaveTypesList, setLeaveTypesList] = useState<ILeaveTypeWithAssignLocations[]>([])
  const [locations, setLocations] = useState<IGetLocationsShort[]>([])
  const [selectedLeaveType, setSelectedLeaveType] = useState<ILeaveTypeWithAssignLocations>()
  const [assigingPolicyCorrelationId, setAssigningPolicyCorrelationId] = useState('')
  const [assigningPolicyCorrelationExist, setAssigningPolicyCorrelationExist] = useState(false)
  const [shouldReloadOnActionNotificationsChange, setShouldReloadOnActionNotificationsChange] = useState(true)
  const [notAssignedLocations, setNotAssignedLocations] = useState<IGetLocationsShort[]>([])
  const [isAssigningLeaveTypeToLocationModalVisible, setIsAssigningLeaveTypeToLocationModalVisible] = useState(false)
  const [selectedLeaveTypeName, setSelectedLeaveTypeName] = useState('')
  const [leaveTypeId, setLeaveTypeId] = useState<string>('')
  const [showButtonAddFor, setShowButtonAddFor] = useState<string[]>([])
  const [similarLeaveType, setSimilarLeaveType] = useState<ISimilarLeaveType | null>(null)
  const [isCheckForLeaveTypeSimilarityLoading, setIsCheckForLeaveTypeSimilarityLoading] = useState(false)

  useEffect(() => {
    fetchLeaveTypesList()
  }, [])

  useEffect(() => {
    // we should reload HERE only when we just add leave type and do not assign policy to any location
    if (shouldReloadOnActionNotificationsChange) {
      fetchLeaveTypesList()
    }
  }, [actionNotifications])

  useEffect(() => {
    // reload when all leave policies are assigned thorugh wizard
    if (eventsNotifications && eventsNotifications[assigingPolicyCorrelationId]) {
      setAssigningPolicyCorrelationExist(true)

    }
    if (eventsNotifications && !eventsNotifications[assigingPolicyCorrelationId] && assigningPolicyCorrelationExist) {
      setAssigningPolicyCorrelationExist(false)
      setAssigningPolicyCorrelationId('')
      fetchLeaveTypesList()
    }
  }, [eventsNotifications])

  const SortableItem = SortableElement(props => <tr {...props} />)
  const SortableWrapper = SortableContainer(props => <tbody {...props} />)
  const DragableBodyRow = ({ ...restProps }) => {
    const index = restProps['data-row-key'] || 0
    return (
      <SortableItem {...restProps} index={index} />
    )
  }

  const createNewLeaveType = () => {
    // showHelpInfo(authCompany as ICompanyInfo, actions, 'HELP_INFO_LEAVE_TYPE_CREATE', dispatch)
    setSelectedLeaveType(undefined)
    setIsCreateLeaveTypeAndAssignPolicyWizardVisible(true)
  }

  const handleErrorNotification = (error, correlationId?: string, title?: string): void => {
    const description = correlationId ?
      formatMessage({ id: 'error.notificationGeneral' }, { correlationId }) :
      error.response?.data?.message ? error.response?.data?.message : error.message ? error.message : JSON.stringify(error)
    notification.error({
      message: title ? title : formatMessage({ id: 'error.notificationGeneral' }),
      description,
      duration: 0,
    })
  }

  const updateLeaveTypesOrder = async (types: ILeaveTypeWithAssignLocations[]) => {
    try {
      setLoading(true)
      await API.post('CoreEvent', '/core/event', {
        body: {
          eventType: 'LEAVE_TYPE_ORDER',
          eventGroup: 'COMPANY',
          positions: types.map(type => {
            return ({
              leaveTypeId: type.id,
              position: type.position,
            })
          }),
        },
      })

      setLoading(false)
    } catch (error) {
      logger.warning('error update leave type order', error)
      setLoading(false)
      message.error(error.message)
    }
  }

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newLeaveTypesPosition = arrayMove(([] as ILeaveTypeWithAssignLocations[]).concat(leaveTypesList), oldIndex, newIndex)
      const leaveTypeListSorted = newLeaveTypesPosition.map((lt: ILeaveTypeWithAssignLocations, index: number) => {
        return {
          ...lt,
          position: index,
          index,
          key: index,
          id: lt.id,
          name: lt.name,
        }
      })
      updateLeaveTypesOrder(leaveTypeListSorted)
      setLeaveTypesList(leaveTypeListSorted)
    }
  }

  const fetchLeaveTypesList = async () => {
    try {
      const response = await API.graphql(graphqlOperation(getLeaveTypesListAndLocations)) as IGetLeaveTypesListAndLocationsData
      setLocations(response.data.getLocationList)
      const leaveTypes: IGetLeaveTypeList[] = response.data.getLeaveTypeList ?
        response.data.getLeaveTypeList.sort((a: IGetLeaveTypeList, b: IGetLeaveTypeList) => a.position < b.position ? -1 : 1) :
        []
      setLeaveTypesList(leaveTypes.map((lt: IGetLeaveTypeList, index: number) => {
        return {
          index,
          key: index,
          assignedToLocations: lt.leavePolicies.length > 0
            ? lt.leavePolicies.map(leaveType => leaveType.location)
            : [],
          ...lt,
        }
      }))
      setLoading(false)
    } catch (err) {
      logger.warning('error fetching leave types list', err)
    }
  }

  const showConfirmDelete = (leaveTypeId: string, name: string) => {
    confirm({
      title: formatMessage({ id: 'leaveTypes.deleteLeaveType' }),
      icon: <ExclamationCircleOutlined />,
      content: formatMessage({ id: 'leaveTypes.deleteLeaveTypeConfirm' }, { name }),
      okText: formatMessage({ id: 'leaveTypes.deleteLeaveType' }),
      okType: 'danger',
      maskClosable: true,
      onOk() {
        deleteLeaveType(leaveTypeId, name)
      },
    })
  }

  const deleteLeaveType = async (leaveTypeId, name) => {
    try {
      setLoading(true)
      const response = await API.post('CoreEvent', '/core/event', {
        body: {
          eventType: 'LEAVE_TYPE_DELETED',
          eventGroup: 'LEAVE_TYPE',
          leaveTypeId,
        },
      })

      notification.open({
        key: response.correlationId,
        message: formatMessage({ id: 'leaveTypes.deleteInProgress' }, { leaveTypeName: name }),
        icon: (<LoadingOutlined />),
        duration: 0,
      })
      setActionNotifications([ ...actionNotifications, response.correlationId ])
      setShouldReloadOnActionNotificationsChange(true)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      message.error(error)
    }
  }

  const handleMenuClick = (target, leaveTypeId, name) => {
    if (target === 'deleteLeaveType') {
      showConfirmDelete(leaveTypeId, name)
    } else if (target === 'editLeaveType') {
      const selected = leaveTypesList.find((type) => type.id === leaveTypeId)
      if (!selected) {
        return
      }
      setSelectedLeaveType(selected)
      setUpdateLeaveTypeFormVisibility(true)
    }
  }

  const DragHandle = SortableHandle(() => (
    <MenuOutlined style={{ cursor: 'pointer', color: '#999' }} />
  ))

  const DraggableContainer = props => (
    <SortableWrapper
      useDragHandle
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  )

  const onUpdateLeaveType = async (leaveType: Partial<ILeaveTypeUpdates>) => {
    try {
      setUpdateLoading(true)
      setIsCreateLeaveTypeAndAssignPolicyWizardVisible(false)
      setLoading(true)
      const response = await API.post('CoreEvent', '/core/event', {
        body: {
          eventType: 'LEAVE_TYPE_UPDATED',
          eventGroup: 'LEAVE_TYPE',
          ...leaveType,
        },
      })
      setUpdateLeaveTypeFormVisibility(false)

      notification.open({
        key: response.correlationId,
        message: leaveType.leaveTypeId ?
          formatMessage({ id: 'leaveTypes.updateInProgress' }, { leaveTypeName: response.name }) :
          formatMessage({ id: 'leaveTypes.createInProgress' }, { leaveTypeName: response.name }),
        icon: (<LoadingOutlined />),
        duration: 0,
      })
      setActionNotifications([...actionNotifications, response.correlationId])
      setShouldReloadOnActionNotificationsChange(true)
      setLoading(false)
      setUpdateLoading(false)
      setSimilarLeaveType(null)
    } catch (error) {
      setLoading(false)
      setUpdateLoading(false)
      handleErrorNotification(error)
    }
  }

  const confirmChangeStatus = (levaeType) => {
    confirm({
      title: levaeType.isActive ?
        formatMessage({ id: 'leaveTypes.deactivateLeaveType' }) :
        formatMessage({ id: 'leaveTypes.activatingLeaveType' }),
      icon: <ExclamationCircleOutlined />,
      content: levaeType.isActive ?
        formatMessage({ id: 'leaveTypes.deactivateLeaveTypeInfo' }) :
        formatMessage({ id: 'leaveTypes.activatingLeaveTypeInfo' }),
      okText: formatMessage({ id: 'app.yes' }),
      cancelText: formatMessage({ id: 'app.no' }),
      onOk() {
        onUpdateLeaveType({
          leaveTypeId: levaeType.id,
          name: levaeType.name,
          isActive: !levaeType.isActive,
          color: levaeType.color,
        })
      },
    })
  }

  const leaveTypesColumns: ColumnsType<ILeaveTypeWithAssignLocations> = [
    {
      title: <IntlMessages id="leaveTypes.sort" />,
      dataIndex: 'sort',
      width: 30,
      className: 'drag-visible',
      // eslint-disable-next-line react/display-name
      render: () => <DragHandle />,
    },
    {
      title: <IntlMessages id="app.active" />,
      dataIndex: 'isActive',
      key: 'isActive',
      className: 'drag-visible',
      render: (isActive: boolean, row) => {
        return (<>
          <Switch
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            checked={isActive}
            onChange={() => confirmChangeStatus(row)}
          />
        </>)
      },
    },
    {
      title: <IntlMessages id="app.name" /> ,
      dataIndex: 'name',
      key: 'name',
      className: 'drag-visible leave-type-name',
    },
    {
      title: <IntlMessages id="leaveTypes.assignedToLocationsColumn" /> ,
      dataIndex: 'assignedToLocations',
      key: 'assignedToLocations',
      className: 'drag-visible',
      render: (ltLocations, leaveType) => {
        const allLocationsUnsorted = locations.filter(Boolean)
        const leaveTypelocationsUnsorted = ltLocations.filter(Boolean)
        const locationsSorted = sortBy(leaveTypelocationsUnsorted, [(location) => location.name.toUpperCase()])
        const notAssignedLocations = differenceWith(allLocationsUnsorted, leaveTypelocationsUnsorted, isEqual)
        const onAddClickHandler = () => {
          setShowButtonAddFor(showButtonAddFor.filter(e => e !== leaveType.id))
          setLeaveTypeId(leaveType.id)
          setSelectedLeaveTypeName(leaveType.name)
          setNotAssignedLocations(notAssignedLocations)
          setIsAssigningLeaveTypeToLocationModalVisible(true)
        }
        return (
          <>
            {locationsSorted.length > 0 ?
              locationsSorted.map(loc => {
                return <Tag key={loc.id}>
                  <Link to={`/app/settings/location/${loc.id}/leave-policies/${leaveType.id}`}>
                    {loc.name}
                  </Link>
                </Tag>
              }) :
              <>
                <WarningOutlined style={{color: 'red'}}/>
                <span style={{ color: 'red', marginRight: 5 }}><IntlMessages id="leaveTypes.assignedToLocationsColumnWarning" /></span>
              </>
            }
            {notAssignedLocations.length > 0 &&
              <Tag
                style={{ cursor: 'pointer' }}
                onClick={onAddClickHandler}
                onMouseEnter={() => setShowButtonAddFor([...showButtonAddFor, leaveType.id])}
                onMouseLeave={() => setShowButtonAddFor(showButtonAddFor.filter(e => e !== leaveType.id))}
              >
                <PlusOutlined /> {showButtonAddFor.includes(leaveType.id) && <IntlMessages id="components.createLeaveTypeForm.assignAndConfigure" />}
              </Tag>
            }
          </>
        )
      },
    },
    {
      title: <IntlMessages id="app.color" /> ,
      dataIndex: 'color',
      className: 'drag-visible color',
      key: 'color',
      render: (color: string) => {
        return (
          <span style={{ background: color, color: invertHexColor(color, true) }}>{color}</span>
        )
      },
    },
    {
      title: '',
      width: 90,
      dataIndex: 'id',
      key: 'id',
      render: (id: string, row) => (
        <Space size='middle'>
          <Tooltip title={<IntlMessages id="leaveTypes.editLeaveType" />}>
            <EditOutlined onClick={() => handleMenuClick('editLeaveType', id, row.name)} />
          </Tooltip>
          <Tooltip title={<IntlMessages id="leaveTypes.deleteLeaveType" />}>
            <DeleteOutlined onClick={() => handleMenuClick('deleteLeaveType', id, row.name)} />
          </Tooltip>
        </Space>
      ),
    },
  ]

  const handleCheckForLeaveTypeSimilarity = async (inputLeaveName: string) => {
    if (!inputLeaveName) {
      return
    }
    setIsCheckForLeaveTypeSimilarityLoading(true)
    try {
      const response = await API.post('CoreEvent', '/core/check-leave-type-for-similarity', {
        body: {
          leaveTypes: leaveTypesList.map(lt => ({ id: lt.id, name: lt.name })),
          inputLeaveName,
        },
      })
      setSimilarLeaveType(response)
      setIsCheckForLeaveTypeSimilarityLoading(false)
    } catch (error) {
      setIsCheckForLeaveTypeSimilarityLoading(false)
      Sentry.captureException(JSON.stringify(error))
    }
  }

  const handleCancel = () => {
    setIsCreateLeaveTypeAndAssignPolicyWizardVisible(false)
    setSelectedLeaveType(undefined)
    setSimilarLeaveType(null)
    setIsCheckForLeaveTypeSimilarityLoading(false)
  }

  const resetSimilarLeaveType = () => {
    setSimilarLeaveType(null)
  }

  return (
    <div className='main-content'>
      <div className="main-content-header">
        <div className="main-content-header-title">
          <span><IntlMessages id="app.leaveTypes" /></span>
        </div>
        <div className="main-content-header-breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/app/dashboard"><IntlMessages id="sidebar.dashboard" /></Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item><IntlMessages id="sidebar.settings" /></Breadcrumb.Item>
            <Breadcrumb.Item><IntlMessages id="app.leaveTypes" /></Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <div className="main-content-body">
        <Button onClick={createNewLeaveType} className="joyride-new-leave-type" data-qa='create-new-leave-type'>
          <IntlMessages id="leaveTypes.createNewLeaveType" />
        </Button>
        <div className="leave-types-body">
          <Table
            loading={loading}
            dataSource={leaveTypesList}
            columns={leaveTypesColumns}
            rowKey="index"
            components={{
              body: {
                wrapper: DraggableContainer,
                row: DragableBodyRow,
              },
            }}
            pagination={false}
          />
          {isCreateLeaveTypeAndAssignPolicyWizardVisible &&
            <CreateLeaveTypeForm
              locations={locations}
              visibleModal={isCreateLeaveTypeAndAssignPolicyWizardVisible}
              handleCancel={handleCancel}
              setModalVisibility={setIsCreateLeaveTypeAndAssignPolicyWizardVisible}
              setAssigninPolicyCorrelationId={setAssigningPolicyCorrelationId}
              setShouldReloadOnActionNotificationsChange={setShouldReloadOnActionNotificationsChange}
              hourlyLeaveAccounting={Boolean(authCompany?.hourlyLeaveAccounting)}
              checkForLeaveTypeSimilarity={handleCheckForLeaveTypeSimilarity}
              similarLeaveType={similarLeaveType}
              isCheckForLeaveTypeSimilarityLoading={isCheckForLeaveTypeSimilarityLoading}
            />
          }
          {isAssigningLeaveTypeToLocationModalVisible &&
            <CreateLeaveTypeForm
              locations={notAssignedLocations}
              visibleModal={isAssigningLeaveTypeToLocationModalVisible}
              handleCancel={() => {
                setIsAssigningLeaveTypeToLocationModalVisible(false)
                setNotAssignedLocations([])
              }}
              setModalVisibility={setIsAssigningLeaveTypeToLocationModalVisible}
              setAssigninPolicyCorrelationId={setAssigningPolicyCorrelationId}
              setShouldReloadOnActionNotificationsChange={setShouldReloadOnActionNotificationsChange}
              leaveType={{
                id: leaveTypeId,
                name: selectedLeaveTypeName,
              }}
              hourlyLeaveAccounting={Boolean(authCompany?.hourlyLeaveAccounting)}
            />
          }
          {isUpdateLeaveTypeFormVisible &&
            <EditLeaveTypeForm
              visibleModal={isUpdateLeaveTypeFormVisible}
              handleCancel={() => { setUpdateLeaveTypeFormVisibility(false); setSelectedLeaveType(undefined) }}
              loading={updateLoading}
              leaveType={selectedLeaveType}
              onSave={onUpdateLeaveType}
              platform={platform}
            />
          }
        </div>
      </div>
    </div>
  )
}

export default LeaveTypesPage
