import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { Breadcrumb } from 'antd'
import uniqBy from 'lodash/uniqBy'
import { API, graphqlOperation } from 'aws-amplify'

import * as logger from '../../../services/logger'
import { getAutomationFormApproverData, getAutomationFormData, getPurchasedAddons, getUsersForNotifictions } from '../../../graphql/custom-queries'
import { useAppSelector } from '../../../store/hooks'
import { selectAuthCompanySlice } from '../../../store/auth-company-slice'
import { roleAdmin, roleApprover, selectAuthUserSlice } from '../../../store/auth-user-slice'
import { useShouldEnableFeatures } from '../../../store/use-should-enable-features'
import { notificationStore } from '../../../context/notificationsContext/store'
import { getExtendedAddons } from '../common/addons'
import IntlMessages from '../../../util/IntlMessages'

import BlackoutPeriodForm from '../../../components/blackout-period-form'
import SeniorityEntitlementForm from '../../../components/seniority-entitlement-form'
import EntitlementByRoleForm from '../../../components/entitlement-by-role-form'

import { IGetTeamsShort } from '../../../types/teams'
import { 
  IAutomations,
  IGetAutomationApproverData,
  IGetAutomationFormData,
  IGetPurchasedAddonsData,
  IGetUsersForNotifications,
  ILeaveTypeListShort
} from '../../../types/custom-queries'
import { ITeamShort } from '@vacationtracker/shared/types/team'
import { IData, IPageParams } from '../../../types/data'
import { IAddon } from '@vacationtracker/shared/types/addons'
import { IGetLocationsShort } from '../../../types/locations'
import { IGetLabelsShort } from '../../../types/labels'
import { FeatureFlagEnum } from '@vacationtracker/shared/types/feature-flags'
import { FrontendUrls } from '../../../types/urls'
import { IFilter } from '@vacationtracker/shared/types/filter'
import { SubscriptionPlanEnum } from '@vacationtracker/shared/types/company'
import CircularProgress from '../../../components/circular-progress'
import ProbationPeriodForm from '../../../components/probation-period-form'

const AddOnFormPage = ({ match }: IPageParams): React.ReactElement => {
  const history = useHistory()
  const { formatMessage } = useIntl()
  const { actionNotifications } = useContext(notificationStore)

  const { authCompany } = useAppSelector(selectAuthCompanySlice)
  const { authUser } = useAppSelector(selectAuthUserSlice)
  const amIAdmin = useSelector(roleAdmin)
  const amIApprover = useSelector(roleApprover)

  const addOnsEventTypes = getExtendedAddons(formatMessage)

  const [isLoading, setIsLoading] = useState(true)
  const [teams, setTeams] = useState<IGetTeamsShort[] | ITeamShort[]>([])
  const [locations, setLocations] = useState<IGetLocationsShort[]>([])
  const [seniorityEntitlementsLocations, setSeniorityEntitlementsLocations] = useState<IGetLocationsShort[]>([])
  const [entitlementsByRoleLabels, setEntitlementsByRoleLabels] = useState<IGetLabelsShort[]>([])
  const [leaveTypes, setLeaveTypes] = useState<ILeaveTypeListShort[]>([])
  const [labels, setLabels] = useState<IGetLabelsShort[]>([])
  const [totalUsers, setTotalUsers] = useState<number>(0)
  const [subscribedAddons, setSubscribedAddons] = useState<IAddon[]>([])

  const [filter, setFilter] = useState<IFilter>({
    locationIds: [],
    teamIds: [],
    labelIds: [],
  })

  const [selectedAddOnType, setSelectedAddOnType] = useState<string | null>(() => {
    if (match?.params?.addonType) {
      const automation = addOnsEventTypes.find(automation => {
        return automation.url === match.params.addonType.replace(/_/g, '-')
      })
      return automation?.type as string
    }
    return null
  })
  const [automationId] = useState<string | undefined>(() => {
    if (match?.params?.id) {
      return match.params.id
    }
    return undefined
  })

  const shouldEnableLabels = useShouldEnableFeatures(SubscriptionPlanEnum.complete, FeatureFlagEnum.labels)

  useEffect(() => {
    if (isLoading) {
      fetchData()
    }
  }, [isLoading])

  useEffect(() => {
    fetchData()
  }, [actionNotifications])

  useEffect(() => {
    // Allow read only access to automation ENTITLEMENT_BY_ROLE for core plan
    if (!isLoading && selectedAddOnType === 'ENTITLEMENT_BY_ROLE' && authCompany?.plan === SubscriptionPlanEnum.core) {
      return
    }
    // If you open a page directly from a URL and we don't have that automation, we have to redirect to page creation
    if (!isLoading && selectedAddOnType && !subscribedAddons.find(automation => automation.type === selectedAddOnType)) {
      setSelectedAddOnType(null)
      history.push(`${FrontendUrls.addOns}`)
    }
  }, [isLoading])

  const getTotalUsers = async (locationIds: string[] = [], teamIds: string[] = [], labelIds: string[] = []) => {
    const usersResponse = await API.graphql(graphqlOperation(getUsersForNotifictions, { locationIds, teamIds, labelIds })) as IData<IGetUsersForNotifications>
    setTotalUsers(usersResponse.data.getUsersFiltered.totalUsers)
  }

  const fetchData = async () => {
    try {
      let response
      if (amIAdmin) {
        response = await API.graphql(graphqlOperation(getAutomationFormData)) as IData<IGetAutomationFormData>
        setTeams(response.data.getTeamList as IGetTeamsShort[])
      }
      if (amIApprover) {
        response = await API.graphql(graphqlOperation(getAutomationFormApproverData, { id: authUser.id } )) as IData<IGetAutomationApproverData>
        setTeams(response.data.getUser.approverToTeams as ITeamShort[])
      }

      setLocations(response.data.getLocationList as IGetLocationsShort[])
      setLabels(response.data.getLabels as IGetLabelsShort[])
      setLeaveTypes(response.data.getLeaveTypeList.sort((a, b) => a.position < b.position ? -1 : 1) as ILeaveTypeListShort[])

      if (!automationId) {
        getTotalUsers()
      }

      const responseAddonsStatus = await API.graphql(graphqlOperation(getPurchasedAddons)) as IData<IGetPurchasedAddonsData>

      const subscribedAddons = [
        ...(responseAddonsStatus.data?.getPurchasedAddons?.addons || []),
        ...(responseAddonsStatus.data?.getPurchasedAddons?.automations || []),
        ...(responseAddonsStatus.data?.getPurchasedAddons?.integrations || []),
      ]
      setSubscribedAddons(subscribedAddons as IAddon[])
      
      const seniorityEntitlementsData = response.data.getAutomations.filter(automation => automation.automationType === 'SENIORITY_ENTITLEMENT') as IAutomations[]
      let optionLocations: IGetLocationsShort[] = []

      if (seniorityEntitlementsData.length === 0) {
        optionLocations = response.data.getLocationList as IGetLocationsShort[]
      } else {
        optionLocations = (response.data.getLocationList as IGetLocationsShort[]).filter(location => {
          return !seniorityEntitlementsData.some(automation =>
            automation.locations && automation.locations.includes(location.id)
          )
        })
      }
      setSeniorityEntitlementsLocations(uniqBy(optionLocations, 'id'))

      const entitlementsByRoleData = response.data.getAutomations.filter(automation => automation.automationType === 'ENTITLEMENT_BY_ROLE') as IAutomations[]
      let optionLabels: IGetLabelsShort[] = []

      if (entitlementsByRoleData.length === 0) {
        optionLabels = response.data.getLabels
      } else {
        optionLabels = response.data.getLabels.filter(label => {
          return entitlementsByRoleData.every(automation => automation.labelId !== label.id)
        })
      }
      setEntitlementsByRoleLabels(uniqBy(optionLabels, 'id'))

      setIsLoading(false)

      // Allow read only access to automation ENTITLEMENT_BY_ROLE for core plan
      if (selectedAddOnType === 'ENTITLEMENT_BY_ROLE' && authCompany?.plan === SubscriptionPlanEnum.core) {
        return
      }

    } catch (err) {
      logger.error('ERROR FETCH DATA', err)
    }
  }

  const handleFilter = (filterArg: IFilter) => {
    setFilter(filterArg)
    getTotalUsers(filterArg.locationIds, filterArg.teamIds, filterArg.labelIds)
  }

  const backToAddons = () => {
    history.push(`${FrontendUrls.addOns}/${selectedAddOnType?.toLowerCase().replace(/_/g, '-')}/view`)
  }

  return (
    <>
      {isLoading ?
        <CircularProgress /> :
        <div className='main-content'>
          <div className="main-content-header">
            <div className="main-content-header-title">
              <IntlMessages id={`automations.${selectedAddOnType}`} />
            </div>
            <div className="main-content-header-breadcrumb">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/app/dashboard"><IntlMessages id="sidebar.dashboard" /></Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to="/app/add-ons"><IntlMessages id="sidebar.addons" /></Link>
                </Breadcrumb.Item>
                {selectedAddOnType && (match.path.includes('create') || match.path.includes('edit')) ?
                  <Breadcrumb.Item>
                    <Link to={`/app/add-ons/${selectedAddOnType?.toLowerCase().replace(/_/g, '-')}/view`}>
                      <IntlMessages id={`automations.${selectedAddOnType}`} />
                    </Link>
                  </Breadcrumb.Item> :
                  <Breadcrumb.Item>
                    <IntlMessages id={`automations.${selectedAddOnType}`} />
                  </Breadcrumb.Item>
                }
                {selectedAddOnType && (match.path.includes('create') || match.path.includes('edit')) && 
                  <Breadcrumb.Item>
                    <IntlMessages id={`app.${match.path.includes('create') ? 'create' : 'edit'}`} />
                  </Breadcrumb.Item>
                }
              </Breadcrumb>
            </div>
          </div>
          <div className="main-content-body">
            {selectedAddOnType === 'BLACKOUT_PERIOD' &&
              <BlackoutPeriodForm
                automationId={automationId}
                teams={teams}
                locations={locations}
                leaveTypes={leaveTypes}
                totalUsers={totalUsers}
                labels={labels}
                filter={filter}
                handleFilter={handleFilter}
                shouldEnableLabels={shouldEnableLabels}
                backToAddons={backToAddons}
                amIAdmin={amIAdmin}
                status={subscribedAddons.find(addon => addon.type === 'BLACKOUT_PERIOD')?.status}
              />
            }
            {selectedAddOnType === 'SENIORITY_ENTITLEMENT' &&
              <SeniorityEntitlementForm
                automationId={automationId}
                optionLocations={seniorityEntitlementsLocations}
                locations={locations}
                leaveTypes={leaveTypes}
                backToAddons={backToAddons}
                hourlyLeaveAccounting={Boolean(authCompany?.hourlyLeaveAccounting)}
                status={subscribedAddons.find(addon => addon.type === 'SENIORITY_ENTITLEMENT')?.status}
                amIAdmin={amIAdmin}
              />
            }
            {selectedAddOnType === 'ENTITLEMENT_BY_ROLE' &&
              <EntitlementByRoleForm
                automationId={automationId}
                labels={labels}
                optionLabels={entitlementsByRoleLabels}
                leaveTypes={leaveTypes}
                backToAutomations={() => history.push(`${FrontendUrls.addOns}`)}
                isCorePlan={authCompany?.plan === SubscriptionPlanEnum.core}
                hourlyLeaveAccounting={Boolean(authCompany?.hourlyLeaveAccounting)}
                shouldEnableLabels={shouldEnableLabels}
                status={subscribedAddons.find(addon => addon.type === 'ENTITLEMENT_BY_ROLE')?.status}
                amIAdmin={amIAdmin}
              />
            }
            {selectedAddOnType === 'PROBATION_PERIOD' &&
              <ProbationPeriodForm
                automationId={automationId}
                teams={teams}
                locations={locations}
                leaveTypes={leaveTypes}
                labels={labels}
                filter={filter}
                handleFilter={handleFilter}
                shouldEnableLabels={shouldEnableLabels}
                backToAddons={backToAddons}
                amIAdmin={amIAdmin}
                status={subscribedAddons.find(addon => addon.type === 'PROBATION_PERIOD')?.status}
              />
            }
          </div>
        </div>
      }
    </>
  )
}

export default AddOnFormPage