/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router'
import { Form, Row, Col, Button } from 'antd'
import { omit } from 'lodash'

import * as logger from '../../../services/logger'
import IntlMessages from '../../../util/IntlMessages'
import countries, { ICountry } from '@vacationtracker/shared/data/countries'
import aboutUsSurvey from '@vacationtracker/shared/data/survey'
import { track, trackConversionEvent } from '../../../services/analytics/analytics'
import CreateCompanyForm from '../../../components/create-company-form'

import { actions } from '../actions'
import { setCrispSessionParams } from '../helpers'
import { ICreateCompany, ICreateCompanyState, SignupVariation } from '../types'
import { initialState } from '../reducer'
import { FrontendUrls } from '../../../types/urls'
import { Platform } from '@vacationtracker/shared/types/core-event'
import { SignupVariation as SignupVariationType } from '@vacationtracker/shared/types/company'

interface ICompanyDetailsProps {
  state: ICreateCompanyState
  variation: SignupVariation
  dispatch: Function
  onStateChange: Function
  signupVariationType: SignupVariationType
}

export const CompanyDetails = ({ state, variation, dispatch, onStateChange, signupVariationType }: ICompanyDetailsProps): React.ReactElement => {
  const [form] = Form.useForm()
  const history = useHistory()
  const { formatMessage } = useIntl()
  const [ selectedCountry, setSelectedCountry ] = useState<ICountry|undefined>()
  const [ hasUnlimitedDays, setHasUnlimitedDays ] = useState(state.createCompany.hasUnlimitedDays)
  const [ createCompanySubmitLoading, setCreateCompanySubmitLoading ] = useState(false)
  const [ agreeToTerms, setAgreeToTerms ] = useState(false)
  const [ platform, setPlatform ] = useState<string | undefined>()

  const { createUser, createCompany, skipSelectPlan } = state
  const plan = createCompany.plan

  useEffect(() => {
    setAgreeToTerms(state.agreeToTerms)
    setHasUnlimitedDays(createCompany.hasUnlimitedDays)

    if (createCompany) {
      form.setFieldsValue({
        contactEmail: createCompany.contactEmail,
        name: createCompany.name,
        country: createCompany.country,
        state: createCompany.state,
        daysPerYear: createCompany.daysPerYear,
        hasUnlimitedDays: createCompany.hasUnlimitedDays,
        agreeToTerms: state.agreeToTerms,
        surveyQuestionMainOption: createCompany.surveyQuestionMainOption,
        ...(createCompany.surveyQuestionSubOption ? { surveyQuestionSubOption: createCompany.surveyQuestionSubOption } : {}),
      })
      if (createCompany.country) {
        setSelectedCountry(countries.find(country => country.iso === createCompany.country))
      }
      if (createCompany.platform && !platform) {
        setPlatform(createCompany.platform)
      }
    }
  }, [createCompany])

  useEffect(() => {
    if (createUser.platform === 'email') {
      form.setFieldsValue({
        userName: createUser.userName,
        password: createUser.userPassword,
        repeatPassword: createUser.userRepeatPassword,
      })
    }
  }, [createUser])

  useEffect(() => {
    if (createUser.platform && !platform) {
      setPlatform(createUser.platform)
    }
  }, [createUser])

  useEffect(() => {
    if (platform) {
      track('SIGNUP_COMPANY_CREATE_FORM_VIEWED', {
        platform,
        variation,
        plan,
        email: createCompany.contactEmail,
        timestamp: new Date().toISOString(),
        source: 'app',
        status: 'step1',
      })
      trackConversionEvent('SIGNUP_STARTED')
      setCrispSessionParams('step 1: company details')
    }
  }, [platform])

  const onCountryChange = (countryId) => {
    track('SIGNUP_COMPANY_CREATE_FORM_LOCATION_SET', {
      platform,
      variation,
      country: countryId,
      plan,
      email: createCompany.contactEmail,
    })
    setSelectedCountry(countries.find(country => country.iso === countryId))
    form.setFieldsValue({ state: '' })
  }

  const onUnlimitedPTOChange = () => {
    track('SIGNUP_COMPANY_CREATE_FORM_PTO_UNLIMITED', {
      platform,
      variation,
      plan,
      email: createCompany.contactEmail,
    })
    setHasUnlimitedDays(!hasUnlimitedDays)
  }

  const onAgreeToTermsChange = () => {
    setAgreeToTerms(!agreeToTerms)
  }

  const onChangeDaysPerYear = (days) => {
    form.setFieldsValue({
      daysPerYear: Math.round(days),
    })
  }

  const validation = () => {
    if (agreeToTerms) {
      track('SIGNUP_COMPANY_CREATE_FORM_TOS_ACCEPTED', {
        platform,
        variation,
        plan,
        email: createCompany.contactEmail,
      })
      return Promise.resolve()
    }
    return Promise.reject(new Error(formatMessage({ id: 'createCompany.steps.companyForm.agreeToTerms.error' })))
  }

  const initialValues = {
    contactEmail: state.createCompany.contactEmail,
    name: state.createCompany.name,
    country: state.createCompany.country,
    state: state.createCompany.state,
    daysPerYear: state.createCompany.daysPerYear,
    hasUnlimitedDays: state.createCompany.hasUnlimitedDays,
    agreeToTerms: state.agreeToTerms,
  }

  const handleCreateCompany = async () => {
    try {
      const formValues = await form.validateFields()
      const survey = getAboutUsSurveyData()
      const values = {...formValues, ...survey}

      localStorage.setItem('vtCreateCompany', JSON.stringify(omit(values, ['agreeToTerms', 'password', 'repeatPassword', 'userName']) as ICreateCompany))
      const createUserRaw = localStorage.getItem('vtCreateUser')
      const userCreator = createUserRaw && JSON.parse(createUserRaw)
      if (userCreator?.platform === 'email') {
        const vtCreateUser = {
          name: values.userName.trimEnd(),
          userName: values.userName,
          userPassword: values.password,
          userRepeatPassword: values.repeatPassword,
          userEmail: userCreator?.userEmail,
          platform: userCreator?.platform,
          mail: userCreator?.userEmail,
        }
        dispatch(actions.setCreateUser(vtCreateUser))
        localStorage.setItem('vtCreateUser', JSON.stringify(omit(vtCreateUser, ['password', 'repeatPassword'])))
      }
      dispatch(actions.setCreateCompanyDetails(values))
      dispatch(actions.onNextStepChange())
      if (values.daysPerYear !== initialState.createCompany.daysPerYear) {
        track('SIGNUP_COMPANY_CREATE_FORM_PTO_QUOTA_CHANGED', {
          platform,
          variation,
          plan,
          email: createCompany.contactEmail,
        })
      }
      track('SIGNUP_COMPANY_CREATE_FORM_COMPLETED', {
        platform,
        variation,
        plan,
        email: createCompany.contactEmail,
      })
      if (variation === 'ACCOUNT_USERS_PLAN') {
        setCreateCompanySubmitLoading(true)
        history.push('/create-company/step2')
      } else if (skipSelectPlan) {
        history.push('/create-company/step2')
      } else {
        history.push('/create-company/step3')
      }
    } catch(error) {
      setCreateCompanySubmitLoading(false)
      logger.warning('ERROR HANDLE CREATE COMPANY', error)
    }
  }

  const getAboutUsSurveyData = () => {
    const surveyOption = form.getFieldValue('surveyQuestionOption')
    const surveySubOption = form.getFieldValue('surveyQuestionSuboption')
    const surveyText = form.getFieldValue(`surveyQuestionSpecifyText-${surveyOption}`) || form.getFieldValue(`surveyQuestionSpecifyText-${surveySubOption}`)
    if (!surveyOption) {
      return ''
    }
    try {
      const option = aboutUsSurvey.questions[0].options?.find(option => option.id === surveyOption)
      const subOption = option?.subQuestion?.options?.find(option => option.id === surveySubOption)
      const data = {
        surveyQuestionMainOption: option?.name,
        ...((subOption?.name || surveyText) ? {surveyQuestionSubOption: subOption?.name || surveyText} : {}),
      }

      form.setFieldsValue(data)
      return data
    } catch (e) {
      return ''
    }
  }

  const backToConnect = () => {
    track('SIGNUP_COMPANY_CREATE_FORM_BACK', {
      platform,
      variation,
      plan,
      email: createCompany.contactEmail,
    })
    if (variation === 'ACCOUNT_USERS_PLAN' || skipSelectPlan) {
      localStorage.removeItem('vtCreateUser')
      localStorage.removeItem('vtCreateCompany')
      localStorage.removeItem('vtSelectedUsers')

      onStateChange('signUp')
      history.push(FrontendUrls.signup)
    } else {
      dispatch(actions.onPrevStepChange())
      history.push('/create-company/step1')
    }
  }

  const onBlurContactEmail = () => {
    const email = form.getFieldValue('contactEmail')
    track('SIGNUP_COMPANY_CREATE_FORM_EMAIL_SET', {
      platform,
      variation,
      plan,
      email,
    })
  }

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  }

  const xxl = { span: 14, offset: 5 }
  const xl = { span: 16, offset: 4 }
  const lg = { span: 20, offset: 2 }
  const md = { span: 22, offset: 1 }
  const xs = { span: 24, offset: 0}

  return (
    <Form
      form={form}
      layout="horizontal"
      onFinish={handleCreateCompany}
      initialValues={initialValues}
      {...layout}
    >
      <CreateCompanyForm
        onBlurContactEmail={onBlurContactEmail}
        onCountryChange={onCountryChange}
        selectedCountry={selectedCountry}
        hasUnlimitedDays={hasUnlimitedDays}
        onChangeDaysPerYear={onChangeDaysPerYear}
        onUnlimitedPTOChange={onUnlimitedPTOChange}
        validation={validation}
        onAgreeToTermsChange={onAgreeToTermsChange}
        platform={platform as Platform}
        form={form}
        showLeaveQuotas={signupVariationType === 'A'}
      />
      <Row>
        <Col xxl={xxl} xl={xl} lg={lg} md={md} xs={xs} style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button onClick={backToConnect}>
            {(variation === 'ACCOUNT_USERS_PLAN' || skipSelectPlan) ?
              <IntlMessages id="app.cancel" /> :
              <IntlMessages id="app.back" />
            }
          </Button>
          <Button type="primary" loading={createCompanySubmitLoading} htmlType="submit" data-qa="continue">
            <IntlMessages id="createCompany.steps.next" />
          </Button>
        </Col>
      </Row>
    </Form>
  )
}