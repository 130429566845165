import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { notification } from 'antd'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { PostHogProvider } from 'posthog-js/react'
import NextApp from './NextApp'
import * as serviceWorker from './serviceWorker'
import { NotificationsProvider } from './context/notificationsContext/store'
import { ThemeProvider } from './context/themeContext'
import { store } from './store/configure'

import './index.css'
import { PostHogConfig } from 'posthog-js'

if (process.env.REACT_APP_ENVIRONMENT === 'production' &&  process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    release: process.env.REACT_APP_SENTRY_VERSION,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
  })
}

notification.config({
  top: 80,
})

const options: Partial<PostHogConfig> = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
  disable_session_recording: true,
  person_profiles: 'identified_only',
  autocapture: false,
  session_recording: {
    maskAllInputs: false,
    maskInputOptions: {
      password: true,
    },
  },
}

const app = (
  <PostHogProvider
    apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
    options={options}
  >
    <NotificationsProvider>
      <ThemeProvider>
        <Provider store={store}>
          <NextApp />
        </Provider>
      </ThemeProvider>
    </NotificationsProvider>
  </PostHogProvider>
)

ReactDOM.render(process.env.REACT_ENABLE_STRICT_MODE ? <React.StrictMode>{app}</React.StrictMode> : app, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
