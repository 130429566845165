/* eslint-disable */

export const getLocations = /* GraphQL */ `
  query GetLocations {
    getLocationList {
      id
      name
      timestamp
      users {
        id
        name
        imageUrl
      }
      isDefault
    }
  }
`

export const getLocationById = /* GraphQL */ `
  query GetLocationById($id: String!) {
    getLocation(id: $id) {
      id
      name
      timestamp
      users {
        id
        name
        imageUrl
      }
      isDefault
      workWeek
      workHours
      yearStartMonth
      yearStartDay
      firstDayOfWeek
      resetQuotas
      rolloverExpiryMonth
      rolloverExpiryDay
      rolloverExpiryAfterDays
      rolloverNeverExpireDays
      timezone
    }
  }
`

export const getConnectedCalendars = /* GraphQL */ `
  query GetConnectedCalendar($userId: String!) {
    getConnectedGooogleCalendar(userId: $userId) {
      calendarId
      timezone
      type
      accessToken
      refreshToken
      error {
        code
      }
    }
    getConnectedOutlookCalendar(userId: $userId) {
      calendarId
      timezone
      type
      accessToken
      refreshToken
      error {
        code
      }
    }
  }
`

export const getOutOfOfficeSettings = /* GraphQL */ `
  query GetOutOfOfficeSettings($userId: String!) {
    getOutOfOfficeSettings(userId: $userId) {
      oooPlatform
      snoozedLeaveTypes
      active
      refreshToken
      msTenantId
      googleUserId
      timezone
      outlookEmail
    }
  }
`

export const getDataForOutOfOffice = /* GraphQL */ `
  query GetDataForOutOfOffice($userId: String!, $leaveRequestId: String!) {
    getOutOfOfficeSettings(userId: $userId) {
      oooPlatform
      snoozedLeaveTypes
      active
      refreshToken
      msTenantId
      googleUserId
      timezone
      outlookEmail
    }
    getLeaveRequest(id: $leaveRequestId) {
      id
      user {
        id
        name
        imageUrl
        location {
          id
          name
        }
        team {
          id
          name
        }
      }
      status
      leavePolicyId
      leaveType {
        id
        name
      }
      startDate
      endDate
      isPartDay
      autoApproved
      partDayStartHour
      partDayEndHour
      reason
      workingDays
      daysList {
        day
        leaveHours
        workingHoursInDay
      }
      calendarDays
      totalThisYear
      totalNextYear
      statusReason
      cancelledBeforeReview
      approver {
        id
        name
      }
      isEdited
    }
  }
`

export const getLocationByIdLeaveTypesAllLeavePolicies = /* GraphQL */ `
  query GetLocationByIdLeaveTypesAllLeavePolicies($id: String!) {
    getLocation(id: $id) {
      id
      name
      timestamp
      users {
        id
        name
        imageUrl
      }
      holidays {
        year
        holidays {
          name
          date
          multiDayId
          isHalfDay
        }
      }
      holidaysAutoImport {
        holidays {
          name
          date
        }
      }
      workWeek
      workHours
      yearStartMonth
      yearStartDay
      isDefault
      firstDayOfWeek
      resetQuotas
      rolloverExpiryMonth
      rolloverExpiryDay
      rolloverExpiryAfterDays
      rolloverNeverExpireDays
      timezone
      leavePolicies {
        id
        locationId
        isActive
        daysPerYear
        maxRolloverDays
        isApprovalRequired
        hasUnlimitedDays
        allowHalfDays
        shortestLeaveInterval
        hideLeaveType
        isReasonRequired
        negativeBallanceAllowed
        accrualType
        firstEarningDate
        accrualCapRate
        accrualPeriodStart
        accrualsLocked
        allowLeaveInPast
        broughtForwardExpirationSettings {
          enabled
          day
          month
          afterDays
        }
        enableRolloverExpiry
        rolloverExpiryMonth
        rolloverExpiryDay
        rolloverExpiryAfterDays
        allowAdvanceAccrualUsage
        pauseAccrualsWhenUserIsDeactivated
        toil
        toilShortestInterval
        toilRequestsAllowedForUsers
        toilExpiration
        toilExpirationDate
        toilExpirationMonths
        leaveType {
          id
          name
          isActive
          position
          deleted
        }
      }
    }
    getLeaveTypeList {
      id
      name
      isActive
      color
      position
    }
    getLocationList {
      id
      name
      leavePolicies {
        id
        locationId
        isActive
        daysPerYear
        maxRolloverDays
        allowLeaveInPast
        accrualCapRate
        isApprovalRequired
        hasUnlimitedDays
        allowHalfDays
        shortestLeaveInterval
        hideLeaveType
        isReasonRequired
        negativeBallanceAllowed
        accrualType
        firstEarningDate
        accrualPeriodStart
        enableRolloverExpiry
        rolloverExpiryMonth
        rolloverExpiryDay
        rolloverExpiryAfterDays
        broughtForwardExpirationSettings {
          enabled
          day
          month
          afterDays
        }
        allowAdvanceAccrualUsage
        pauseAccrualsWhenUserIsDeactivated
        toil
        toilShortestInterval
        toilRequestsAllowedForUsers
        toilExpiration
        toilExpirationDate
        toilExpirationMonths
        leaveType {
          id
          name
          isActive
          position
          deleted
        }
      }
    }
  }
`

export const getAllUserListShortPaginated = /* GraphQL */ `
  query GetAllUserListShortPaginated($limit: Int, $nextToken: String) {
    getAllUserListPaginated(limit: $limit, nextToken: $nextToken) {
      users {
        id
        name
        status
      }
      nextToken
    }
  }
`

export const getUsersFiltered = /* GraphQL */ `
  query GetUsersFilteredCustom(
     $status: String!
     $name: String
     $locationIds: [String!]
     $teamIds: [String!]
     $labelIds: [String!]
     $limit: Int = 200
     $nextToken: String
     $asc: Boolean = true
  ) {
    getUsersFiltered(
      status: $status
      name: $name
      locationIds: $locationIds
      teamIds: $teamIds
      labelIds: $labelIds
      limit: $limit
      nextToken: $nextToken
      asc: $asc
    ) {
      users {
        id
        name
        role
        status
        startDate
        email
        platform
        imageUrl
        timestamp
        labels {
          id
          name
          color
        }
        location {
          id
          name
        }
        team {
          id
          name
        }
      }
      nextToken
    }
  }
`

export const getAllUsersSlim = /* GraphQL */ `
  query GetAllUsersSlim(
     $status: String!
     $limit: Int
     $nextToken: String
     $asc: Boolean = true
  ) {
    getUsersFiltered(
      status: $status
      locationIds: []
      teamIds: []
      labelIds: []
      limit: $limit
      nextToken: $nextToken
      asc: $asc
    ) {
      users {
        id
        name
        status
        email
        locationId
        teamId
      }
      nextToken
    }
  }
`

//this query is minimalized to only fetch the data needed for the import page
//be careful when changing this query, as it can cause db performance issues
export const getImportPageValidationData = /* GraphQL */ `
  query GetImportPageValidationData {
    getTeamList {
      id
      name
      approvers {
        id
      }
    }
    getLeaveTypeList {
      id
      name
      isActive
    }
    getLocationList {
      id
      name
      workHours
      leavePolicies {
        id
        isReasonRequired
        isActive
        shortestLeaveInterval
      }
    }
  }
`

export const getTeams = /* GraphQL */ `
  query GetTeams {
    getTeamList {
      id
      name
      timestamp
      parentId
      users {
        id
        name
        imageUrl
      }
      approvers {
        id
        name
        imageUrl
      }
      isDefault
    }
  }
`

export const getTeamById = /* GraphQL */ `
  query GetTeamById($id: String!) {
    getTeam(id: $id) {
      id
      name
      timestamp
      parentId
      users {
        id
        name
        imageUrl
      }
      approvers {
        id
        name
        imageUrl
      }
      isDefault
    }
  }
`

// If you do not have a nextToken, use the "NONE" value, as we need a value for cache eviction
export const getCalendarData = /* GraphQL */ `
  query GetCalendarData(
    $dateStart: String!
    $dateEnd: String!
    $status: String!
    $limit: Int!
    $nextToken: String!
  ) {
    getLeaveRequestByDate(
      dateStart: $dateStart
      dateEnd: $dateEnd
      status: $status
      limit: $limit
      nextToken: $nextToken
    ) {
      nextToken
      leaveRequests {
        id
        user {
          id
          name
          locationId
          imageUrl
          teamId
          labels {
            id
            name
            color
          }
        }
        startDate
        endDate
        isPartDay
        partDayStartHour
        partDayEndHour
        reason
        teamId
        locationId
        leaveType {
          id
          isActive
          name
          color
          deleted
          position
          leavePolicies {
            locationId
            hideLeaveType
          }
        }
      }
    }
  }
`
export const getWallchartData = /* GraphQL */ `
  query GetWallchartData(
    $fromDate: String!
    $toDate: String!
    $limit: Int
    $lastId: String
    $locationIds: [String]
    $teamIds: [String]
    $labelIds: [String]
  ) {
    getLeaveRequestsByUsers(
      fromDate: $fromDate
      toDate: $toDate
      limit: $limit
      lastId: $lastId
      locationIds: $locationIds
      teamIds: $teamIds
      labelIds: $labelIds
    ) {
      user {
        id
        name
        locationId
        imageUrl
        teamId
        workWeek
        labels {
          id
          name
          color
        }
      }
      leaves {
        id
        startDate
        status
        endDate
        isPartDay
        partDayStartHour
        partDayEndHour
        reason
        teamId
        locationId
        workingDays
        daysList {
          day
          leaveHours
          workingHoursInDay
        }
        leaveType {
          id
          isActive
          name
          color
          deleted
          position
          leavePolicies {
            locationId
            hideLeaveType
          }
        }
      }
    }
  }
  `

export const getCalendarInfo = /* GraphQL */ `
  query GetCalendarInfo(
    $id: String!
  ) {
    getUser(id: $id) {
      id
      workWeek
      approverTo {
        id
      }
    }
    getLocationList {
      id
      name
      workWeek
      leavePolicies {
        hideLeaveType
        leaveType {
          id
          name
          isActive
          color
          deleted
          position
        }
      }
      timezone
      holidays {
        year
        holidays {
          name
          date
          multiDayId
          isHalfDay
        }
      }
    }
    getTeamListV2 {
      id
      name
      isDefault
      parentId
    }
    getLabels {
      id
      name
      color
    }
    getLeaveTypeList {
      id
      name
      isActive
      color
    }
  }
`

export const getUsersForApprover = /* GraphQL */ `
  query GetUsersForApprover($id: String!) {
    getUser(id: $id) {
      id
      approverTo {
        id
        name
        role
      }
    }
  }
`

export const getDataForManageLicences = /* GraphQL */ `
  query GetDataForManageLicences($status: String!) {
    getUsersByStatus(status: $status) {
      id
      name
      isAdmin
      email
    }
     getCompany {
      id
      billing {
        quantity
      }
    }
  }
`

export const getUsersForAdmin = /* GraphQL */ `
  query GetUsersForAdmin($status: String!) {
    getUsersByStatus(status: $status) {
      id
      name
      locale
      role
      leaveDays {
        yearStart
        yearEnd
        leaveTypes {
          leavePolicyId
          leaveTypeId
          leaveTypeName
          isActive
          position
          color
          accrualType
          currentDays
          earnedDays
          earnedDaysWithoutRollover
          defaultDaysPerYear
          expiredDays
          hasUnlimitedDays
          rolloverFromPreviousYear
          usedDays
          seniorityEntitlementDaysForYear
          baseQuota
          toilDays
          toilDaysLapsed
          quota
          senioirtyEntitlementExists
          scheduledDays
          userLeaveTypeOverride
          ignoreRolloverFromPreviousYear
          allowHalfDays
          shortestLeaveInterval
          rolloverExpiryDate
          firstEarningDate
          accrualPeriodStart
        }
      }
      location {
        id
        timezone
        yearStartDay
        leavePolicies(onlyActive: true) {
          id
          name
          isActive
          allowHalfDays
          shortestLeaveInterval
          isReasonRequired
          allowLeaveInPast
          toil
          toilShortestInterval
          toilRequestsAllowedForUsers
          allowAdvanceAccrualUsage
          leaveType {
            id
            name
            position
            deleted
            isActive
            calendarStatus
          }
        }
      }
      team {
        id
        name
        approvers {
          id
          name
        }
      }
    }
  }
`

export const getUsersForAdminWithPagination = /* GraphQL */ `
  query GetUsersForAdminWithPagination(
    $limit: Int = 500
    $nextToken: String
  ) {
    getActiveUsersWithPagination(
      limit: $limit
      nextToken: $nextToken
    ) {
      nextToken
      limit
      users {
        id
        name
        role
      }
    }
  }
`

export const getSuggestedSubstituteApprovers = /* GraphQL */ `
  query getSuggestedSubstituteApprovers($limit: Int = 500, $nextToken: String, $upcomingLeavesDate: String) {
    getActiveUsersWithPagination(limit: $limit, nextToken: $nextToken) {
      nextToken
      limit
      users {
        id
        name
        role
        upcomingLeaves(date: $upcomingLeavesDate) {
          id
          status
          startDate
          endDate
          isPartDay
          partDayStartHour
          partDayEndHour
          leaveType {
            id
            name
          }
        }
        teamId
      }
    }
  }
`

export const getUserForLeaveRequest = /* GraphQL */ `
  query GetUserForLeaveRequest($id: String!) {
    getUser(id: $id) {
      id
      name
      locale
      role
      workHours
      leaveDays {
        yearStart
        yearEnd
        leaveTypes {
          leavePolicyId
          leaveTypeId
          leaveTypeName
          isActive
          position
          color
          accrualType
          currentDays
          earnedDays
          earnedDaysWithoutRollover
          defaultDaysPerYear
          expiredDays
          hasUnlimitedDays
          rolloverFromPreviousYear
          usedDays
          seniorityEntitlementDaysForYear
          baseQuota
          toilDays
          toilDaysLapsed
          quota
          senioirtyEntitlementExists
          scheduledDays
          userLeaveTypeOverride
          ignoreRolloverFromPreviousYear
          allowHalfDays
          shortestLeaveInterval
          rolloverExpiryDate
          firstEarningDate
          accrualPeriodStart
        }
      }
      location {
        id
        timezone
        yearStartDay
        yearStartMonth
        leavePolicies(onlyActive: true) {
          id
          allowHalfDays
          shortestLeaveInterval
          isActive
          isReasonRequired
          maxRolloverDays
          hasUnlimitedDays
          accrualType
          firstEarningDate
          enableRolloverExpiry
          rolloverExpiryMonth
          rolloverExpiryDay
          rolloverExpiryAfterDays
          allowAdvanceAccrualUsage
          allowLeaveInPast
          broughtForwardExpirationSettings {
            enabled
            day
            month
            afterDays
          }
          toil
          toilRequestsAllowedForUsers
          toilShortestInterval
          leaveType {
            id
            name
            position
            deleted
            isActive
            calendarStatus
          }
        }
      }
      team {
        id
        name
        approvers {
          id
          name
        }
      }
    }
  }
`

export const getLeaveTypesForUser = /* GraphQL */ `
  query GetLeaveTypesForUser($id: String!) {
    getUser(id: $id) {
      id
      name
      locale
      hourFormat
      workHours
      role
      team {
        id
        name
        approvers {
          id
          name
        }
      }
      leaveDays {
        yearStart
        yearEnd
        leaveTypes {
          leavePolicyId
          leaveTypeId
          leaveTypeName
          isActive
          position
          color
          accrualType
          currentDays
          earnedDays
          earnedDaysWithoutRollover
          defaultDaysPerYear
          expiredDays
          hasUnlimitedDays
          rolloverFromPreviousYear
          usedDays
          seniorityEntitlementDaysForYear
          baseQuota
          toilDays
          toilDaysLapsed
          quota
          senioirtyEntitlementExists
          scheduledDays
          userLeaveTypeOverride
          ignoreRolloverFromPreviousYear
          allowHalfDays
          shortestLeaveInterval
          rolloverExpiryDate
          firstEarningDate
          accrualPeriodStart
        }
      }
      location {
        id
        timezone
        yearStartDay
        holidays {
          year
          holidays {
            name
            date
            multiDayId
            isHalfDay
          }
        }
        leavePolicies {
          id
          name
          isActive
          allowHalfDays
          shortestLeaveInterval
          isReasonRequired
          allowLeaveInPast
          isApprovalRequired
          toil
          toilRequestsAllowedForUsers
          toilShortestInterval
          allowAdvanceAccrualUsage
          leaveType {
            id
            name
            position
            deleted
            isActive
            calendarStatus
          }
        }
      }
    }
  }
`

export const getUserIdsForApprover = /* GraphQL */ `
  query GetUserIdsForApprover($id: String!) {
    getUser(id: $id) {
      id
      workWeek
      approverTo {
        id
      }
    }
  }
`

export const getUsersWithCustomLeaveQuota = /* GraphQL */ `
  query GetUsersWithCuseomLeaveQuota($leaveTypeId: String!, $locationId: String!) {
    getUsersWithCustomQuota(leaveTypeId: $leaveTypeId, locationId: $locationId) {
      users {
        id
        name
        locationId
      }
    }
  }
`

export const getCompanyInfo = /* GraphQL */ `
  query GetCompanyInfo {
    getCompany {
      id
      msTeamId
      msIsBotInstalled
      name
      organizationId
      contactEmail
      plan
      trialPeriod
      subscriptionStatus
      userEndDate
      platform
      importUsersAutomatically
      aiBotHelper
      announceNewUsers
      hourlyLeaveAccounting
      timestamp
      calculationStartYear
      shouldProrate
      proratedLeaveTypes
      defaultDepartmentForAutomaticImport
      defaultLocationForAutomaticImport
      billing {
        stripeCurrentPeriodEnd
        paymentProcessor
        subscriptionPeriod
        subscriptionPlan
        nextSubscriptionPeriod
        nextSubscriptionPlan
        address {
          city
          country
          state
          address
          addressLine2
        }
        quantity
        paymentMethodAdded
        msPlanId
        msSubscriptionStatus
        msPeriodEndDate
        msPeriodStartDate
        couponCodes
        billingName
        billingEmails
        stripePriceId
        billingType
        seats
      }
    },
  }
`

export const getCompanyGeneral = /* GraphQL */ `
  query GetCompanyGeneral {
    getCompany {
      id
      name
      importUsersAutomatically
      aiBotHelper
      announceNewUsers
      hourlyLeaveAccounting
      userEndDate
      platform
      shouldProrate
      proratedLeaveTypes
      defaultDepartmentForAutomaticImport
      defaultLocationForAutomaticImport
      calculationStartYear
      contactEmail
      shouldImportGuestUsers
    }
  }
`

export const getCompanyAndUserInfo = /* GraphQL */ `
  query GetCompanyAndUserInfo($userId: String!) {
    getCompany {
      id
      msTeamId
      msIsBotInstalled
      name
      organizationId
      contactEmail
      plan
      trialPeriod
      subscriptionStatus
      userEndDate
      platform
      importUsersAutomatically
      aiBotHelper
      announceNewUsers
      hourlyLeaveAccounting
      timestamp
      calculationStartYear
      shouldProrate
      proratedLeaveTypes
      defaultDepartmentForAutomaticImport
      defaultLocationForAutomaticImport
      billing {
        stripeCurrentPeriodEnd
        paymentProcessor
        subscriptionPeriod
        subscriptionPlan
        nextSubscriptionPeriod
        nextSubscriptionPlan
        address {
          city
          country
          state
          address
          addressLine2
        }
        quantity
        paymentMethodAdded
        msPlanId
        msSubscriptionStatus
        msPeriodEndDate
        msPeriodStartDate
        couponCodes
        billingName
        billingEmails
        stripePriceId
        billingType
        seats
      }
    },
    getUser(id: $userId) {
      id
      name
      role
      initialUser
      startDate
      email
      platform
      imageUrl
      workWeek
      workWeekType
      workHours
      isNameLocked
      locale
      hourFormat
      location {
        id
        name
        timezone
        workWeek
        leavePolicies {
          toil
          toilRequestsAllowedForUsers
        }
      }
      team {
        id
        name
        approvers {
            id
            name
          }
      }
      status
      approverTo {
        id
      }
      approverToTeams {
        id
      }
    }
  }
`

export const getCompanyBillingInfo = /* GraphQL */ `
  query GetCompanyBillingInfo {
    getCompany {
      id
      plan
      trialPeriod
      numberOfActiveUsers
      billing {
        paymentProcessor
        paymentProcessorId
        billingName
        billingEmails
        address {
          city
          country
          state
          address
          addressLine2
        }
        stripeCustomerId
        stripePaymentMethodId
        stripeSubscriptionId
        stripeSubscriptionItemId
        stripePriceId
        stripeCurrentPeriodEnd
        stripePaymentStatus
        sendInvoice
        paymentMethodAdded
        subscriptionPeriod
        subscriptionPlan
        nextSubscriptionPlan
        nextSubscriptionPeriod
        subscriptionStatus
        msSubscriptionId
        quantity
        msPlanId
        msSubscriptionStatus
        msPeriodStartDate
        msPeriodEndDate
        billingType
        seats
      }
    }
    getTeamList {
      id
      name
      isDefault
      parentId
    }
    getLocationList {
      id
      name
      workHours
    }
    getAutomationProbationPeriods {
      id
      name
      isActive
    }
    getAddonsSubscription {
      automations {
        type
        status
      }
    }
  }
`

export const getNumberOfActiveUsers = /* GraphQL */ `
  query GetCompanyBillingInfo {
    getCompany {
      numberOfActiveUsers
    }
  }
`

export const getCompanyLogs = /* GraphQL */ `
  query GetCompanyLogs(
    $fromDate: String!,
    $toDate: String!,
    $eventGroup: String,
    $groupKey: String,
    $lastId: String
  ) {
    getCompany {
      logs(fromDate: $fromDate, toDate: $toDate, eventGroup: $eventGroup, groupKey: $groupKey, lastId: $lastId) {
        sk
        type
        timestamp
        creatorName
        creatorId
        paramsJson
      }
    }
  }
`

export const getLeaveRequestData = /* GraphQL */ `
  query GetLeaveRequestData($leaveRequestId: String!) {
    getLeaveRequest(id: $leaveRequestId) {
      id
      userId
      user {
        locationId
      }
      status
      leavePolicyId
      calendarStatus
      leaveType {
        id
        name
        calendarStatus
        leavePolicies {
          shortestLeaveInterval
          isReasonRequired
          locationId
        }
      }
      autoApproved
      startDate
      endDate
      isPartDay
      partDayStartHour
      partDayEndHour
      reason
      workingDays
      daysList {
        day
        leaveHours
        workingHoursInDay
      }
      substituteApproverId
    }
  }
`

export const getLeaveRequestByIdForSubscription = /* GraphQL */ `
  query GetLeaveRequestById(
    $leaveRequestId: String!
  ) {
    getLeaveRequest(id: $leaveRequestId) {
      id
      user {
        id
        companyId
        name
        team {
          id
          name
          approvers {
            id
            name
          }
        }
        leaveDays {
          yearStart
          yearEnd
          leaveTypes {
            leaveTypeId
            leaveTypeName
            leavePolicyId
            accrualType
            currentDays
            earnedDays
            earnedDaysWithoutRollover
            defaultDaysPerYear
            expiredDays
            hasUnlimitedDays
            rolloverFromPreviousYear
            usedDays
            userLeaveTypeOverride
            position
            color
            isActive
            ignoreRolloverFromPreviousYear
            shouldProrate
            userProratedLeaveTypes
          }
        }
      }
      status
      leavePolicyId
      leaveType {
        id
        name
      }
      startDate
      endDate
      isPartDay
      autoApproved
      partDayStartHour
      partDayEndHour
      reason
      workingDays
      daysList {
        day
        leaveHours
        workingHoursInDay
      }
      calendarDays
      totalThisYear
      totalNextYear
      statusReason
      cancelledBeforeReview
      approver {
        id
        name
      }
      isEdited
    }
  }
`

export const getToilRequestByIdForSubscription = /* GraphQL */ `
  query GetToilRequestById(
    $toilRequestId: String!
  ) {
    getToilRequest(id: $toilRequestId) {
      id
      user {
        id
        companyId
        name
        team {
          id
          name
          approvers {
            id
            name
          }
        }
      }
      status
      leaveType {
        id
        name
      }
      leavePolicyId
      startDate
      endDate
      isPartDay
      partDayStartHour
      partDayEndHour
      reason
      statusReason
      approver {
        id
        name
      }
    }
  }
`

export const getUserForLeaveRequestUpdateNotification = /* GraphQL */ `
  query getUserForUpdate($id: String!) {
    getUser(id: $id) {
      id
      name
      workHours
    }
  }
`

export const getLeaveRequestById = /* GraphQL */ `
  query GetLeaveRequestById($leaveRequestId: String!) {
    getLeaveRequest(id: $leaveRequestId) {
      id
      user {
        id
        name
        imageUrl
        workHours
        location {
          id
          name
        }
        team {
          id
          name
        }
        leaveDays {
          yearStart
          yearEnd
          leaveTypes {
            leaveTypeId
            leaveTypeName
            leavePolicyId
            accrualType
            currentDays
            earnedDays
            earnedDaysWithoutRollover
            defaultDaysPerYear
            expiredDays
            hasUnlimitedDays
            rolloverFromPreviousYear
            usedDays
            userLeaveTypeOverride
            position
            color
            isActive
            ignoreRolloverFromPreviousYear
            shouldProrate
            userProratedLeaveTypes
          }
        }
      }
      status
      leavePolicyId
      leaveType {
        id
        name
      }
      startDate
      endDate
      isPartDay
      autoApproved
      partDayStartHour
      partDayEndHour
      reason
      workingDays
      daysList {
        day
        leaveHours
        workingHoursInDay
      }
      calendarDays
      totalThisYear
      totalNextYear
      statusReason
      cancelledBeforeReview
      approver {
        id
        name
      }
      isEdited
    }
  }
`

export const getToilRequestById = /* GraphQL */ `
  query GetToilRequestById($leaveRequestId: String!) {
    getToilRequest(id: $leaveRequestId) {
      id
      user {
        id
        name
        imageUrl
        location {
          id
          name
        }
        team {
          id
          name
        }
        leaveDays {
          yearStart
          yearEnd
          leaveTypes {
            leaveTypeId
            leaveTypeName
            leavePolicyId
            accrualType
            currentDays
            earnedDays
            earnedDaysWithoutRollover
            defaultDaysPerYear
            expiredDays
            hasUnlimitedDays
            rolloverFromPreviousYear
            usedDays
            userLeaveTypeOverride
            position
            color
            isActive
            ignoreRolloverFromPreviousYear
            shouldProrate
            userProratedLeaveTypes
          }
        }
      }
      status
      leavePolicyId
      leaveType {
        id
        name
      }
      startDate
      endDate
      daysList
      isPartDay
      partDayStartHour
      partDayEndHour
      reason
      statusReason
      approver {
        id
        name
      }
    }
  }
`

export const getUserHolidays = /* GraphQL */ `
  query GetUserHolidays($id: String!) {
    getUser(id: $id) {
      id
      location {
        id
        name
        workWeek
        firstDayOfWeek
        holidays {
          year
          holidays {
            name
            date
            multiDayId
            isHalfDay
          }
        }
      }
    }
  }
`

export const getUserById = /* GraphQL */ `
  query GetUserById(
    $id: String!,
    $date: String,
    $pendingLeavesDate: String,
  ) {
    getUser(id: $id) {
      id
      name
      role
      isAdmin
      status
      startDate
      endDate
      employeeId
      email
      platform
      imageUrl
      isNameLocked
      workWeek
      workWeekType
      workWeekInfo
      workHours
      timestamp
      imageUrl
      locale
      hourFormat
      location {
        id
        name
        workWeek
        firstDayOfWeek
        resetQuotas
        rolloverNeverExpireDays
        rolloverExpiryMonth
        rolloverExpiryDay
        rolloverExpiryAfterDays
        yearStartDay
        yearStartMonth
        timezone
        leavePolicies {
          id
          name
          allowHalfDays
          shortestLeaveInterval
          isActive
          isReasonRequired
          allowLeaveInPast
          toil
          toilShortestInterval
          toilRequestsAllowedForUsers
          allowAdvanceAccrualUsage
          maxRolloverDays
          hasUnlimitedDays
          accrualType
          firstEarningDate
          accrualPeriodStart
          enableRolloverExpiry
          rolloverExpiryMonth
          rolloverExpiryDay
          rolloverExpiryAfterDays
          broughtForwardExpirationSettings {
            enabled
            day
            month
            afterDays
          }
          leaveType {
            id
            name
            position
            deleted
            isActive
            calendarStatus
          }
        }
      }
      team {
        id
        name
        approvers {
          id
          name
        }
      }
      leaveDays {
        yearStart
        yearEnd
        leaveTypes {
          leavePolicyId
          leaveTypeId
          leaveTypeName
          isActive
          position
          color
          accrualType
          currentDays
          earnedDays
          earnedDaysWithoutRollover
          defaultDaysPerYear
          seniorityEntitlementDaysForYear
          entitlementByRoleDaysForYear
          senioirtyEntitlementExists
          entitlementByRoleExists
          baseQuota
          quota
          toilDays
          toilDaysLapsed
          capHit
          expiredDays
          hasUnlimitedDays
          rolloverFromPreviousYear
          usedDays
          scheduledDays
          userLeaveTypeOverride
          ignoreRolloverFromPreviousYear
          allowHalfDays
          shortestLeaveInterval
          rolloverExpiryDate
          shouldProrate
          userProratedLeaveTypes
          pauseAccrualsWhenUserIsDeactivated
        }
      }
      upcomingLeaves(date: $date) {
        id
        status
        user {
          id
        }
        leavePolicyId
        approver {
          id
          name
        }
        leaveType {
          name
        }
        autoApproved
        startDate
        endDate
        isPartDay
        partDayStartHour
        partDayEndHour
        reason
        statusReason
        workingDays
        daysList {
          day
          leaveHours
          workingHoursInDay
        }
        isEdited
        approvedBySubstituteApprover
      }
      pendingLeaves(date: $pendingLeavesDate) {
        id
        status
        startDate
        endDate
        isPartDay
        partDayStartHour
        partDayEndHour
        leaveType {
          id
          name
        }
        autoApproved
        reason
        workingDays
        daysList {
          day
          leaveHours
          workingHoursInDay
        }
        totalThisYear
        totalNextYear
        isEdited
        approvedBySubstituteApprover
      }
      history(date: $date) {
        id
        status
        user {
          id
        }
        leaveType {
          id
          name
          color
        }
        leavePolicy {
          allowLeaveInPast
          isReasonRequired
        }
        approver {
          id
          name
        }
        autoApproved
        startDate
        endDate
        isPartDay
        isResent
        partDayStartHour
        partDayEndHour
        reason
        statusReason
        workingDays
        daysList {
          day
          leaveHours
          workingHoursInDay
        }
        cancelledBeforeReview
        isEdited
        approvedBySubstituteApprover
      }
      today(date: $date) {
        id
        startDate
        endDate
        isPartDay
        partDayStartHour
        partDayEndHour
        reason
        workingDays
        daysList {
          day
          leaveHours
          workingHoursInDay
        }
        autoApproved
        approver {
          id
          name
        }
        leaveType {
          id
          name
          color
        }
        statusReason
        approvedBySubstituteApprover
      }
      labels {
        id
        name
        color
      }
      probationPeriod {
        lengthDays
        name
        leaveTypeIds
      }
    }
    getCompany {
      userEndDate
    }
    getTeamList {
      id
      name
      isDefault
      parentId
    }
    getLocationList {
      id
      name
    }
    getLabels {
      id
      name
      color
      userCount
    }
  }
`

export const getUserLogs = /* GraphQL */ `
  query GetUserLogs(
    $id: String!,
    $fromDate: String!,
    $toDate: String!,
  ) {
    getUser(id: $id) {
      id
      logs(fromDate: $fromDate, toDate: $toDate) {
        type
        timestamp
        creatorName
        creatorId
        paramsJson
      }
    }
  }
`

export const getCurrentUser = /* GraphQL */ `
  query GetCurrentUser(
    $id: String!,
    $date: String,
    $pendingLeavesDate: String,
  ) {
    getUser(id: $id) {
      id
      name
      role
      isAdmin
      email
      status
      startDate
      endDate
      employeeId,
      platform
      imageUrl
      workWeek
      workWeekType
      workWeekInfo
      workHours
      timestamp
      locale
      hourFormat
      labels {
        id
        name
        color
      }
      location {
        id
        name
        workWeek
        firstDayOfWeek
        resetQuotas
        rolloverNeverExpireDays
        rolloverExpiryMonth
        rolloverExpiryDay
        rolloverExpiryAfterDays
        yearStartDay
        timezone
        leavePolicies {
          id
          name
          allowHalfDays
          shortestLeaveInterval
          isActive
          isReasonRequired
          maxRolloverDays
          hasUnlimitedDays
          accrualType
          firstEarningDate
          enableRolloverExpiry
          rolloverExpiryMonth
          rolloverExpiryDay
          rolloverExpiryAfterDays
          broughtForwardExpirationSettings {
            enabled
            day
            month
            afterDays
          }
          toil
          toilRequestsAllowedForUsers
          toilShortestInterval
          leaveType {
            id
            name
            position
            deleted
            isActive
          }
        }
      }
      team {
        id
        name
        approvers {
          id
          name
        }
      }
      leaveDays {
        yearStart
        yearEnd
        leaveTypes {
          leavePolicyId
          leaveTypeId
          leaveTypeName
          isActive
          position
          color
          accrualType
          currentDays
          earnedDays
          earnedDaysWithoutRollover
          seniorityEntitlementDaysForYear
          senioirtyEntitlementExists
          baseQuota
          toilDays
          toilDaysLapsed
          quota
          defaultDaysPerYear
          expiredDays
          hasUnlimitedDays
          rolloverFromPreviousYear
          usedDays
          scheduledDays
          userLeaveTypeOverride
          ignoreRolloverFromPreviousYear
          allowHalfDays
          shortestLeaveInterval
          rolloverExpiryDate
          shouldProrate
          userProratedLeaveTypes
          pauseAccrualsWhenUserIsDeactivated
        }
      }
      upcomingLeaves(date: $date) {
        id
        status
        user {
          id
        }
        leavePolicyId
        approver {
          id
          name
        }
        leaveType {
          name
        }
        autoApproved
        startDate
        endDate
        isPartDay
        partDayStartHour
        partDayEndHour
        reason
        statusReason
        workingDays
        daysList {
          day
          leaveHours
          workingHoursInDay
        }
        approvedBySubstituteApprover
        isEdited
      }
      pendingLeaves(date: $pendingLeavesDate) {
        id
        status
        startDate
        endDate
        isPartDay
        partDayStartHour
        partDayEndHour
        leaveType {
          id
          name
        }
        autoApproved
        reason
        workingDays
        daysList {
          day
          leaveHours
          workingHoursInDay
        }
        totalThisYear
        totalNextYear
        isEdited
      }
      history(date: $date) {
        id
        status
        user {
          id
        }
        leaveType {
          id
          name
          color
        }
        leavePolicy {
          allowLeaveInPast
          isReasonRequired
        }
        approver {
          id
          name
        }
        autoApproved
        startDate
        endDate
        isPartDay
        isResent
        partDayStartHour
        partDayEndHour
        reason
        statusReason
        workingDays
        daysList {
          day
          leaveHours
          workingHoursInDay
        }
        cancelledBeforeReview
        approvedBySubstituteApprover
        isEdited
      }
      today(date: $date) {
        id
        startDate
        endDate
        isPartDay
        partDayStartHour
        partDayEndHour
        reason
        workingDays
        daysList {
          day
          leaveHours
          workingHoursInDay
        }
        approver {
          id
          name
        }
        autoApproved
        leaveType {
          id
          name
          color
        }
        statusReason
        approvedBySubstituteApprover
      }
    }
    getLabels {
      id
      name
      color
    }
    getCompany {
      userEndDate
    }
  }
`

// If you do not have a nextToken, use the "NONE" value, as we need a value for cache eviction
export const getLeavesByDateAndStatus = /* GraphQL */ `
  query GetLeavesByDateAndStatus(
    $dateStart: String!
    $dateEnd: String!
    $status: String!
    $limit: Int!
    $nextToken: String!
  ) {
    getLeaveRequestByDate(
      dateStart: $dateStart
      dateEnd: $dateEnd
      status: $status
      limit: $limit
      nextToken: $nextToken
    ) {
      leaveRequests {
        id
        user {
          id
          name
          imageUrl
          workHours
          team {
            id
            approvers {
              id
            }
          }
          labels {
            id
            name
            color
          }
          location {
            id
            name
          }
        }
        status
        approver {
          id
          name
        }
        leavePolicy {
          id
          hideLeaveType
          leaveType {
            id
            name
            color
            deleted
          }
        }
        autoApproved
        startDate
        endDate
        isPartDay
        partDayStartHour
        partDayEndHour
        reason
        workingDays
        calendarDays
        totalThisYear
        totalNextYear
        substituteApproverId
        approvedBySubstituteApprover
        isEdited
      }
      nextToken
    }
  }
`

export const getToilByDateAndStatus = /* GraphQL */ `
  query GetToilByDateAndStatus(
    $dateStart: String!
    $dateEnd: String!
    $status: String!
    $limit: Int!
    $nextToken: String!
  ) {
    getToilRequestsByDate(
      dateStart: $dateStart
      dateEnd: $dateEnd
      status: $status
      limit: $limit
      nextToken: $nextToken
    ) {
      toilRequests {
        id
        user {
          id
          name
          imageUrl
          team {
            id
            approvers {
              id
            }
          }
          labels {
            id
            name
            color
          }
          location {
            id
            name
          }
        }
        status
        approver {
          id
          name
        }
        leavePolicy {
          id
          hideLeaveType
          leaveType {
            id
            name
            color
            deleted
          }
        }
        daysList
        startDate
        endDate
        isPartDay
        partDayStartHour
        partDayEndHour
        reason
      }
      nextToken
    }
  }
`

export const getHeatmapData = /* GraphQL */ `
  query GetHeatmapData {
    locations: getLocationList {
      name
      id
      holidays {
        year
        holidays {
          name
          date
          isHalfDay
        }
      }
    }
    departments: getTeamListV2 {
      id
      name
      parentId
    }
    labels: getLabels {
      id
      name
      color
    }
  }
`

export const getCompanyNotificationsV2 = /* GraphQL */ `
  query GetCompanyNotificationsV2 {
    getNotificationsV2 {
      id
      name
      firstDayOfWeek
      frequency
      destinations {
        platform
        addresses
        msGroup
      }
      day
      hour
      minute
      timezone
      enabled
      locations
      teams
      labels
      groupBy
      sendEmpty
      currentWeek
      sendOnDays
      locale
      error
      owner {
        id
        name
      }
    }
  }
`

export const getNotificationById = /* GraphQL */ `
  query getNotificationById($id: String!) {
    getNotification(id: $id) {
      id
      name
      firstDayOfWeek
      frequency
      destinations {
        platform
        addresses
        msGroup
      }
      day
      hour
      minute
      timezone
      enabled
      locations
      teams
      labels
      groupBy
      sendEmpty
      currentWeek
      sendOnDays
      locale
      error
      owner {
        id
        name
      }
    }
  }
`

export const errorSubscription = /* GraphQL */ `
  subscription GetErrors($userId: String!) {
    eventResponses(userId: $userId) {
      companyId
      userId
      correlationId
      code
      errorCode
      data
      message
      originalEvent
      result
      name
    }
  }
`

export const companyUpdateSubscription = /* GraphQL */ `
  subscription GetCompanyUpdates($companyId: String!) {
    companyUpdates(companyId: $companyId) {
      companyId
      userId
      correlationId
      code
      message
      originalEvent
      result
    }
  }
`

export const whatsNewResponseSubscription = /* GraphQL */ `
  subscription GetWhatsNewResponse($userId: String!) {
    whatsNewResponse(userId: $userId) {
      companyId
      userId
      messageId
      originalEvent
    }
  }
`

export const getPendingForApproverById = /* GraphQL */ `
  query GetPendingForApproverById($approverId: String!) {
    getPendingForApprover(approverId: $approverId) {
      id
      user {
        id
        name
        imageUrl
        workHours
        team {
          id
          approvers {
            id
          }
        }
        labels {
          id
          name
          color
        }
        location {
          id
          name
        }
      }
      status
      leavePolicy {
        id
        hideLeaveType
        leaveType {
          id
          name
          color
          deleted
        }
      }
      startDate
      endDate
      isPartDay
      partDayStartHour
      partDayEndHour
      reason
      workingDays
      daysList {
        day
        leaveHours
        workingHoursInDay
      }
      calendarDays
      totalThisYear
      totalNextYear
    }
    getPendingToilForApprover(approverId: $approverId) {
      id
      user {
        id
        name
        imageUrl
        team {
          id
          approvers {
            id
          }
        }
        labels {
          id
          name
          color
        }
        location {
          id
          name
        }
      }
      status
      approver {
        id
        name
      }
      leavePolicy {
        id
        hideLeaveType
        leaveType {
          id
          name
          color
          deleted
        }
      }
      daysList
      startDate
      endDate
      isPartDay
      partDayStartHour
      partDayEndHour
      reason
    }
  }
`

export const getLocationsAndTeamsShort = /* GraphQL */ `
  query GetLocationsAndTeamsShort {
    getLocationList {
      id
      name
    }
    getTeamList {
      id
      name
    }
  }
`

export const getLocationsTeamsAndLabelsShort = /* GraphQL */ `
  query GetLocationsTeamsAndLabelsShort {
    getLocationList {
      id
      name
    }
    getTeamListV2 {
      id
      name
      parentId
    }
    getLabels {
      id
      name
      color
    }
  }
`

export const getAutomationFormData = /* GraphQL */ `
  query GetAutomationFormData {
    getLocationList {
      id
      name
    }
    getTeamList {
      id
      name
      parentId
    }
    getLabels {
      id
      name
      color
    }
    getLeaveTypeList {
      id
      name
      position
    }
    getAutomations {
      id
      name
      leaveTypeIds
      leaveTypeId
      automationType
      startDate
      calculateFrom
      endDate
      recurring
      periods {
        days
        years
      }
      isActive
      reason
      locations
      teams
      labels
      labelId
      days
      createdAt
      awardSeniorityEntitlementDaysOn
      prorate
      error
      errorCode
    }
  }
`

export const getUsersPageData = /* GraphQL */ `
  query GetUsersPageData {
    getLocationList {
      id
      name
    }
    getTeamList {
      id
      name
    }
    getLabels {
      id
      name
      color
    }
    getGoogleIntegrationSettings {
      scopes
    }
  }
`

export const getLocationsApproverTeamsAndLabels = /* GraphQL */ `
  query GetLocationsApproverTeamsAndLabels($id: String!) {
    getUser(id: $id) {
      approverToTeams {
        id
        name
      }
    }
    getLocationList {
      id
      name
    }
    getLabels {
      id
      name
      color
    }
  }
`

export const getAutomationFormApproverData = /* GraphQL */ `
  query GetAutomationFormApproverData($id: String!) {
    getUser(id: $id) {
      approverToTeams {
        id
        name
      }
    }
    getLocationList {
      id
      name
    }
    getLabels {
      id
      name
      color
    }
    getLeaveTypeList {
      id
      name
      position
    }
    getAutomations {
      id
      name
      leaveTypeIds
      leaveTypeId
      automationType
      startDate
      calculateFrom
      endDate
      recurring
      periods {
        days
        years
      }
      isActive
      reason
      locations
      teams
      labels
      labelId
      days
      createdAt
      awardSeniorityEntitlementDaysOn
      prorate
      error
      errorCode
    }
  }
`

export const getLeaveTypesListAndLocations = /* GraphQL */ `
  query GetLeaveTypesListAndLocations {
    getLocationList {
      id
      name
    }
    getLeaveTypeList {
      id
      name
      isActive
      color
      position
      calendarStatus
      leavePolicies {
        id
        location {
          id
          name
        }
        isActive
      }
      awayStatus
      awayStatusIcon
      awayStatusMessage
      deleted
      isDefault
    }
  }
`

export const getLeaveTypesList = /* GraphQL */ `
  query GetLeaveTypesList {
    getLeaveTypeList {
      id
      name
      isActive
      color
      position
      calendarStatus
      leavePolicies {
        id
        location {
          id
          name
        }
        isActive
      }
      awayStatus
      awayStatusIcon
      awayStatusMessage
      deleted
      isDefault
    }
  }
`

export const getUsersForNotifictions = /* GraphQL */ `
  query GetUsersForNotifications(
    $locationIds: [String!]
    $teamIds: [String!]
    $labelIds: [String!]
  ) {
    getUsersFiltered(
      status: "ACTIVE"
      locationIds: $locationIds
      teamIds: $teamIds
      labelIds: $labelIds
    ) {
      totalUsers
    }
  }
`

export const getTotalTeamsAndUsers = /* GraphQL */ `
  query GetTotalTeamsAndUsers {
    getTeamList {
      id
      name
      parentId
    }
    getUsersFiltered(status: "ACTIVE", limit: 10000) {
      users {
        id
        name
      }
    }
  }
`

export const getTotalLocationsAndUsers = /* GraphQL */ `
  query GetTotalLocationsAndUsers {
    getLocationList {
      id
    }
    getUsersFiltered(status: "ACTIVE", limit: 10000) {
      users {
        id
        name
      }
    }
  }
`

export const getSlackBotDetails = /* GraphQL */ `
  query getSlackBotDetails($id: String!) {
    slackId(id: $id) {
      slackBotId
    }
  }
`

export const getSlackDetails = /* GraphQL */ `
  query getSlackDetails($id: String!) {
    slackId(id: $id) {
      slackUserId
      slackTeamId
      slackOrgTeamId
      slackBotId
    }
  }
`

export const getScheduledReports = /* GraphQL */ `
  query getScheduledReports {
    getScheduledReportList {
      id
      name
      teams {
        id
        name
        isDefault
      }
      locations {
        id
        name
      }
      labels {
        id
        name
      }
      reportType
      period
      receivers
      timezone
      sendDayOfMonth
      sendHour
      sendMinute
      leaveStatus
      error
    }
  }
`

export const getScheduledReportById = /* GraphQL */ `
  query getScheduledReportById($id: String!) {
    getScheduledReport(id: $id) {
      id
      name
      teams {
        id
        name
      }
      locations {
        id
        name
      }
      labels {
        id
        name
      }
      reportType
      period
      receivers
      timezone
      sendDayOfMonth
      sendHour
      sendMinute
      leaveStatus
      error
    }
  }
`

export const slackIds = /* GraphQL */ `
  query SlackIds($limit: Int, $nextToken: String) {
    slackIds(limit: $limit, nextToken: $nextToken) {
      users {
        companyId
        vacationTrackerId
        slackUserId
        slackTeamId
        slackOrgTeamId
        slackBotId
        isOwner
      }
      nextToken
      scannedCount
    }
  }
`

export const getInitialBroughtForwardUnlockData = /* GraphQL */ `
  query GetInitialBroughtForwardUnlockData($locationId: String!) {
    getLocation(id: $locationId) {
      id
      name
      timestamp
      users {
        id
        name
        imageUrl
      }
      holidays {
        year
        holidays {
          name
          date
          multiDayId
          isHalfDay
        }
      }
      workWeek
      yearStartMonth
      yearStartDay
      isDefault
      firstDayOfWeek
      resetQuotas
      rolloverExpiryMonth
      rolloverExpiryDay
      rolloverExpiryAfterDays
      rolloverNeverExpireDays
      timezone
      leavePolicies {
        id
        locationId
        isActive
        daysPerYear
        maxRolloverDays
        isApprovalRequired
        hasUnlimitedDays
        allowHalfDays
        shortestLeaveInterval
        hideLeaveType
        isReasonRequired
        negativeBallanceAllowed
        accrualType
        firstEarningDate
        accrualCapRate
        accrualPeriodStart
        accrualsLocked
        enableRolloverExpiry
        rolloverExpiryMonth
        rolloverExpiryDay
        rolloverExpiryAfterDays
        toil
        toilShortestInterval
        toilRequestsAllowedForUsers
        toilExpiration
        toilExpirationDate
        toilExpirationMonths
        leaveType {
          id
          name
          isActive
          position
          deleted
        }
      }
    }
    getCompany {
      customLeaveTypes {
        leaveTypeId
        daysPerYear
        hasUnlimitedDays
      }
    }
  }
`

export const getGoogleScopes = /* GraphQL */ `
  query GoogleIntegrationSettings($accessToken: String!) {
    getGoogleIntegrationSettings {
      scopes
      domainName
    }
    getGoogleTokenInfo(accessToken: $accessToken) {
      scopes
    }
  }
`

export const getUserActionFlow = /* GraphQL */ `
  query GetUserActionFlow($type: String) {
    getUserActionFlow(type: $type) {
      type
      skipped
      completed
      actions {
        ONBOARDING_CHECKLIST_BOT_VIDEO_WATCHED
        ONBOARDING_CHECKLIST_BOT_BUTTON_CLICKED
        ONBOARDING_CHECKLIST_BOT_COMPLETED
        ONBOARDING_CHECKLIST_LEAVE_REQUEST_VIDEO_WATCHED
        ONBOARDING_CHECKLIST_LEAVE_REQUEST_BUTTON_CLICKED
        ONBOARDING_CHECKLIST_LEAVE_REQUEST_COMPLETED
        ONBOARDING_CHECKLIST_ICAL_VIDEO_WATCHED
        ONBOARDING_CHECKLIST_ICAL_BUTTON_CLICKED
        ONBOARDING_CHECKLIST_ICAL_COMPLETED
        ONBOARDING_CHECKLIST_NOTIFICATIONS_VIDEO_WATCHED
        ONBOARDING_CHECKLIST_NOTIFICATIONS_BUTTON_CLICKED
        ONBOARDING_CHECKLIST_NOTIFICATIONS_COMPLETED
        ONBOARDING_CHECKLIST_IMPORT_USERS_VIDEO_WATCHED
        ONBOARDING_CHECKLIST_IMPORT_USERS_BUTTON_CLICKED
        ONBOARDING_CHECKLIST_IMPORT_USERS_COMPLETED
      }
      version
    }
  }
`

export const getUserHelpInfo = /* GraphQL */ `
  query GetUserHelpInfo {
    getUserHelpInfo {
      actions {
        HELP_INFO_LOCATION_CREATE
        HELP_INFO_LEAVE_TYPE_CREATE
      }
      version
    }
  }
`

export const getInvitations = /* GraphQL */ `
  query GetInvitations {
    getInvitations {
      email
      companyId
      status
      timestamp
      code
      invitedBy {
        name
      }
    }
  }
`

export const getConfigureUsersData = /* GraphQL */ `
  query GetConfigureUsersData {
    getTeamList {
      id
      name
      isDefault
      parentId
    }
    getLocationList {
      id
      name
      resetQuotas
      isDefault
    }
    getLeaveTypeList {
      id
      name
      isActive
      color
      leavePolicies {
        locationId
      }
    }
    getCompany {
      shouldImportGuestUsers
      shouldProrate
      proratedLeaveTypes
      defaultDepartmentForAutomaticImport
      defaultLocationForAutomaticImport
      announceNewUsers
    }
  }
`
export const getWhatsNew = /* GraphQL */ `
  query GetWhatsNew($limit: Int, $nextToken: String) {
    getWhatsNew(limit: $limit, nextToken: $nextToken) {
      news {
        messageId,
        title
        description
        imageUrl
        unread
        linkText
        linkUrl
        timestamp
        videoEmbedId
        showPopup
        subtitle
        openLinkInSameTab
      }
      nextToken
    }
  }
`

export const getLeaveTypeListShort = /* GraphQL */ `
  query GetLeaveTypeListShort {
    getLeaveTypeList {
      id
      name
      position
    }
  }
`

export const getAddonsSubscription = /* GraphQL */ `
  query GetAddonsSubscription {
    getAddonsSubscription {
      companyId
      addons {
        type
        status
      }
      automations {
        type
        status
      }
      integrations
    }
  }
`

export const getPurchasedAddons = /* GraphQL */ `
  query GetPurchasedAddons {
    getPurchasedAddons {
      companyId
      addons {
        type
        status
      }
      automations {
        type
        status
      }
      integrations
    }
    getUserCount {
      usersActive
    }
  }
`


/**
 * ------------------AUTOMATIONS START-------------------------------------
 * Automations are outdated and is kept for avoiding breaking changes
 * All opf them are considered as add-ons with different types (Automations, Integrations, Add-ons)
 * AutomationsViewTable is storing automations, add-ons and integrations
 * --------------------------------------------------------------
 */

export const getAutomations = /* GraphQL */ `
  query getAutomations {
    getAutomations {
      id
      name
      leaveTypeIds
      leaveTypeId
      automationType
      startDate
      calculateFrom
      endDate
      recurring
      periods {
        days
        years
      }
      isActive
      reason
      locations
      teams
      labels
      labelId
      days
      createdAt
      awardSeniorityEntitlementDaysOn
      prorate
      error
      errorCode
      visibilityLevel
      lengthDays
      owner {
        id
        name
      }
    }
  }
`

export const getAutomationsById = /* GraphQL */ `
  query GetAutomationsById($id: String!) {
    getAutomation(id: $id) {
      id
      name
      leaveTypeId
      leaveTypeIds
      automationType
      startDate
      calculateFrom
      endDate
      recurring
      isActive
      reason
      periods {
        days
        years
      }
      prorate
      locations
      teams
      labels
      labelId
      owner {
        id
        name
      }
      days
      awardSeniorityEntitlementDaysOn
      lengthDays
    }
  }
`
/**
 * ------------------AUTOMATIONS END-------------------------------------
 *  Automations are outdated and is kept for avoiding breaking changes
 * All opf them are considered as add-ons with different types (Automations, Integrations, Add-ons)
 * AutomationsViewTable is storing automations, add-ons and integrations
 * --------------------------------------------------------------
 */


export const entitlementByRoleData = /* GraphQL */ `
  query EntitlementByRoleData {
    getLabels {
      id
      name
      color
    }
    getLeaveTypeList {
      id
      name
      position
    }
  }
`

export const getOverlappingUsersInfo = /* GraphQL */ `
  query GetOverlappingUsersInfo($teamId: String!, $leaveRequest: BasicLeaveRequestInfo) {
    getOverlappingUsersInfo(teamId: $teamId, leaveRequest: $leaveRequest) {
      message
      employees
      calendarDays
      workingDays
      leaveDaysList
      year1Days
      year2Days
      workingHours
      daysOnLeaveEndDates
    }
  }
`

export const getCalendars = /* GraphQL */ `
  query getCalendars($companyId: String!) {
    getCalendars(companyId: $companyId) {
      type
      id
      refreshToken
      accessToken
      locationId
      locationIds
      labelIds
      teamIds
      labelId
      teamId
      creator
      errors
    }
  }
`

export const getSyncedCalendarsData = /* GraphQL */ `
  query getSyncedCalendarsData($companyId: String!) {
    getCalendars(companyId: $companyId) {
      type
      id
      refreshToken
      accessToken
      locationId
      locationIds
      labelIds
      teamIds
      labelId
      teamId
      creator
      timezone
      errors {
        code
      }
      error {
        code
      }
      creator
      calendarName
      user {
        id
        name
      }
      deleted
    }
    getLocationList {
      id
      name
    }
    getTeamListV2 {
      id
      name
      parentId
    }
    getLabels {
      id
      name
      color
    }
  }
`
export const getTeamsForApprover = /* GraphQL */ `
  query getTeamsForApprover($id: String!, $date: String) {
    getUser(id: $id) {
      approverToTeams {
        id
        name
        approvers {
          id
          name
          upcomingLeaves(date: $date) {
            id
            status
            user {
              id
            }
            approver {
              id
              name
            }
            leaveType {
              name
            }
            startDate
            endDate
          }
        }
      }
      teamId
    }
  }
`

export const getConsumedLeaveRequestsForPeriod = /* GraphQL */ `
  query GetConsumedLeaveRequestsForPeriod(
    $dateStart: String!
    $dateEnd: String!
  ) {
    getConsumedLeaveRequestsForPeriod(
      dateStart: $dateStart
      dateEnd: $dateEnd
    ) {
        userId
        leaves {
          date
          hours
          isPartDay
        }
    }
  }
`

const childrenFragment = /* GraphQL */ `
  fragment Children on TeamTree {
    id
    name
    isDefault
    timestamp
    users {
      id
      name
      imageUrl
    }
    approvers {
      id
      name
      imageUrl
    }
    children {
      id
      name
      isDefault
      timestamp
      users {
        id
        name
        imageUrl
      }
      approvers {
        id
        name
        imageUrl
      }
      children {
        id
        name
        isDefault
        timestamp
        users {
          id
          name
          imageUrl
        }
        approvers {
          id
          name
          imageUrl
        }
      }
    }
  }
`

const childrenFragmentComplete = /* GraphQL */ `
  fragment ChildrenComplete on TeamTree {
    id
    name
    isDefault
    timestamp
    users {
      id
      name
      imageUrl
    }
    approvers {
      id
      name
      imageUrl
    }
    children {
      id
      name
      isDefault
      timestamp
      users {
        id
        name
        imageUrl
      }
      approvers {
        id
        name
        imageUrl
      }
      children {
        id
        name
        isDefault
        timestamp
        users {
          id
          name
          imageUrl
        }
        approvers {
          id
          name
          imageUrl
        }
        children {
          id
          name
          isDefault
          timestamp
          users {
            id
            name
            imageUrl
          }
          approvers {
            id
            name
            imageUrl
          }
          children {
            id
            name
            isDefault
            timestamp
            users {
              id
              name
              imageUrl
            }
            approvers {
              id
              name
              imageUrl
            }
            children {
              id
              name
              isDefault
              timestamp
              users {
                id
                name
                imageUrl
              }
              approvers {
                id
                name
                imageUrl
              }
            }
          }
        }
      }
    }
  }
`

export const getTeamTree = /* GraphQL */ `
 ${childrenFragment}
  query GetTeamTree {
    getTeamTree {
      id
      name
      isDefault
      timestamp
      users {
        id
        name
        imageUrl
      }
      approvers {
        id
        name
        imageUrl
      }
      ...Children
    }
  }
`
export const getTeamTreeComplete = /* GraphQL */ `
 ${childrenFragmentComplete}
  query GetTeamTreeComplete {
    getTeamTree {
      id
      name
      isDefault
      timestamp
      users {
        id
        name
        imageUrl
      }
      approvers {
        id
        name
        imageUrl
      }
      ...ChildrenComplete
    }
  }
`

export const getAiAssistedOnboardingData = /* GraphQL */ `
  query getAiAssistedOnboardingData {
    getTeamList {
      id
      name
      isDefault
    }
    getLocationList {
      id
      name
      resetQuotas
      timestamp
      timezone
      yearStartDay
      yearStartMonth
      firstDayOfWeek
    }
    getLeaveTypeList {
      id
      name
      isActive
      deleted
      color
      position
      isDefault
    }
  }
`

export const getAiAssistantConversationInfoData = /* GraphQL */ `
  query getAiAssistantConversationInfo($companyId: String!) {
    getAiAssistedConversationInfo(companyId: $companyId) {
      topic
      timestamp
      isDone
      updatedAt
      id
    }
  }
`

export const getAiAssistantConversationInfoAndMessagesData = /* GraphQL */ `
  query getAiAssistantConversationInfoAndMessages($companyId: String!, $userId: String!, $conversationId: String!) {
    getAiAssistedConversationInfoAndMessages(companyId: $companyId, userId: $userId, conversationId: $conversationId) {
      role
      content
      companyId
      userId
      id
      timestamp
      userId
      ttl
      isDone
      topic
      type
    }
  }
`

export const getAiAssistedOnboardingSetupCoreEventsData = /* GraphQL */ `
  query getAiAssistedOnboardingSetupCoreEvents($companyId: String!) {
    getAiAssistedOnboardingSetupCoreEvents(companyId: $companyId) {
      setupJSON
      eventType
      eventGroup
      name
      id
      leaveTypeId
      locationId
      teamId
      status
    }
  }
`