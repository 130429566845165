import React, { useContext, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Typography, Form, Input, Button, notification, Select, Col, Row, Radio, InputNumber, Divider } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { API, graphqlOperation } from 'aws-amplify'
import { useIntl } from 'react-intl'
import { notificationStore } from '../../context/notificationsContext/store'
import { getAutomationsById } from '../../graphql/custom-queries'
import { track } from '../../services/analytics/analytics'
import * as logger from '../../services/logger'

import FilterAdvanced from '@vacationtracker/shared/components/filter-advanced'
import IntlMessages from '../../util/IntlMessages'
import CircularProgress from '../circular-progress'

import { IAutomationProbationPeriodForm } from '@vacationtracker/shared/types/automations'
import { ITeamShort } from '@vacationtracker/shared/types/team'
import { IFilter } from '@vacationtracker/shared/types/filter'
import { IGetTeamsShort } from '../../types/teams'
import { IGetLocationsShort } from '../../types/locations'
import { IGetLabelsShort } from '../../types/labels'
import { IGetAutomationsByIdData, ILeaveTypeListShort, IProbationPeriodAutomation } from '../../types/custom-queries'
import { ISelected } from '@vacationtracker/shared/components/filter-advanced/types'
import { IData } from '../../types/data'
import { FrontendUrls } from '../../types/urls'
import { AddonsStatus, AddonsStatusEnum } from '@vacationtracker/shared/types/addons'

const { Title, Paragraph } = Typography
const { Option } = Select
const { TextArea } = Input

interface IProbationPeriodForm {
  teams: IGetTeamsShort[] | ITeamShort[]
  locations: IGetLocationsShort[]
  labels: IGetLabelsShort[]
  leaveTypes: ILeaveTypeListShort[]
  filter: IFilter
  handleFilter: (filter: IFilter) => void
  backToAddons: () => void
  shouldEnableLabels: boolean
  automationId?: string
  amIAdmin: boolean
  status?: AddonsStatus
}

const ProbationPeriodForm = ({
  teams,
  locations,
  labels,
  leaveTypes,
  filter,
  handleFilter,
  shouldEnableLabels,
  automationId,
  backToAddons,
  amIAdmin,
  status,
}: IProbationPeriodForm) => {
  const [form] = Form.useForm()
  const { formatMessage } = useIntl()
  const history = useHistory()
  const { actionNotifications, setActionNotifications } = useContext(notificationStore)

  const [applyToAllUsers, setApplyToAllUsers] = useState(amIAdmin ? true : false)
  const [isLoading, setIsLoading] = useState(true)
  const [addOn, setAddOn] = useState<IAutomationProbationPeriodForm>({
    automationId: '',
    name: '',
    leaveTypeIds: leaveTypes.map(leaveType => leaveType.id),
    lengthDays: 30,
    applyToAllUsers: amIAdmin ? true : false,
    reason: formatMessage({ id: 'automations.probationPeriod.autoRejectReasonDefault' }),
    isActive: true,
    locations: [],
    teams: [],
    labels: [],
  })
  const [initialFilterValues, setInitialFilterValues] = useState([{}])
  const [btnLoading, setBtnLoading] = useState(false)

  useEffect(() => {
    if (automationId) {
      fetchAutomations(automationId)
    }
  }, [automationId])

  const fetchAutomations = async (id) => {
    try {
      const response = await API.graphql(graphqlOperation(getAutomationsById, { id })) as IData<IGetAutomationsByIdData>
      let applyToAllUsers = true
      const getAutomationData = response.data.getAutomation as IProbationPeriodAutomation

      handleFilter({
        labelIds: getAutomationData?.labels || [],
        locationIds: getAutomationData?.locations || [],
        teamIds: getAutomationData?.teams || [],
      })

      if (
        getAutomationData?.locations?.length > 0 ||
        getAutomationData?.teams?.length > 0 ||
        getAutomationData?.labels?.length > 0
      ) {
        applyToAllUsers = false
        form.setFieldsValue({ applyToAllUsers })
        setApplyToAllUsers(applyToAllUsers)
        setInitialFilterValues(() => {
          const data: ISelected[] = []
          const automationData = getAutomationData

          if (automationData) {
            if (automationData.locations.length > 0) {
              data.push({ type: 'Locations', values: automationData.locations })
            }
            if (automationData.teams.length > 0) {
              data.push({ type: 'Departments', values: automationData.teams })
            }
            if (automationData?.labels && automationData.labels.length > 0) {
              data.push({ type: 'Labels', values: automationData.labels})
            }
          }
          if(data.length === 0) {
            return [{}]
          }
          return data
        })
      }

      setAddOn({
        automationId: getAutomationData.id,
        name: getAutomationData.name,
        leaveTypeIds: getAutomationData.leaveTypeIds,
        lengthDays: getAutomationData.lengthDays,
        applyToAllUsers: applyToAllUsers,
        reason: getAutomationData.reason,
        isActive: getAutomationData.isActive,
        locations: getAutomationData.locations,
        teams: getAutomationData.teams,
        labels: getAutomationData.labels,
      })

      setIsLoading(false)
    } catch (err) {
      logger.error('ERROR FETCH DATA', err)
    }
  }

  const handleApplyToAllUsers = () => {
    setApplyToAllUsers(!applyToAllUsers)
    if(!applyToAllUsers) {
      handleFilter({
        labelIds: [],
        locationIds: [],
        teamIds: [],
      })
    }
  }

  const onFinish = async () => {
    if (status === AddonsStatusEnum.CANCELED) return
    let response

    try {
      const values = await form.validateFields()
      setBtnLoading(true)
      const data = {
        name: values.name,
        leaveTypeIds: values.leaveTypeIds,
        lengthDays: values.lengthDays,
        reason: values.reason,
        locations: filter.locationIds,
        teams: amIAdmin ? filter.teamIds : values.teams,
        labels: filter.labelIds,
      }

      track('PROBATION_PERIOD_LEAVE_TYPE_LIST_UPDATED', {
        includedLeaveTypes: leaveTypes.filter(leaveType => values.leaveTypeIds.includes(leaveType.id)).map(leaveType => leaveType.name),
        excludedLeaveTypes: leaveTypes.filter(leaveType => !values.leaveTypeIds.includes(leaveType.id)).map(leaveType => leaveType.name),
      })

      if (!automationId) {
        response = await API.post('CoreEvent', '/core/event', {
          body: {
            eventType: 'PROBATION_PERIOD_CREATED',
            eventGroup: 'AUTOMATION',
            ...data,
          },
        })
      } else {
        response = await API.post('CoreEvent', '/core/event', {
          body: {
            eventType: 'PROBATION_PERIOD_UPDATED',
            eventGroup: 'AUTOMATION',
            automationId,
            ...data,
            isActive: true,
          },
        })
      }

      notification.open({
        key: response.correlationId,
        message: formatMessage({ id: automationId ? 'automations.probationPeriod.updateInProgress' : 'automations.probationPeriod.createInProgress' }),
        icon: (<LoadingOutlined />),
        duration: 0,
      })
      setActionNotifications([ ...actionNotifications, response.correlationId ])

      setTimeout(() => {
        // It's not necessary, but it looks nicer if it waits a bit because in 90% of cases automation creation will be completed by then, 
        // and it will immediately appear in the view section.
        history.push(`${FrontendUrls.addOns}/probation-period/view`)
      }, 700)
    } catch (error) {
      setBtnLoading(false)
      if (error.response?.data?.error) {
        notification.error({
          message: formatMessage({ id: 'error.automations.automationSubmitError' }),
          description: formatMessage({ id: error.response.data.error }),
          duration: 0,
        })
      } else {
        let description
        let message = formatMessage({ id: 'error.automations.automationSubmitError' })
        if (response?.correlationId) {
          description = formatMessage({ id: 'error.automations.automationSubmitError' })
        } else if (error.message === 'Request failed with status code 400') {
          message = formatMessage({ id: 'error.automations.insufficientPrivileges.title' })
          description = formatMessage({ id: 'error.automations.insufficientPrivileges.description' })
        } else {
          description = error.message
        }

        notification.error({
          message,
          description,
          duration: 0,
        })
      }
    }
  }


  return (
    <>
      {(isLoading && automationId) ?
        <CircularProgress /> :
        <>
          <Form
            layout="vertical"
            form={form}
            name="automationsForm"
            requiredMark="optional"
            labelWrap
            initialValues={addOn}
            onFinish={onFinish}
            disabled={status === AddonsStatusEnum.CANCELED}
            scrollToFirstError
            size="large"
          >
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col xxl={8} xl={8} lg={6} md={6} sm={24} xs={24}>
                <Title level={4}><IntlMessages id="automations.probationPeriod.settings.nameTitle" /></Title>
                <Paragraph type="secondary"><IntlMessages id="automations.probationPeriod.settings.nameDescription" /></Paragraph>
                <Paragraph type="secondary"><IntlMessages id="automations.probationPeriod.settings.nameDescriptionTwo" /></Paragraph>
                <Paragraph type="secondary"><IntlMessages id="automations.probationPeriod.settings.nameDescriptionThree" /></Paragraph>
              </Col>
              <Col xxl={16} xl={16} lg={18} md={18} sm={24} xs={24}>
                <Form.Item
                  name="name"
                  label={<IntlMessages id="automations.probationPeriod.name" />}
                  rules={[{ required: true, message: <IntlMessages id="form.inputRequired" /> }]}
                >
                  <Input maxLength={100} placeholder={`${formatMessage({ id: 'automations.probationPeriod.namePlaceholder' })}`} />
                </Form.Item>

                <Form.Item
                  name="lengthDays"
                  label={<IntlMessages id="automations.probationPeriod.lengthDays" />}
                  rules={[{ required: true, message: <IntlMessages id="form.inputRequired" /> }]}
                >
                  <InputNumber 
                    max={365}
                    min={1}
                    parser={value => Math.round(Number(value)) as number}
                  />
                </Form.Item>

                <Form.Item
                  style={{ marginTop: 20 }}
                  name="reason"
                  label={<IntlMessages id="automationsForm.autoRejectReason"/>}
                  tooltip={<IntlMessages id="automationsForm.autoRejectReasonTooltip" />}
                  rules={[{ required: true, message: <IntlMessages id="form.inputRequired" /> }]}
                >
                  <TextArea rows={4} />
                </Form.Item>
              </Col>
            </Row>
            <Divider />
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col xxl={8} xl={8} lg={6} md={6} sm={24} xs={24}>
                <Title level={4}><IntlMessages id="app.applyTo" /></Title>
                <Paragraph type="secondary"><IntlMessages id="automations.probationPeriod.settings.applyToMDescription" /></Paragraph>
                <Paragraph type="secondary"><IntlMessages id="automations.probationPeriod.settings.applyToMDescriptionTwo" /></Paragraph>
              </Col>
              <Col xxl={16} xl={16} lg={18} md={18} sm={24} xs={24}>
                {amIAdmin &&
                  <Form.Item
                    label={<IntlMessages id="automationsForm.applyToAllUsers" />}
                    tooltip={<IntlMessages id="automationsForm.applyToAllUsersTooltip" />}
                    name="applyToAllUsers"
                  >
                    <Radio.Group onChange={handleApplyToAllUsers}>
                      <Radio.Button value={true}><IntlMessages id="app.yes" /></Radio.Button>
                      <Radio.Button value={false}><IntlMessages id="app.no" /></Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                }

                {!applyToAllUsers && amIAdmin &&
                  <FilterAdvanced
                    isForm={true}
                    data={{
                      Locations: locations,
                      Departments: teams,
                      Labels: labels,
                    }}
                    onChangeFilter={handleFilter}
                    showLabels={shouldEnableLabels}
                    toolTipInfo="automationsForm.advancedFilter.tooltipInfo"
                    initialValues={initialFilterValues}
                    filterByWidth={160}
                    page={automationId ? undefined : 'automationsForm'}
                  />
                }
                {!amIAdmin &&
                  <Form.Item
                    label={<IntlMessages id="calendar.filterByTeam" />}
                    rules={[{ required: true, message: <IntlMessages id="form.inputRequired" /> }]}
                    name="teams"
                  >
                    <Select
                      mode="multiple"
                      allowClear
                      placeholder={<IntlMessages id="app.pleaseSelect" />}
                    >
                      {teams.map((team) => (
                        <Option value={team.id} key={team.id}>
                          {team.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                }
                <Form.Item
                  label={<IntlMessages id="app.leaveTypes" />}
                  rules={[{ required: true, message: <IntlMessages id="form.inputRequired" /> }]}
                  name="leaveTypeIds"
                >
                  <Select
                    mode="multiple"
                    allowClear
                    placeholder={<IntlMessages id="app.pleaseSelect" />}
                  >
                    {leaveTypes.map((leaveType) => (
                      <Option value={leaveType.id} key={leaveType.id}>
                        {leaveType.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span={24}>
                <Form.Item style={{ textAlign: 'right' }}>
                  <Button type="default" style={{ marginRight: 10 }} onClick={backToAddons}>
                    <IntlMessages id="app.cancel" />
                  </Button>
                  <Button type="primary"
                    loading={btnLoading}
                    htmlType="submit"
                  >
                    {automationId ? <IntlMessages id="app.update" /> : <IntlMessages id="app.create" />}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </>
      }
    </>
  )
}
export default ProbationPeriodForm
