import 'moment/locale/fr'
import { getSafeLocale } from '@vacationtracker/shared/i18n'
import moment from 'moment'
import getDateInUserTimezone from '@vacationtracker/shared/functions/get-date-in-user-timezone'
import { convertHourFormats } from '@vacationtracker/shared/functions/convert-between-hour-formats'
import { HourFormatEnum } from '@vacationtracker/shared/types/user'
import { LocaleEnum } from '../../types/i18n'

interface IValues {
  locale: LocaleEnum
  startDate: string
  endDate: string
  partDayStartHour?: number
  partDayEndHour?: number
  hourFormat: HourFormatEnum
}

export const formatToilPeriod = (values: IValues, formatMessage: any): string => {
  const safeLocale = getSafeLocale(values.locale)
  const startDate = moment(getDateInUserTimezone(values.startDate)).locale(safeLocale).format('MMMM Do YYYY')
  const endDate = moment(getDateInUserTimezone(values.endDate)).locale(safeLocale).format('MMMM Do YYYY')
  let convertedStartHour
  let convertedEndHour
  let amOrPmStart
  let amOrPmEnd
  if (values.partDayStartHour) {
    const { value: convertedStartHourVal, amOrPm: amOrPmStartVal } = convertHourFormats(values.hourFormat, values.partDayStartHour)
    const { value: convertedEndHourVal, amOrPm: amOrPmEndVal } = convertHourFormats(values.hourFormat, values.partDayEndHour as number)
    convertedStartHour = convertedStartHourVal
    convertedEndHour = convertedEndHourVal
    amOrPmStart = amOrPmStartVal
    amOrPmEnd = amOrPmEndVal
  }
    
  return values.partDayStartHour ?
    formatMessage({id: 'components.toil.partDayPeriod'}, {
      date: startDate,
      partDayStartHour: `${convertedStartHour}${amOrPmStart}`,
      partDayEndHour: `${convertedEndHour}${amOrPmEnd}`,
      hourFormat24: values.hourFormat === HourFormatEnum.twentyFour,
    }) :
    formatMessage({id: 'components.toil.fullDayLeavePeriod'}, {
      date: startDate,
      endDate: values.endDate !== values.startDate ? ` - ${endDate}` : '',
    })
}

export const calculateToilPeriod = (values: IValues, formatMessage: any): string => {
  if (values.partDayStartHour || values.partDayEndHour) {
    return `${(Number(values?.partDayEndHour) - Number(values.partDayStartHour)) || 0} ${formatMessage({id: 'dashboard.hour'})}`
  }
  const dateDiff = (moment(values.endDate).diff(moment(values.startDate), 'days') + 1) || 0
  return `${dateDiff} ${formatMessage({id: 'dashboard.days'})}`
}